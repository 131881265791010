import React from "react";
import { deletePayments, getPayments } from "../../api";
import { Await, defer, useLoaderData } from "react-router-dom";
import DataGrid from "../../Component/DataGrid";
import AcitionButton from "./../../Component/ActionButton";
import PaymentModal from "../../Component/PaymentModal";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");

  const PromisedData = getPayments("SupplierPayment", sort, order, search);
  return defer({ data: PromisedData });
};
export default function SupplierPayments() {
  const data = useLoaderData();
  const [actionData, setActionData] = React.useState({});

  const Actions = (p) => {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center gap-2">
        <AcitionButton
          modal="paymentModal"
          setActionData={setActionData}
          isShow={true}
          p={p}
        />

        <AcitionButton
          p={p}
          isDelete={true}
          deleteFunc={deletePayments}
          deletePaymentType="SupplierPayment"
        />
      </div>
    );
  };
  const [colDefs, setColDefs] = React.useState([
    {
      field: "supplierName",
      headerName: "Full Name",
    },
    {
      field: "forgivePrice",
      valueFormatter: (p) => `$ ${p.value?.toLocaleString()}`,
    },
    {
      field: "price",
      valueFormatter: (p) => `$ ${p.value?.toLocaleString()}`,
    },
    {
      field: "receivedFrom",
    },
    {
      field: "invoiceNumber",
    },
    {
      field: "note",
    },
    {
      field: "actions",
      cellRenderer: Actions,
    },
  ]);

  return (
    <section className="p-3">
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return (
              <DataGrid
                modalType={"paymentModal"}
                setActionData={setActionData}
                hasCreate={true}
                data={data}
                col={colDefs}
              />
            );
          }}
        </Await>
      </React.Suspense>

      <PaymentModal isSupplier={true} actionData={actionData} />
    </section>
  );
}
