import React from "react";

function TelegramGroups() {
  const groups = [
    {
      title: "ملابس الاطفال",
      link: "https://t.me/china_stars",
    },
    {
      title: "احتياجات بيبي",
      link: "https://t.me/ChinastarsBaby",
    },
    {
      title: "اكسسوارات اطفال و النساء",
      link: "https://t.me/ChinastarsAccessories",
    },
    {
      title: "ملابس نسائية",
      link: "https://t.me/Chinastars_women",
    },
    {
      title: "الاحذية",
      link: "https://t.me/chinastars0shose",
    },
    {
      title: "جوارب والملحقات",
      link: "https://t.me/ChinaStars1socks",
    },
    {
      title: "اليكترونيات",
      link: "https://t.me/ChinastarsElictornics",
    },
    {
      title: "الالعاب",
      link: "https://t.me/ChinaStarsToys",
    },
    {
      title: "الحقائب",
      link: "https://t.me/ChinaSTARSStationery",
    },
    {
      title: "ترمز و كوب",
      link: "https://t.me/ChinaStarsThermosAndCup",
    },
    {
      title: "مفروشات",
      link: "https://t.me/chinastarscarpet",
    },
    {
      title: "شالات نسائية",
      link: "https://t.me/ChinaStarshaj",
    },
    {
      title: "مداليا",
      link: "https://t.me/ChinaStarsMadaliya",
    },
    {
      title: "قلم قرآن",
      link: "https://t.me/ChinaStars_Quran",
    },
    {
      title: "شى ئن للطلبيات الخاصة",
      link: "https://t.me/Chinastarsbabyclothes",
    },
    {
      title: "شحن البحري بالكونتينر والحوالات",
      link: "https://t.me/ChinastarSseafreight",
    },
  ];
  return (
    <div>
      <div className="bg-secondary py-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className=" mb-0 text-light">{window.t("telegramGroups")}</h1>
          </div>
        </div>
      </div>
      <section className="container-fluid pt-grid-gutter">
        <div className="row">
          {groups.map((group, index) => {
            return (
              <div className="col-6 col-xl-3  mb-grid-gutter">
                <a
                  key={index}
                  className="card "
                  href={group.link}
                  target="_blank"
                  data-scroll
                >
                  <div className="card-body text-center">
                    <i className="ci-telegram fs-sm-md-lg-1 my-2 text-primary"></i>
                    <p className="fs-sm-md-lg-1 mb-2">{group.title}</p>
                    <p className="fs-sm-md-lg-2 text-muted">{group.link}</p>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default TelegramGroups;
