import React from "react";
import { deleteMain, getSizes } from "../../api";
import { Await, defer, useLoaderData } from "react-router-dom";
import DataGrid from "../../Component/DataGrid";
import ActionButton from "../../Component/ActionButton";
import UtilitesModal from "../Utilites/Modal/UtilitesModal";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");

  const PromisedData = getSizes(sort, order, search, "AvailableColors");
  return defer({ data: PromisedData });
};

export default function ProductsColor() {
  const data = useLoaderData();
  const [actionData, setActionData] = React.useState({});
  const Actions = (p) => {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center gap-2">
        <ActionButton modal="sizeModal" setActionData={setActionData} p={p} />

        <ActionButton
          p={p}
          isDelete={true}
          deleteFunc={deleteMain}
          deletePaymentType="FrontPageSlider"
        />
      </div>
    );
  };

  const ColorRender = (p) => {
    return (
      <div className="d-flex h-100 justify-content-between align-items-center gap-2">
        <p>{p?.value}</p>
        <div
          className="rounded shadow border"
          style={{ backgroundColor: p?.value, width: "40px", height: "40px" }}
        ></div>
      </div>
    );
  };
  const [colDefs, setColDefs] = React.useState([
    {
      field: "name",
      flex: 2,
    },

    {
      field: "hexCode",
      headerName: "Color",

      cellRenderer: ColorRender,
    },

    {
      field: "actions",
      cellRenderer: Actions,
      flex: 2,
    },
  ]);
  return (
    <section className="p-3">
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return (
              <DataGrid
                modalType={"sizeModal"}
                setActionData={setActionData}
                hasCreate={true}
                data={data}
                col={colDefs}
              />
            );
          }}
        </Await>
      </React.Suspense>

      <UtilitesModal actionData={actionData} type="FrontPageSlider" />
    </section>
  );
}
