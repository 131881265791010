import React from "react";
import { getOrders } from "../../api";
import { Await, defer, useLoaderData } from "react-router-dom";
import DataGrid, { image } from "../../Component/DataGrid";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");
  const status = url.searchParams.get("status");
  const customerId = url.searchParams.get("customerId");
  const supplierId = url.searchParams.get("supplierId");
  const PromisedData = getOrders(
    sort,
    order,
    search,
    status,
    customerId,
    supplierId
  );
  return defer({ data: PromisedData });
};

export default function SupplierOrders() {
  const data = useLoaderData();

  const [colDefs, setColDefs] = React.useState([
    {
      headerName: "Product",
      cellRenderer: image,
      flex: 4,
    },

    {
      field: "cost",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "qty",
      headerName: "Quantity",
      valueFormatter: (p) => {
        return `${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "price",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "totalPrice",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },

    {
      field: "orderStatus",
    },
  ]);

  return (
    <section className="p-3">
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return (
              <DataGrid
                hasCustomerSearch={true}
                hasSupplierSearch={true}
                orders={true}
                rowHeight={80}
                data={data}
                col={colDefs}
              />
            );
          }}
        </Await>
      </React.Suspense>
    </section>
  );
}
