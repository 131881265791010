import React from "react";
import { deleteCustomer, getCustomers } from "../../api";
import { Await, defer, useLoaderData } from "react-router-dom";
import { HTTP } from "../../axios";
import DataGrid, { BooleanCellData } from "../../Component/DataGrid";
import CustomerModal from "../../Component/CustomerModal";
import { MdEditNote } from "react-icons/md";
import ActionButton from "../../Component/ActionButton";
import AcitionButton from "../../Component/ActionButton";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");

  const PromisedData = getCustomers(sort, order, search);
  return defer({ data: PromisedData });
};
export default function Customers() {
  const data = useLoaderData();
  const [isShow, setIsShow] = React.useState(false);
  const [actionData, setActionData] = React.useState({});

  const Actions = (p) => {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center gap-2">
        <span
          onClick={() => {
            setIsShow(false);
          }}
        >
          <AcitionButton
            modal="customerModal"
            setActionData={setActionData}
            p={p}
          />
        </span>
        <span
          onClick={() => {
            setIsShow(true);
          }}
        >
          <AcitionButton
            modal="customerModal"
            setActionData={setActionData}
            p={p}
            isShow={true}
          />
        </span>
        <AcitionButton
          modal="supplierModal"
          setActionData={setActionData}
          p={p}
          isDelete={true}
          deleteFunc={deleteCustomer}
        />
      </div>
    );
  };

  const [colDefs, setColDefs] = React.useState([
    {
      field: "fullName",
    },
    {
      field: "phone",
    },
    {
      field: "address",
    },
    {
      field: "currentLoan",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "hasAccount",
      cellRenderer: BooleanCellData,
    },
    {
      field: "actions",
      cellRenderer: Actions,
      flex: 2,
    },
  ]);

  return (
    <section className="p-3">
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return (
              <DataGrid
                hasCreate={true}
                modalType={"customerModal"}
                setActionData={setActionData}
                data={data}
                col={colDefs}
              />
            );
          }}
        </Await>
      </React.Suspense>
      <CustomerModal isShow={isShow} actionData={actionData} />
    </section>
  );
}
