export const ar = {
  home: "الصفحة الرئيسية",
  darkLightMode: "وضع الظلام / الضوء",
  //Start Header
  available: "متوفرة",
  at: "في",
  //End Header

  //Start Navbar
  searchForProduct: "ابحث عن المنتجات",
  hello: "مرحبًا",
  myAccount: "حسابي",
  departments: "الاقسام",

  /* Start SignIn */
  signIn: "تسجيل الدخول",
  username: "اسم المستخدم",
  password: "كلمه السر",
  rememberMe: "ذكرنى",
  forgetPassword: "هل نسيت كلمة السر؟",
  /* End SignIn */

  /* Start SignUp */
  signUp: "قم بالتسجيل",
  fullName: "الاسم الكامل",
  emailAddress: "عنوان بريد الكتروني",
  confirmPassword: "تأكيد كلمة المرور",
  userType: "نوع المستخدم",
  generalAdmin: "المشرف العام",
  superAdmin: "مشرف فائق",
  admin: "مسؤل",
  default: "إفتراضي",
  /* End SignUp */

  /* Start My Cart */
  myCart: "عربتي",
  subtotal: "المجموع الفرعي",
  expandCart: "بتوسيع العربة",
  checkout: "الدفع",
  /* End My Cart */

  //End Navbar

  //Start Navs
  phoneAndTablets: "الهاتف والأجهزة اللوحية",
  compAndAcce: "الكمبيوتر وملحقاته",
  gaming: "الألعاب",
  cctvAndSecurity: "CCTV والأمن",
  networking: "الشبكات",
  homeApplication: "تطبيق المنزل",
  powerSolution: "حل الطاقة",
  tools: "أدوات",
  pos: "نقطة البيع",
  //End Navs

  //Start Shop Cart Page
  yourCart: "عربتك",
  products: "منتجات",
  contShopping: "مواصلة التسوق",
  quantity: "كمية",
  additionalComments: "تعليقات اضافية",
  applyPromoCode: "تطبيق الرمز الترويجي",
  promoCode: "رمز ترويجي",
  proceedToCheckout: "انتقل إلى الخروج",
  remove: "إزالة",
  //End Shop Cart Page

  //Start Main Page
  slidHead1: "عالم الموسيقى مع",
  slidBody1: "سماعات الرأس",
  slidFooter1: "اختر من بين أفضل العلامات التجارية",
  slidHead2: "اكتشف أفضل",
  slidBody2: "مجموعة VR",
  slidFooter2: "في السوق ",
  slidHead3: "تحقق من",
  slidBody3: "مجموعتنا الضخمة من الهواتف الذكية",
  slidFooter3: "والملحقات",
  //End Main Page

  //Start Trending Products (Product Component)
  trendingProducts: "المنتجات الرائجة",
  moreProducts: "المزيد من المنتجات",
  addToCart: "أضف إلى السلة",
  quickView: "نظرة سريعة",
  //End Trending Products (Product Component)

  //Start Product Modal
  reviews: "المراجعات",
  color: "اللون: ",
  size: "مقاس: ",
  addToWishlist: "أضف إلى قائمة الامنيات",
  compare: "يقارن",
  productInfo: "معلومات المنتج",
  general: "عام",
  generalInfo: "معلومات عامة",
  model: "نموذج: ",
  gender: "جنس: ",
  osCompatibilty: "قابلية نظام التشغيل: ",
  physicalSpece: "المواصفات المادية",
  shape: "شكل: ",
  bodyMterial: "مادة الجسم: ",
  description: "الوصف",
  bandMterial: "مادة حزام: ",
  ram: "الرام : ",
  rom: "الذاكرة : ",
  screen: "الشاشة : ",
  installments: "الاقصات : ",
  overview: "ملخص ",
  noRAM: "لا يوجد حجم رام متاح",
  noROM: "لا يوجد حجم ذاكرة متاح",
  noIM: "لا توجد طريقة تقسيط متاحة",
  noSc: "لا يوجد حجم شاشة متاح",
  noCo: "لا يوجد لون متاح",
  availableSizes: "الأحجام المتوفرة",
  availableColors: "الألوان المتاحة",
  bottomSizes: "الأحجام السفلية",
  bottomSizes2: "الأحجام السفلية2",
  //End Product Modal

  //Start Prormo Banner
  limitedOffer: "عرض محدود",
  offerHead: "جهاز",
  offerBody: "iPad Pro الجديد كليًا",
  offerfooter: "بسعر مخفض. أسرع!",
  viewOffers: "مشاهدة العروض",
  //End Promo Banner

  //Start Product Widget
  bestsellers: "الأكثر مبيعًا",
  newSrrivals: "الوافدون الجدد",
  topRated: "مختارة من اجلك",
  //End Product Widget

  //Start SocialMedia Feeds
  smfl: "شاهد أحدث تقييمات الأدوات المتاحة للشراء في متجرنا.",
  smfr: "أحدث الفيديوهات من قناة Techeye",
  moreVideos: "فيديوهات اكثر",
  //End SocialMedia Feeds

  //Start SocialMedia Info
  readBlog: "اقرأ المدونة",
  readBlogDescr: "آخر متجر , أخبار الموضة والاتجاهات",
  followSocialMedia: "اتبع على Instagram",
  followSocialMedia2: "تابعنا على فيسبوك",
  //End SocialMedia Info

  //Start Product Carousel
  ymal: "ربما يعجبك أيضا",
  //End Product Carousel

  //Start Cheaper Together
  cheaperTogether: "أرخص معا",
  purchaseTogether: "شراء معا",
  //End Cheaper Together

  //Start TFHHD
  menu: "القائمة",
  wishlist: "الرغبات",
  filters: "المرشحات",
  //End TFHHD

  //Start Toolbar
  sortBy: "ترتيب حسب: ",
  popularity: "شعبية",
  LtHp: "منخفض - مرتفع (السعر)",
  HtLp: "مرتفع - منخفض (السعر)",
  AtZ: "A - Z (الاسم)",
  ZtA: "Z - A (الاسم)",
  AtZCode: "A - Z كود",
  ZtACode: "Z - A كود",
  shippingPrice: "سعر الشحن",
  //End Toolbar

  //Start Footer
  shopDept: "أقسام المتجر",
  accAndShippingInfo: "معلومات الحساب والشحن",
  stayInformed: "البقاء على علم",
  aboutUs: "معلومات عنا",
  downloadApp: "قم بتنزيل تطبيقنا",
  footerNav1Head: "توصيل مجاني وسريع",
  footerNav1Descr: "توصيل مجاني لجميع الطلبات التي تزيد عن 200 دولار",
  footerNav2Head: "ضمان استعادة الاموال",
  footerNav2Descr: "نعيد الأموال في غضون 30 يومًا",
  footerNav3Head: "24/7 دعم العملاء",
  footerNav3Descr: "دعم عملاء ودود على مدار الساعة طوال أيام الأسبوع",
  footerNav4Head: "الدفع الآمن عبر الإنترنت",
  footerNav4Descr: "لدينا شهادة SSL / آمنة",
  cr: "© جميع الحقوق محفوظة. من صنع TechEye",
  aboutCompany: "عن الشركة",
  ourTeam: "فريقنا",
  careers: "وظائف",
  news: "الاخبار",
  downloadOn: "تحميل التطبيق من",
  //End Footer

  // Start Shop Filter
  price: "السعر",
  min: "من",
  max: "الى",
  categories: "التصنيفات",
  brands: "العلامات التجارية",
  colors: "الالوان",
  raf: "إعادة تعيين كافة عوامل التصفية",
  rcf: "إعادة تعيين مرشحات الألوان",
  //stock
  stock: "المخازن",
  available: "متاح",
  iraqStock: "مخزن العراق",
  chinaStock: "مخزن الصين",
  iranStock: "مخزن ايران",
  turkeyStock: "مخزن تركيا",
  uaeStock: "مخزن الإمارات",
  europeStock: "مخزن أوروبا",
  //seasons
  seasons: "المواسم",
  spring: "الربيع",
  summer: "الصيف",
  autumn: "الخريف",
  winter: "الشتاء",
  all: "الكل",
  // End Shop Filter

  // Start Generals
  techeye: "Techeye",
  techeyestore: "Techeye Store",
  shopNow: "تسوق الآن ",
  shop: "تسوق",
  new: "جديد",
  note: "ملحوظة",
  sale: "تخفيض السعر",
  viewMore: "عرض المزيد",

  kurdish: "کوردی",
  arabic: "عربی",
  english: "English",
  language: "Language",
  usd: "دولار أمريكي",
  // End Generals

  contactUs: "اتصل بنا",
  discount: "تخفيضات",
  aboutUsTitle1: `1-	انطلق في أوائل عام 2003`,
  aboutUsTitle2: `2-	موظفين البحث والتطوير`,
  aboutUsTitle3: "3-  رسالتنا",
  aboutUsTitle4: `4-	العلامات التجارية`,
  aboutUsTitle5: `5-	الصفقة والشراء والشحن`,
  aboutUsTitle6: `6-	الإجراءات الجمركية`,
  aboutUsTitle7: `7-	شبكة علاقات واسعة`,
  aboutUsTitle8: `8-	السوق الدولي`,
  aboutUsTitle9: "9-  رؤيتنا",
  aboutUsTitle10: "في ملاحظة أخيرة",
  //desc
  aboutUsDesc1: `China Stars Co.  هي مؤسسة تجارية رائدة تركز أعمالها على البحث والتطوير، هي الأولى في العراق التي تشغل خدمة سلسلة التوريد من خلال مجموعة واسعة من التطبيقات وتكنولوجيا المعلومات المتقدمة التي تغطي آلاف المنتجات. إنها واحدة من أسرع المؤسسات نموًا في البلاد منذ إنشائها.`,
  aboutUsDesc2: `لدينا 19 عامًا من الخبرة في البحث والتطوير في السوق العراقي و 10 سنوات من الخبرة في البحث والتطوير في السوق الصينية. لدينا مجموعة من المدراء ذوي الجودة العالية ، من رجال الأعمال إلى المهندسين والميكانيكيين والأطباء وخبراء التسويق.
    وقد أقامت شراكات بحثية واسعة النطاق في وطنها وفي الخارج. وهي مكونة من 14 مديرًا يتمتعون بقدرات متخصصة في السوق العالمية ، وخاصة السوق الصينية.`,
  aboutUsDesc3: `مهمتنا هي تسهيل ممارسة الأعمال التجارية في العراق من خلال البنية التحتية التكنولوجية واستراتيجيات التسويق. نحن نساعد التجارین من خلال منحهم الأدوات اللازمة للوصول الموردين الصينيين ومنتجاتهم بسرعة وكفاءة. نحن نبني الجسر بين عملائنا والموردين الصينيين الذين يقدمون منتجات ذات جودة عالية في الصين ومناسبة لتجارتهم. نساعد أيضًا الشركات الصينية على القيام بالتسويق لمنتجاتها وتجارة العلامات التجارية في سوق بلدنا ، للاستفادة من قوة التكنولوجيا الجديدة للتفاعل مع مستخدميها وعملائها من خلال مجالنا والعمل بكفاءة أكبر. من خلال هذا الصدد ، ندعم أيضًا نمو أعمالهم في بلدنا. تقدم لك China stars-iq.com ملايين المنتجات في أكثر من 4000 فئة مختلفة ، بما في ذلك الإلكترونيات الاستهلاكية والآلات والملابس.
    لقد طورنا منصة قائمة على الويب ، تساعد رجال الأعمال على إجراء مشترياتهم وتتبعها بطريقة فعالة ، واكتشاف فرص جديدة ، في أي مكان وفي أي وقت. انتقل إلى China Stars-iq.com لجميع احتياجات عملك .`,
  aboutUsDesc4: ` تشاينا ستارز هي مؤسسة كبيرة ذات رؤية عالية للعلامة التجارية وإبداع وتأثير كبير. حاليا ، هي علامة تجارية معروفة في العراق. يستخدم نجوم الصين تصميمًا احترافيًا لعلاماتهم التجارية وعملائهم مدعومًا بتكنولوجيا إدارة القيمة المضافة الفعالة القائمة على منصة التجارة الإلكترونية. توفر المنصة تكاملًا للموارد الداخلية والخارجية ، وتكاملًا للتجارة واللوجستيات والمعلومات ورأس المال تمامًا. يتم تطبيق الابتكار باستمرار على خدمات تشغيل سلسلة التوريد الخاصة بنا في نموذج أعمالنا.`,
  aboutUsDesc5: ` نحن خبراء في الصفقات والمشتريات للبضائع فی الصین  . نقوم أيضًا بتنفيذ العملية اللوجستية من الباب إلى الباب. نقوم بتجميع البضائع في جميع أنحاء الصين وشحنها باستخدام إجراءات لوجستية فعالة من حيث التكلفة وموفرة للوقت وآمنة.`,
  aboutUsDesc6: `نختار احسن موانئ للاستلام (ميناء أم قصر في العراق ، ميناء عباس في إيران أو ميناء مرسين في تركيا). نختار الموانئ والطرق اللوجستية حسب نوع البضائع والمعايير اللوجستية لتسليم كل نوع من المنتجات. تتم إجراءاتنا اللوجستية وفقًا للمعايير الدولية ومتطلبات الحكومة العراقية.`,
  aboutUsDesc7: `نجحنا في إقامة علاقات شراكة إستراتيجية مع العديد من العلامات التجارية الصينية الشهيرة لجميع فئات التجارة. فقط اتصل بنا وستكتشف مدى احترافنا في العمل. ستضمن لك خدمات فريقنا المحترف النجاح في عملك. هدفنا هو إنشاء شبكة خدمة مثالية من خلال بناء علاقات طويلة الأمد وودية مع العملاء من جميع أنحاء العراق.`,
  aboutUsDesc8: `الآن استنادًا إلى السوق المحلية ، نقوم أيضًا بتطوير السوق الدولية بنشاط في نفس الوقت.`,
  aboutUsDesc9: ` نلتزم دائمًا بشعار "الصدق وثقة العملاء تأتي أولاً ، الجودة العالية والكفاءة العالية في أداء الأعمال والابتكار والمغامرة في منتجاتنا وخدماتنا والتعاون المربح للجانبين مع عملائنا". نتطلع إلى أن ننمو معكم معًا من أجل مصلحتنا المتبادلة. تتقدم شركتنا بثبات في المنافسة في السوق من خلال خدماتنا المتقدمة ومنتجاتنا الآمنة والموثوقة ، والتحسين المستمر في نظامنا البرمجي للحصول على أفضل الخدمات وأكثرها كفاءة.`,
  aboutUsDesc10: `تلتزم شركة China Stars بسياسة الشركة المتمثلة في "بذل قصارى جهدنا لتلبية متطلبات العملاء". نعد بضمان الجودة الجيدة لكل منتج منها. نأمل مخلصين أن نتمكن من تطوير الأعمال التجارية على أساس المنفعة المتبادلة.
  تشاينا ستارز هي الأولى في تقديم سلسلة التوريد الشاملة للعراق. ندمج قيمة الإبداع في جميع إجراءاتنا وخدماتنا. لضمان مستقبل مشرق لعملك يمكنك مقابلتنا. نرحب ترحيبا حارا بعملائنا الأحباء.`,
  freeShipping: "الشحن مجانا",
  pleaseWriteQuantity: "يرجى كتابة الكمية",
  cantOrderLessThanMinQuantity: "لا يمكن طلب أقل من الحد الأدنى للكمية",
  factorOrderingInfo:
    "يمكنك طلب كل مجموعة وفقا لعوامل الحد الأدنى للطلب على سبيل المثال يمكنك الطلب ",
  addedToCart: "تمت الإضافة إلى السلة",
  shippingOptions: "خيارات الشحن",
  courier: "الساعي",
  share: "سهم",
  relatedProducts: "منتجات ذات صله",
  code: "کود",
  barcode: "الباركود",
  minOrder: "الحد الأدنى للطلب",
  qty: "الكميه",
  resetFilters: "إعادة تعيين الفلاتر",
  applyFilters: "تطبيق المرشحات",
  sections: "مقاطع",
  families: "العائلات",
  minPrice: "الأدنى للسعر",
  maxPrice: "اقصى سعر",
  search: "بحث",
  searchCode: "رمز البحث",
  of: "من",
  logout: "تسجيل الخروج",
  myProfile: "ملفي الشخصي",
  languageChanged: "تم تغيير اللغة",
  telegramGroups: "كروبات  تلكرام",
};
