import { HTTP } from "./axios";

const queryValidation = (
  sort,
  order,
  search,
  status,
  customerId,
  supplierId,
  forCompare
) => {
  let query = ``;
  if (
    sort === "" ||
    sort === undefined ||
    sort === null ||
    order === "" ||
    order === undefined ||
    order === null
  ) {
    query += ``;
  } else {
    query += `&sort=${sort}`;
  }
  if (order === "" || order === undefined || order === null) {
    query += ``;
  } else {
    query += `&order=${order}`;
  }
  if (search === "" || search === undefined || search === null) {
    query += ``;
  } else {
    query += `&search=${search}`;
  }
  if (status === "" || status === undefined || status === null) {
    query += ``;
  } else {
    query += `&status=${status}`;
  }

  if (customerId === "" || customerId === undefined || customerId === null) {
    query += ``;
  } else {
    query += `&forCustomer=True&customerId=${customerId}`;
  }
  if (supplierId === "" || supplierId === undefined || supplierId === null) {
    query += ``;
  } else {
    query += `&forSupplier=True&supplierId=${supplierId}`;
  }
  if (!forCompare) {
    query += ``;
  } else {
    query += `&forCompare=True`;
  }

  return query;
};
export async function getProducts(sort, order, search) {
  const query = queryValidation(sort, order, search);

  let data = HTTP.get(`/products?start=0&end=100${query}`).then((res) => {
    return res.data;
  });
  return data;
}
export async function getproductsById(id) {
  let data = HTTP.get(`/products/${id}`).then((res) => {
    return res.data;
  });
  return data;
}
export async function getBranches(sort, order, search) {
  const query = queryValidation(sort, order, search);

  let data = HTTP.get(`/Branches?start=0&end=1000000${query}`).then((res) => {
    return res.data;
  });
  return data;
}
// ? Customers
export async function getCustomers(sort, order, search) {
  const query = queryValidation(sort, order, search);

  let data = HTTP.get(`/customers?start=0&end=1000000${query}`).then((res) => {
    return res.data;
  });
  return data;
}
export const customerLoadOptions = async (search, callback) => {
  const customers = await getCustomers("", "", search);
  let options = [];

  options.push({ label: "None", value: "" });
  customers.map((customer) => {
    options.push({
      label: customer.fullName,
      value: customer.id,
      customer,
    });
  });

  callback(options);
};
export const productLoadOptions = async (search, callback) => {
  const products = await getProducts("", "", search);
  let options = [];

  options.push({ label: "None", value: "" });
  products.map((product) => {
    options.push({
      label: product.name,
      value: product.id,
      product,
    });
  });

  callback(options);
};
export function deleteCustomer(id) {
  return HTTP.delete(`/customers/${id}`);
}
// ? Payments
export async function getPayments(type, sort, order, search) {
  const query = queryValidation(sort, order, search);
  let data = HTTP.get(
    `/Accounting?start=0&end=100${query}&objectName=${type}`
  ).then((res) => {
    return res.data;
  });
  return data;
}
export function deletePayments(id, type) {
  return HTTP.delete(`/Accounting/${id}?objectName=${type}`);
}
export function deleteMain(id, type) {
  return HTTP.delete(`/Main/${id}?objectName=${type}`);
}
export async function getCustomerInvoices(
  sort,
  order,
  search,
  status,
  customerId
) {
  const query = queryValidation(sort, order, search, status, customerId);

  let data = HTTP.get(
    `/Invoices?start=0&end=100&invoiceType=SellInvoice${query}`
  ).then((res) => {
    return res.data;
  });
  return data;
}
export async function getCustomerAccount(sort, order, search) {
  const query = queryValidation(sort, order, search);

  let data = HTTP.get(
    `/customers/GetCustomerAccount?start=0&end=100${query}`
  ).then((res) => {
    return res.data;
  });
  return data;
}
// ? Suppliers
export async function getSuppliers(sort, order, search) {
  const query = queryValidation(sort, order, search);
  let data = HTTP.get(`/suppliers?start=0&end=100${query}`).then((res) => {
    return res.data;
  });
  return data;
}
export function deleteSupplier(id) {
  return HTTP.delete(`/suppliers/${id}`);
}
export async function getUsers(sort, order, search) {
  const query = queryValidation(sort, order, search);

  let data = HTTP.get(`/Users?start=0&end=100${query}`).then((res) => {
    return res.data;
  });
  return data;
}
export async function getOrders(
  sort,
  order,
  search,
  status,
  customerId,
  supplierId,
  forCompare = false
) {
  const query = queryValidation(
    sort,
    order,
    search,
    status,
    customerId,
    supplierId,
    forCompare
  );

  let data = HTTP.get(
    `/Orders?start=0&end=100&invoiceType=SellInvoice${query}`
  ).then((res) => {
    return res.data;
  });
  return data;
}
export async function getCodes(search, isConfirmed, code) {
  let _search = ``;
  if (search === "" || search === undefined || search === null) {
    _search += ``;
  } else {
    _search += `&search=${search}`;
  }

  let data = HTTP.get(
    `/OpenSupplier/GetCodes/Name/${code}?start=0&end=100&IsSupplierAccepted=${isConfirmed}${_search}`
  ).then((res) => {
    return res.data;
  });
  return data;
}
export async function getOrderByInvoice(
  sort = "id",
  order,
  search,
  code,
  isConfirmed,
  invCode
) {
  const query = queryValidation(sort, order, search);

  let _invCode = ``;
  if (invCode === "" || invCode === undefined || invCode === null) {
    _invCode += ``;
  } else {
    _invCode += `&invCode=${invCode}`;
  }

  let data = await HTTP.get(
    `/OpenSupplier/GetOrders/Name/${code}?start=0&end=100${query}&isSupplierAccepted=${isConfirmed}${_invCode}`
  ).then((res) => {
    return res.data;
  });
  return data;
}
// ! OPTIONS AYSNC
export const supplierLoadOptions = async (search, callback) => {
  const suppliers = await getSuppliers("", "", search);

  const options = suppliers.map((supplier) => {
    return {
      label: supplier.name,
      value: supplier.id,
      supplier,
    };
  });

  callback(options);
};
export async function getMain(search, type, productTypeId = "") {
  let PID = ``;
  if (
    productTypeId !== "" &&
    productTypeId !== undefined &&
    productTypeId !== null
  ) {
    PID = `&id=${productTypeId}`;
  } else {
    PID = ``;
  }

  const query = queryValidation("", "", search);
  let data = HTTP.get(
    `/Main?start=0&end=100&objectName=${type}${query}${PID}&sort=id&order=ASC`
  ).then((res) => {
    return res.data;
  });
  return data;
}
export const brandLoadOptions = async (search, callback) => {
  const brands = await getMain(search, "Brand");

  const options = brands.map((brand) => {
    return {
      label: brand.name,
      value: brand.id,
      brand,
    };
  });

  callback(options);
};
export const firstProductTypeLoadOptions = async (search, callback) => {
  const productTypes = await getMain(search, "ProductType");

  const options = productTypes.map((productType) => {
    return {
      label: productType.name,
      value: productType.id,
      productType,
    };
  });

  callback(options);
};
export const secondProductTypeLoadOptions = async (search, callback) => {
  const queryString = window.location.search;
  const parentId = new URLSearchParams(queryString).get("firstProdutTypeId");

  const productTypes = await getMain(search, "ProductType", parentId);

  const options = productTypes.map((productType) => {
    return {
      label: productType.name,
      value: productType.id,
      productType,
    };
  });

  callback(options);
};
export const thirdProductTypeLoadOptions = async (search, callback) => {
  const queryString = window.location.search;
  const parentId = new URLSearchParams(queryString).get("secondProdutTypeId");

  const productTypes = await getMain(search, "ProductType", parentId);

  const options = productTypes.map((productType) => {
    return {
      label: productType.name,
      value: productType.id,
      productType,
    };
  });

  callback(options);
};
export const ProductTypeLoadOptions = async (search, callback) => {
  const queryString = window.location.search;
  const parentId = new URLSearchParams(queryString).get("thirdProdutTypeId");

  const productTypes = await getMain(search, "ProductType", parentId);

  const options = productTypes.map((productType) => {
    return {
      label: productType.name,
      value: productType.id,
      productType,
    };
  });

  callback(options);
};
export const tagLoadOptions = async (search, callback) => {
  const tags = await getMain(search, "tag");

  const options = tags.map((tag) => {
    return {
      label: tag.name,
      value: tag.id,
      tag,
    };
  });

  callback(options);
};
export const countryLoadOptions = async (search, callback) => {
  const tags = await getMain(search, "13");

  const options = tags.map((tag) => {
    return {
      label: tag.name,
      value: tag.id,
      tag,
    };
  });

  callback(options);
};
export const diskSizeLoadOptions = async (search, callback) => {
  const disks = await getMain(search, "AvailableDiskSizes");

  const options = disks.map((desk) => {
    return {
      label: desk.name,
      value: desk.id,
      desk,
    };
  });

  callback(options);
};
export const installmentMethodLoadOptions = async (search, callback) => {
  const methods = await getMain(search, "InstallmentMethod");

  const options = methods.map((method) => {
    return {
      value: method?.id,
      label: method?.name,
      paymentCount: method?.paymentCount,
      paymentPeriodDays: method?.paymentPeriodDays,
      prePaymentRate: method?.prePaymentRate,
    };
  });

  callback(options);
};
export const RAMSizeLoadOptions = async (search, callback) => {
  const rams = await getMain(search, "AvailableRamSizes");

  const options = rams.map((ram) => {
    return {
      label: ram.name,
      value: ram.id,
      ram,
    };
  });

  callback(options);
};
export const colorLoadOptions = async (search, callback) => {
  const colors = await getMain(search, "AvailableColors");

  const options = colors.map((color) => {
    return {
      label: color.name,
      value: color.id,
      hexCode: color.hexCode,
      color,
    };
  });

  callback(options);
};
export const brunchLoadOptions = async (search, callback) => {
  const colors = await getBranches("", "", search);

  const options = colors.map((color) => {
    return {
      label: color.name,
      value: color.id,
      hexCode: color.hexCode,
      color,
    };
  });

  callback(options);
};
// ? product Size (size, ram, scree, color)
export async function getSizes(
  sort,
  order,
  search,
  type,
  productTypeId,
  groupType
) {
  const query = queryValidation(sort, order, search);

  let id = ``;
  if (
    productTypeId === undefined ||
    productTypeId === null ||
    productTypeId === ""
  ) {
    id = ``;
  } else {
    id = `&id=${productTypeId}`;
  }
  let GType = ``;
  if (groupType === undefined || groupType === null || groupType === "") {
    GType = ``;
  } else {
    GType = `&groupType=${groupType}`;
  }

  let data = HTTP.get(
    `/Main?start=0&end=1000&objectName=${type}${query}${id}${GType}`
  ).then((res) => {
    return res.data;
  });
  return data;
}
export async function getContracts(sort, order, search) {
  const query = queryValidation(sort, order, search);

  let data = HTTP.get(`/contracts?start=0&end=1000${query}`).then((res) => {
    return res.data;
  });
  return data;
}
