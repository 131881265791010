import React from "react";
import { HTTP } from "../axios";
import { useRevalidator } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { customerLoadOptions, supplierLoadOptions } from "../api";
import helpers from "../../Constants/helpers";
import { AttachEmail } from "@mui/icons-material";
import moment from "moment";
import { CloseButton, SubmitButton } from "./ActionButton";

export default function PaymentModal(props) {
  const { actionData, isSupplier } = props;

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const [data, setData] = React.useState();

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="paymentModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {data?.id
                ? isSupplier
                  ? "Show Supplier"
                  : "Show Customer"
                : "Add Customer Payment"}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="first-name" className="col-form-label">
                  {isSupplier ? "Suppliers" : "Customers"} :
                </label>{" "}
                {data?.id ? (
                  <input
                    type="text"
                    className="form-control"
                    disabled={data?.id}
                    value={isSupplier ? data?.supplierName : data?.customerName}
                  />
                ) : (
                  <AsyncSelect
                    className="w-100"
                    onChange={(e) => {
                      setData(
                        isSupplier
                          ? {
                              ...data,
                              supplierId: e.value,
                              supplierName: e.label,
                            }
                          : {
                              ...data,
                              customerId: e.value,
                              customerName: e.label,
                            }
                      );
                    }}
                    loadOptions={
                      isSupplier ? supplierLoadOptions : customerLoadOptions
                    }
                    defaultOptions
                  />
                )}
              </div>
              <div className="flex-1">
                <label htmlFor="price" className="col-form-label">
                  Price :
                </label>
                <input
                  required
                  disabled={data?.id}
                  value={data?.price || 0}
                  type="number"
                  name="price"
                  onChange={formDataHandler}
                  className="form-control"
                  id="price"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="forgive-price" className="col-form-label">
                  Forgive Price:
                </label>
                <input
                  required
                  name="forgivePrice"
                  disabled={data?.id}
                  value={data?.forgivePrice || 0}
                  type="number"
                  onChange={formDataHandler}
                  className="form-control"
                  id="forgive-price"
                />
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label
                  htmlFor={`${isSupplier ? "paid-to" : "received-from"}`}
                  className="col-form-label"
                >
                  {isSupplier ? "Paid To" : "Received From"}:
                </label>
                <input
                  type="text"
                  disabled={data?.id}
                  value={
                    isSupplier ? data?.paidTo || "" : data?.receivedFrom || ""
                  }
                  name={isSupplier ? "paidTo" : "receivedFrom"}
                  onChange={formDataHandler}
                  className="form-control"
                  id={`${isSupplier ? "paid-to" : "received-from"}`}
                />
              </div>
              <div className="flex-1">
                <label htmlFor="accountant" className="col-form-label">
                  Accountant:
                </label>
                <input
                  name="accountant"
                  disabled={data?.id}
                  value={data?.accountant || ""}
                  type="text"
                  onChange={formDataHandler}
                  className="form-control"
                  id="accountant"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="date" className="col-form-label">
                  Date:
                </label>
                <input
                  name="date"
                  disabled={data?.id}
                  value={
                    moment(data?.date).format("YYYY-MM-DD") ||
                    moment(Date.now()).format("YYYY-MM-DD")
                  }
                  type="date"
                  onChange={formDataHandler}
                  className="form-control"
                  id="date"
                />
              </div>
            </div>

            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="note" className="col-form-label">
                  Note:
                </label>
                <input
                  name="note"
                  disabled={data?.id}
                  value={data?.note || ""}
                  type="text"
                  onChange={formDataHandler}
                  className="form-control"
                  id="note"
                />
              </div>
            </div>
            {!data?.id && (
              <div className="d-flex gap-4 justify-content-center align-items-center w-100">
                <div className="flex-1">
                  <label htmlFor="note" className="col-form-label">
                    Attachment:
                  </label>
                  <input
                    type="file"
                    disabled={data?.id}
                    className="form-control"
                    name="attachment"
                    onChange={async (e) => {
                      const file = e.target.files[0];
                      const base64 = await helpers.getBase64(file);
                      setData({ ...data, attachment: base64 });
                    }}
                    id="img"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                  />
                </div>
              </div>
            )}
            {data?.attachment && (
              <div className="position-relative d-flex gap-4 justify-content-center align-items-center w-100 my-3">
                <a
                  href={data?.id && `${helpers.getImageUrl(data?.attachment)}`}
                  target="_blank"
                >
                  <img
                    src={
                      data?.id
                        ? helpers.getImageUrl(data?.attachment)
                        : data?.attachment
                    }
                    alt=""
                  />
                </a>
                {!data?.id && (
                  <button
                    onClick={() => setData({ ...data, attachment: "" })}
                    className="m-1 shadow-lg btn btn-primary position-absolute top-0 start-0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <CloseButton />

            {!data?.id && (
              <SubmitButton
                endPoint={`/Accounting?objectName=${
                  isSupplier ? "SupplierPayment" : "CustomerPayment"
                }`}
                data={data}
                setData={setData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
