import React, { useEffect } from "react";
import Input from "../../Component/Input";

import AsyncSelect from "react-select/async";
import {
  customerLoadOptions,
  getproductsById,
  installmentMethodLoadOptions,
  productLoadOptions,
} from "../../api";
import helpers from "../../../Constants/helpers";
import moment from "moment";
import { SubmitButton } from "../../Component/ActionButton";

export default function CreateContract(props) {
  const { actionData, isShow } = props;
  const [data, setData] = React.useState();

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };

  const availableColorsOptions = async (search, callback) => {
    let options = [];
    options.push({ label: "None", value: "" });
    data?.product?.availableColors?.map((color) => {
      options.push({
        label: color.name,
        value: color.colorId,
        color,
      });
    });

    callback(options);
  };
  const availableDiskSizeOptions = async (search, callback) => {
    let options = [];
    options.push({ label: "None", value: "" });
    data?.product?.availableDiskSizes?.map((disk) => {
      options.push({
        label: `${disk.name} - ${disk.sizeInGB}`,
        value: disk.diskSizeId,
        size: disk.sizeInGB,
        disk,
      });
    });

    callback(options);
  };
  const availableRamSizeOptions = async (search, callback) => {
    let options = [];
    options.push({ label: "None", value: "" });
    data?.product?.availableRamSizes?.map((ram) => {
      options.push({
        label: `${ram.name} - ${ram.sizeInGB}`,
        value: ram.ramSizeId,
        size: ram.sizeInGB,
        ram,
      });
    });

    callback(options);
  };
  const availableScreenOptions = async (search, callback) => {
    let options = [];
    options.push({ label: "None", value: "" });
    data?.product?.availableScreenSizes?.map((screen) => {
      options.push({
        label: `${screen.name} - ${screen.sizeInGB}`,
        value: screen.screenSizeId,
        size: screen.sizeInGB,
        screen,
      });
    });

    callback(options);
  };

  React.useEffect(() => {
    let totalPrice = (data?.price || 0) + (parseFloat(data?.extraPrice) || 0);
    let discountPrice = totalPrice * ((data?.discount || 0) / 100);
    let finalPrice = totalPrice - discountPrice;
    let eachPrice = data?.finalPrice - data?.finalPrice * data?.prePaymentRate;
    let paymentProcesses = [];

    paymentProcesses.push({
      mcPrice: data?.finalPrice * data?.prePaymentRate,
      paymentType: 0,
      payDate: moment(Date.now()).format("YYYY-MM-DD"),
      deadline: moment(Date.now()).add(15, "d").format("YYYY-MM-DD"),
      isPaid: true,
      byMain: true,
      scPrice: 0,
    });

    let number = data?.paymentPeriodDays;
    for (let i = 0; i < data?.paymentCount; i++) {
      paymentProcesses.push({
        mcPrice: parseFloat(eachPrice / data?.paymentCount),
        paymentType: 1,
        payDate: moment(Date.now()).add(number, "d").format("YYYY-MM-DD"),
        deadline: moment(Date.now())
          .add(number + 5, "d")
          .format("YYYY-MM-DD"),
        isPaid: false,
        byMain: true,
        scPrice: 0,
      });
      number += data?.paymentPeriodDays;
    }

    setData({ ...data, discountPrice, finalPrice, paymentProcesses });
  }, [
    data?.price,
    data?.extraPrice,
    data?.discount,
    data?.installmentMethodId,
  ]);

  const renderPaymentList = () => {
    let itemList = [];
    let eachPrice = data?.finalPrice - data?.finalPrice * data?.prePaymentRate;

    itemList.push(
      <tr>
        <th scope="row">Pre Payment</th>
        <td>{data?.finalPrice * data?.prePaymentRate}</td>
        <td>
          {
            <input
              type="date"
              className="form-control"
              value={moment(Date.now()).format("YYYY-MM-DD")}
              required={true}
            />
          }
        </td>
        <td>
          {
            <input
              type="date"
              className="form-control"
              disabled={true}
              value={moment(Date.now()).add(15, "d").format("YYYY-MM-DD")}
              required={true}
            />
          }
        </td>
      </tr>
    );

    let number = data?.paymentPeriodDays;
    for (let i = 0; i < data?.paymentCount; i++) {
      itemList.push(
        <tr>
          <th scope="row">{i + 1}</th>
          <td>{helpers.roundToTwo(eachPrice / data?.paymentCount)}</td>
          <td>
            <input
              type="date"
              className="form-control"
              value={moment(Date.now()).add(number, "d").format("YYYY-MM-DD")}
              required={true}
            />{" "}
          </td>
          <td>
            <input
              type="date"
              className="form-control"
              value={moment(Date.now())
                .add(number + 5, "d")
                .format("YYYY-MM-DD")}
              required={true}
            />{" "}
          </td>
        </tr>
      );

      number += data?.paymentPeriodDays;
    }

    return itemList;
  };

  return (
    <div className="p-5 bg-light">
      <h1 className="fs-5">
        {data?.id
          ? isShow
            ? "Show Contract"
            : "Edit Contract"
          : "Create Contract"}
      </h1>
      <div className=" d-flex flex-column gap-4">
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="contractNumber"
            name="contractNumber"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />

          <div className="flex-1">
            <p>Select Customer: </p>
            <AsyncSelect
              placeholder="Select Supplier"
              name="price"
              required={true}
              onChange={(val) => {
                setData({
                  ...data,
                  supplierId: val?.value,
                  supplierName: val?.label,
                });
              }}
              loadOptions={customerLoadOptions}
            />
          </div>
          <div className="flex-1">
            <p>Select Products: </p>
            <AsyncSelect
              placeholder="Select Product"
              name="price"
              required={true}
              onChange={async (val) => {
                const product = await getproductsById(val?.value);
                setData({
                  ...data,
                  productId: val?.value,
                  price: val?.product?.price,
                  product,
                });
              }}
              loadOptions={productLoadOptions}
            />
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          {data?.product?.availableColors?.length > 0 && (
            <div className="flex-1">
              <p>Select Color: </p>
              <AsyncSelect
                placeholder="Select Color"
                name="price"
                required={true}
                onChange={(val) => {
                  setData({
                    ...data,
                    color: {
                      id: val?.value,
                      name: val?.label,
                      hexCode: val?.hexCode,
                    },
                    colorId: val?.value,
                  });
                }}
                loadOptions={availableColorsOptions}
              />
            </div>
          )}
          {data?.product?.availableDiskSizes?.length > 0 && (
            <div className="flex-1">
              <p>Select Disk Size: </p>
              <AsyncSelect
                placeholder="Select Color"
                name="price"
                required={true}
                onChange={(val) => {
                  setData({
                    ...data,
                    diskSize: {
                      id: val?.value,
                      name: val?.label,
                      sizeInGB: val?.sizeInGB,
                    },
                    diskSizeId: val?.value,
                  });
                }}
                loadOptions={availableDiskSizeOptions}
              />
            </div>
          )}
          {data?.product?.availableRamSizes?.length > 0 && (
            <div className="flex-1">
              <p>Select Ram Size: </p>
              <AsyncSelect
                placeholder="Select Color"
                name="price"
                required={true}
                onChange={(val) => {
                  setData({
                    ...data,
                    ramSize: {
                      id: val?.value,
                      name: val?.label,
                      sizeInGB: val?.sizeInGB,
                    },
                    ramSizeId: val?.value,
                  });
                }}
                loadOptions={availableRamSizeOptions}
              />
            </div>
          )}
          {data?.product?.availableScreenSizes?.length > 0 && (
            <div className="flex-1">
              <p>Select Screen Size: </p>
              <AsyncSelect
                placeholder="Select Color"
                name="price"
                required={true}
                onChange={(val) => {
                  setData({
                    ...data,
                    screenSize: {
                      id: val?.value,
                      name: val?.label,
                      sizeInGB: val?.sizeInGB,
                    },
                    screenSizeId: val?.value,
                  });
                }}
                loadOptions={availableScreenOptions}
              />
            </div>
          )}
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="price"
            name="price"
            type="number"
            disabled={true}
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />{" "}
          <Input
            label="extraPrice"
            name="extraPrice"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
          <Input
            label="discountPercentage"
            name="discount"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <div className="btn btn-primary">Price: {data?.price}</div>
          <div className="btn btn-primary">Extra Price: {data?.extraPrice}</div>
          <div className="btn btn-primary">
            Discount: (%{data?.discount}){" "}
            {helpers.roundToTwo(data?.discountPrice)}
          </div>
          <div className="btn btn-primary">
            Final Price: {helpers.roundToTwo(data?.finalPrice)}
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <div className="flex-1">
            <p>Select Installment Method: </p>
            <AsyncSelect
              placeholder="Select Color"
              name="price"
              required={true}
              onChange={(val) => {
                setData({
                  ...data,
                  installmentMethodId: val?.value,
                  paymentCount: val?.paymentCount,
                  paymentPeriodDays: val?.paymentPeriodDays,
                  prePaymentRate: val?.prePaymentRate,
                });
              }}
              loadOptions={installmentMethodLoadOptions}
            />
          </div>
        </div>
        {data?.finalPrice && data?.installmentMethodId && (
          <table class="table table-bordered table-hover table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Price</th>
                <th scope="col">Pay Date</th>
                <th scope="col">Deadline</th>
              </tr>
            </thead>
            <tbody>{renderPaymentList()}</tbody>
          </table>
        )}
      </div>
      <SubmitButton endPoint={`/Contracts`} data={data} setData={setData} />
    </div>
  );
}
