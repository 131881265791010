import React from "react";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";
import ToastMessage from "./ToastMessage";

export default function ErrorEle() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const routeError = useRouteError();

  React.useEffect(() => {
    if (routeError?.response?.status === 401) {
      navigate(`login?prevRouter=${pathname}`);
    }
  }, [0]);

  return (
    <section className="d-flex flex-column gap-4 justify-content-center align-items-center h-100 ">
      {routeError?.response?.status === 401 && (
        <ToastMessage
          title="The User is Unauthenticated"
          icon="error"
          text="Enter Username and password"
        />
      )}
      <div className=" w-50 shadow">
        <div className="card bg-primary text-light d-flex justify-content-center align-items-center gap-3 p-5">
          <p style={{ fontSize: "10rem" }} className="h1 text-light">
            {routeError?.response?.status}
          </p>

          <p className="h2 text-light">{routeError?.response?.statusText}</p>
          <p className="h6 text-light">
            Please check your connection or contact with the developement team
          </p>
        </div>
      </div>
    </section>
  );
}
