import React from "react";
import Swal from "sweetalert2";

export default function ToastMessage(props) {
  const { title, icon, text } = props;
  console.log(text);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  Toast.fire({
    icon: `${icon}`,
    title: `${title}`,
    text: `${text || ""}`,
  });
}
