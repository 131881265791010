import React from "react";
import { HTTP } from "../axios";
import { useRevalidator } from "react-router-dom";
import OrderStatus from "./OrderStatus";
import { CloseButton, UpdateButton } from "./ActionButton";

export default function CustomerInvoiceModal(props) {
  const { actionData, hasQty, hasCost, params } = props;

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const [data, setData] = React.useState();

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="changePriceQty"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Name: {data?.name}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex gap-5 justify-content-center align-items-start w-100">
              <div className="flex-2">
                <p className="border-bottom my-2">Name:</p>
                <p className="text-primary">{data?.name}</p>
              </div>
              <div className="flex-1">
                <p className="border-bottom my-2">Price:</p>
                <p className="text-primary">$ {data?.price}</p>
              </div>
              <div className="flex-1">
                <p className="border-bottom my-2">Code:</p>
                <p className="text-primary">{data?.code}</p>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="Price" className="col-form-label">
                  Price:
                </label>
                <input
                  required
                  type="number"
                  name="price"
                  value={data?.price}
                  onChange={formDataHandler}
                  className="form-control"
                  id="Price"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="qty" className="col-form-label">
                  Quantity:
                </label>
                <input
                  name="qty"
                  value={data?.qty}
                  type="number"
                  onChange={formDataHandler}
                  className="form-control"
                  id="qty"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <CloseButton />
            {data?.id && (
              <UpdateButton
                endPoint={`OpenSupplier/UpdateOrder/Name/${params?.code}?id=${data?.id}`}
                data={data}
                setData={setData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
