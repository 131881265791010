import DefaultLogo from "./../Assets/Images/navbar-logo.png";
import { toast } from "react-toastify";
import axiosPrivate from "../Axios";

const helpers = {
  getImageUrl: (url) => {
    if (url && url !== "error" && url !== "null") {
      if (url?.startsWith("http://") || url?.startsWith("https://")) {
        return url;
      } else {
        return process.env.REACT_APP_BASE_URL + url;
      }
    }
    return DefaultLogo;
  },

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  },
  logo: () => {
    return DefaultLogo;
  },
  makeQueryParams(array, name) {
    let string = "";
    for (let i = 0; i < array.length; i++) {
      if (i + 1 === array.length) {
        string += name + "[]=" + array[i];
      } else {
        string += name + "[]=" + array[i] + "&";
      }
    }
    return string;
  },
  showNotify(text, type = "success", position = "topRight", delay = 1000) {
    let currentPosition;
    switch (position) {
      case "topRight":
        currentPosition = toast.POSITION.TOP_RIGHT;
        break;
      case "topLeft":
        currentPosition = toast.POSITION.TOP_LEFT;
        break;
      case "topCenter":
        currentPosition = toast.POSITION.TOP_CENTER;
        break;
      case "bottomRight":
        currentPosition = toast.POSITION.BOTTOM_RIGHT;
        break;
      case "bottomLeft":
        currentPosition = toast.POSITION.BOTTOM_LEFT;
        break;
      case "bottomCenter":
        currentPosition = toast.POSITION.BOTTOM_CENTER;
        break;
      default:
        currentPosition = toast.POSITION.TOP_RIGHT;
        break;
    }
    toast(text, {
      type: type,
      delay: delay,
      autoClose: delay,
      position: currentPosition,
      pauseOnFocusLoss: true,
    });
  },
  async getDepartments() {
    let checkLocalStorage = localStorage.getItem("departments");
    if (!checkLocalStorage) {
      const response = await axiosPrivate.get(
        "/ClientSide/Get?objectName=5&start=0&end=1000000&sort=sort&order=asc"
      );
      localStorage.setItem("departments", JSON.stringify(response.data));
    }
  },
  openModal() {
    let myModal = new window.bootstrap.Modal(
      document.getElementById("quick-view"),
      { keyboard: false }
    );
    myModal.show();
  },
  openLoginModal() {
    let myModal = new window.bootstrap.Modal(
      document.getElementById("signin-modal"),
      { keyboard: false }
    );
    myModal.show();
  },
  roundToTwo(num) {
    return +(Math.round(num + "e+3") + "e-3");
  },
  getCartItems() {
    let array = [];
    Object.keys(localStorage).forEach(function (key) {
      if (key.startsWith("item_")) {
        array.push(JSON.parse(localStorage.getItem(key)));
      }
    });
    array.sort((a, b) => a.id - b.id);
    return array;
  },
  getCartItemNumbers() {
    let number = Object.keys(localStorage).filter((x) =>
      x.startsWith("item_")
    ).length;
    if (document.getElementById("update-cart-number")) {
      document.getElementById("update-cart-number").innerHTML = number;
    }
    return number;
  },
  getFavItemNumbers(
    number = JSON.parse(localStorage.getItem("favItems"))?.length
  ) {
    if (document.getElementById("update-fav-number")) {
      document.getElementById("update-fav-number").innerHTML = number;
    }
    return number;
  },
  closeAllModals() {
    document.body.classList.remove("modal-open");
    document.body.style.overflow = null;
    document.body.style.padding = null;

    const modals = document.getElementsByClassName("modal");
    for (let i = 0; i < modals.length; i++) {
      modals[i].classList.remove("show");
      modals[i].removeAttribute("aria-modal");
      modals[i].setAttribute("aria-hidden", "true");
      modals[i].setAttribute("style", "display: none");
    }

    const modalsBackdrops = document.getElementsByClassName("modal-backdrop");
    for (let i = 0; i < modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }
  },
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("firstName");
    localStorage.removeItem("middleName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("fullName");
    localStorage.removeItem("isLoggedIn");
    delete axiosPrivate.defaults.headers.common["Authorization"];
    helpers.closeAllModals();
  },
  compareValues(key, order = "asc") {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA =
        typeof a[key] === "string" ? a[key].toUpperCase() : parseFloat(a[key]);
      const varB =
        typeof b[key] === "string" ? b[key].toUpperCase() : parseFloat(b[key]);

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === "desc" ? comparison * -1 : comparison;
    };
  },
  getTextByLength: (text, lenght = 120) => {
    return text.length >= lenght
      ? text.substring(0, lenght) + " ..."
      : text.substring(0, lenght);
  },
};

export default helpers;
