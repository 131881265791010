import React, { useState } from "react";

import { getProducts } from "../../api";
import { Await, defer, useLoaderData } from "react-router-dom";

import DataGrid, { image } from "../../Component/DataGrid";
import ActionButton from "../../Component/ActionButton";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");
  const PromisedData = getProducts(sort, order, search);
  return defer({ data: PromisedData });
};

export default function Products() {
  const data = useLoaderData();
  const [actionData, setActionData] = React.useState({});
  const [isShow, setIsShow] = React.useState(false);

  const Actions = (p) => {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center gap-2">
        <span
          onClick={() => {
            setIsShow(true);
          }}
        >
          <ActionButton
            modal="createProductModal"
            setActionData={setActionData}
            isShow={true}
            p={p}
          />
        </span>

        {/* <ActionButton
          p={p}
          isDelete={true}
          deletePaymentType="CustomerPayment"
        /> */}
      </div>
    );
  };

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Product",
      cellRenderer: image,
      flex: 4,
    },
    {
      field: "code",
    },
    {
      field: "actions",
      cellRenderer: Actions,
    },
  ]);

  return (
    <section className="p-3">
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin ">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return (
              <DataGrid
                hasCreate={true}
                Link="true"
                data={data}
                rowHeight={80}
                col={colDefs}
              />
            );
          }}
        </Await>
      </React.Suspense>
    </section>
  );
}
