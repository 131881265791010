import { Logout } from "@mui/icons-material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { HTTP } from "../axios";
import helpers from "../../Constants/helpers";

export default function Navbar() {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = React.useState(
    localStorage.getItem("isLoggedIn") || false
  );
  const [userImage, setUserImage] = React.useState(
    helpers.getImageUrl(localStorage.getItem("image")) || ""
  );

  React.useEffect(() => {
    setIsLoggedIn(localStorage.getItem("isLoggedIn") || false);
  }, [window.location.pathname]);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    HTTP.defaults.headers.common["Authorization"] = null;
    setIsLoggedIn(false);
    navigate("/ordering/login");
  };
  return (
    <nav className="bg-secondary p-2 d-flex justify-content-between align-items-center gap-2">
      {isLoggedIn && (
        <div className="nav-img-container">
          <img className="" src={userImage} alt="" />
          <p className="d-flex flex-column">
            <span className="text-muted fs-xs">
              {localStorage.getItem("userType")}
            </span>
            <span>{localStorage.getItem("fullName")}</span>
          </p>
        </div>
      )}
      {isLoggedIn ? (
        <button onClick={() => logout()} className="btn btn-primary me-5 ">
          Logout
        </button>
      ) : (
        <Link to={"login"} className="btn btn-primary ms-auto me-5">
          Login
        </Link>
      )}
    </nav>
  );
}
