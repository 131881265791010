import React from "react";

import {
  CloseButton,
  SubmitButton,
  UpdateButton,
} from "../../../Component/ActionButton";
import SelectAttachment from "../../../Component/SelectAttachment";
import AsyncSelect from "react-select/async";
import {
  firstProductTypeLoadOptions,
  ProductTypeLoadOptions,
  secondProductTypeLoadOptions,
  thirdProductTypeLoadOptions,
} from "../../../api";
import { useSearchParams } from "react-router-dom";

export default (props) => {
  const { actionData, type } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const [data, setData] = React.useState();
  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="BTMTModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Product {type}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="name" className="col-form-label">
                  name:
                </label>
                <input
                  required
                  name="name"
                  value={data?.name}
                  onChange={formDataHandler}
                  className="form-control"
                  id="name"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="kuName" className="col-form-label">
                  Kurdish Name:
                </label>
                <input
                  required
                  name="kuName"
                  value={data?.kuName}
                  onChange={formDataHandler}
                  className="form-control"
                  id="kuName"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="arName" className="col-form-label">
                  Arabic name:
                </label>
                <input
                  required
                  name="arName"
                  value={data?.arName}
                  onChange={formDataHandler}
                  className="form-control"
                  id="arName"
                />
              </div>
            </div>
            {type === "ProductType" && (
              <div className="d-flex gap-4 justify-content-center align-items-center w-100 mt-4">
                <div className="d-flex gap-4 justify-content-center align-items-center w-100">
                  <div className="flex-1">
                    <p>Select Product Type: </p>
                    <AsyncSelect
                      required={true}
                      onChange={(val) => {
                        setData({
                          ...data,
                          firstLevelType: val?.value,
                          secondLevelType: null,
                          productTypeId: null,
                        });
                        setSearchParams({ firstProdutTypeId: val?.value });
                      }}
                      loadOptions={firstProductTypeLoadOptions}
                    />
                  </div>
                  <div className="flex-1">
                    <p>Select Product Type: </p>
                    <AsyncSelect
                      required={true}
                      isDisabled={!data?.firstLevelType}
                      onChange={(val) => {
                        setData({
                          ...data,
                          secondLevelType: val?.value,
                          productTypeId: null,
                        });
                        setSearchParams({
                          secondProdutTypeId: val?.value,
                        });
                      }}
                      loadOptions={secondProductTypeLoadOptions}
                    />
                  </div>
                  <div className="flex-1">
                    <p>Select Product Type: </p>
                    <AsyncSelect
                      required={true}
                      isDisabled={!data?.secondLevelType}
                      onChange={(val) => {
                        setData({
                          ...data,
                          parentId: val?.value,
                        });
                        setSearchParams({ thirdProdutTypeId: val?.value });
                      }}
                      loadOptions={thirdProductTypeLoadOptions}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="d-flex gap-4 justify-content-center align-items-center w-100">
                <SelectAttachment
                  data={data}
                  setData={setData}
                  label="attachment"
                  className="flex-1 d-block"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!data?.id && (
              <SubmitButton
                endPoint={`/Main?objectName=${type}`}
                data={data}
                setData={setData}
              />
            )}
            <CloseButton />
            {data?.id && (
              <UpdateButton
                endPoint={`/Main/${data?.id}?objectName=${type}`}
                data={data}
                setData={setData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
