import React from "react";
import OrderStatus from "./OrderStatus";
import { CloseButton, UpdateButton } from "./ActionButton";

export default function CustomerInvoiceModal(props) {
  const { actionData } = props;

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const [data, setData] = React.useState();
  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="changeStatus"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Change Status
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1 my-3">
                <OrderStatus data={data} formDataHandler={formDataHandler} />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <CloseButton />
            <UpdateButton
              data={data}
              setData={setData}
              endPoint={`orders/ChangeOrderStatus/${data?.id}?invoiceType=SellInvoice&newStatus=${data?.orderStatus}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
