import React from "react";
import { getCustomerInvoices } from "../../api";
import { Await, defer, useLoaderData } from "react-router-dom";
import DataGrid, { BooleanCellData } from "../../Component/DataGrid";
import CustomerInvoiceModal from "../../Component/CustomerInvoiceModal";
import AcitionButton from "./../../Component/ActionButton";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");
  const status = url.searchParams.get("status");
  const customerId = url.searchParams.get("customerId");
  const PromisedData = getCustomerInvoices(
    sort,
    order,
    search,
    status,
    customerId
  );
  return defer({ data: PromisedData });
};
export default function CustomerInvoices() {
  const data = useLoaderData();

  const [actionData, setActionData] = React.useState({});

  const Actions = (p) => {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center gap-2">
        <AcitionButton
          modal="CustomerInvoiceModal"
          setActionData={setActionData}
          p={p}
        />
      </div>
    );
  };

  const [colDefs, setColDefs] = React.useState([
    {
      field: "invoiceNumber",
    },
    {
      field: "customerName",
    },
    {
      field: "finalPrice",
      valueFormatter: (p) => `$ ${p.value?.toLocaleString()}`,
    },
    {
      field: "shipping",
      valueFormatter: (p) => `$ ${p.value?.toLocaleString()}`,
    },
    {
      field: "tax",
      valueFormatter: (p) => `$ ${p.value?.toLocaleString()}`,
    },
    {
      field: "other",
      valueFormatter: (p) => `$ ${p.value?.toLocaleString()}`,
    },
    {
      field: "isCash",
      cellRenderer: BooleanCellData,
    },
    {
      field: "isReviewed",
      cellRenderer: BooleanCellData,
    },
    {
      field: "shippingMethod",
    },
    {
      field: "totalQty",
    },

    {
      field: "actions",
      cellRenderer: Actions,
    },
  ]);

  return (
    <section className="p-3">
      {" "}
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return (
              <DataGrid
                modalType={"CustomerInvoiceModal"}
                hasCustomerSearch={true}
                invoiceOptionsStatus={true}
                orders={true}
                data={data}
                col={colDefs}
              />
            );
          }}
        </Await>
      </React.Suspense>
      <CustomerInvoiceModal actionData={actionData} />
    </section>
  );
}
