import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import Language from "./Language";

function TopCategories() {
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);

  const getDepartments = useCallback(async () => {
    const response = await axiosPrivate.get(
      "/ClientSide/Get?objectName=5&start=0&end=1000000&sort=sort&order=asc"
    );
    setDepartments(response.data);
  }, []);
  useEffect(() => {
    getDepartments().catch();
  }, [getDepartments]);

  const getSections = useCallback(async (departmentId) => {
    if (
      departmentId !== 0 &&
      departmentId !== null &&
      departmentId !== undefined &&
      departmentId !== ""
    ) {
      const response = await axiosPrivate.get(
        `/ClientSide/Get?objectName=5&start=0&end=1000000&sort=sort&order=asc&subLevelId=${departmentId}`
      );
      setSections(response.data);
    }
  }, []);
  useEffect(() => {
    getSections().catch();
  }, [getSections]);

  const [currencies, setCurrencies] = useState([]);

  const getCurrency = useCallback(async () => {
    const response = await axiosPrivate.get(
      `/ClientSide/Get?objectName=11&start=0&end=12`
    );
    setCurrencies(response.data);
  }, []);
  useEffect(() => {
    getCurrency().catch();
  }, [getCurrency]);

  const setNewCurrency = (currency) => {
    console.log(currency.attachment, "Attachment");
    window.currencyName = currency.name;
    localStorage.setItem("currencyName", currency.name);
    window.currencyImage = currency.attachment;
    localStorage.setItem("currencyImage", currency.attachment);
    window.rate = currency.rate;
    localStorage.setItem("rate", currency.rate);
    window.prefix = currency.prefix;
    localStorage.setItem("prefix", currency.prefix);
    window.suffix = currency.suffix;
    localStorage.setItem("suffix", currency.suffix);
    window.location.reload();
  };

  return (
    <div className="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2">
      <div className="container">
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="input-group d-lg-none my-3">
            <i className="ci-search position-absolute top-50 end-0 translate-middle-y mx-2"></i>
            <input
              className="form-control rounded-start"
              type="text"
              placeholder="Search for products"
            />
          </div>
          <div className="d-lg-none my-3">
            <div className="btn-group dropdown disable-autohide px-2">
              <Language />
            </div>
            <div className="btn-group dropdown disable-autohide px-2">
              <button
                className="btn text-dark btn-outline-dark border-dark btn-sm dropdown-toggle px-2"
                type="button"
                data-bs-toggle="dropdown"
              >
                <img
                  className="me-2"
                  src={helpers.getImageUrl(window.currencyImage)}
                  width="20"
                  alt="English"
                />
                {window.currencyName}
              </button>
              <ul className="dropdown-menu my-1">
                {currencies &&
                  currencies.map((currency, index) => (
                    <li
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setNewCurrency(currency);
                      }}
                    >
                      <a className="dropdown-item">
                        <img
                          className="me-2"
                          src={helpers.getImageUrl(currency.attachment)}
                          width="20"
                          alt="Italiano"
                        />
                        {currency.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <ul className="navbar-nav navbar-mega-nav pe-lg-2 me-lg-2">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle ps-lg-0 text-decoration-none"
                href="#"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
              >
                <i className="ci-menu align-middle mt-n1 me-2"></i>
                {window.t("departments")}
              </a>
              <ul className="dropdown-menu">
                {departments &&
                  departments.map((department, index) => {
                    return (
                      <li className="dropdown mega-dropdown" key={index}>
                        <Link
                          to={``}
                          className="dropdown-item dropdown-toggle text-decoration-none"
                          data-bs-toggle="dropdown"
                          onMouseEnter={() => getSections(department.id)}
                          onClick={() => {
                            navigate(
                              `/shop?category=${department.id}&level=false`
                            );
                          }}
                        >
                          {department.name}
                        </Link>
                        <div className="dropdown-menu p-0">
                          <div className="d-flex flex-wrap flex-sm-nowrap px-2">
                            <div className="mega-dropdown-column pt-4 pb-0 py-sm-4 px-3">
                              {sections &&
                                sections.map((section, index) => {
                                  return (
                                    <div
                                      className="widget widget-links"
                                      key={index}
                                    >
                                      <ul className="widget-list">
                                        <li className="widget-list-item pb-1">
                                          <Link
                                            to={`/shop?category=${section.id}&level=false`}
                                            className="widget-list-link text-decoration-none"
                                          >
                                            {section.name}
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <Link className="nav-link text-decoration-none" to={`/`}>
                {window.t("home")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link text-decoration-none"
                to={`/shop?page=1`}
              >
                {window.t("shop")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link text-decoration-none" to={`/about-us`}>
                {window.t("aboutUs")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link text-decoration-none"
                to={`/contact-us`}
              >
                {window.t("contactUs")}
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link text-decoration-none"
                to={`/telegramGroups`}
              >
                {window.t("telegramGroups")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TopCategories;
