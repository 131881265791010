import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideBar from "../Component/SideBar";
import Navbar from "../Component/Navbar";

export default function OrderingLayout() {
  return (
    <main className="d-flex">
      <SideBar />
      <div className="flex-1 d-flex flex-column">
        <Navbar />
        <Outlet />
      </div>
    </main>
  );
}
