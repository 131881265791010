import React, { useState, useEffect, useCallback } from "react";
import { SwiperSlide } from "swiper/react";
import SwiperSlider from "../Components/SwiperSlider";
import CustomCarousel from "../Components/CustomCarousel";
import TrendingProducts from "../Components/TrendingProducts";
import HomeWidgets from "../Components/HomeWidgets";
import FollowUs from "../Components/FollowUs";
import Departments from "../Components/Departments";
import Sections from "../Components/Sections";
import Categories from "../Components/Categories";
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import { useLocation } from "react-router-dom";
import SubCats from "../Components/SubCats";

function Home() {
  const [isLoading, setIsloading] = React.useState(false);
  const [sections, setSections] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [subCats, setSubCats] = React.useState([]);
  const [sliders, setSliders] = React.useState([]);
  const [newArrivals, setNewArrivals] = React.useState([]);
  const [discounts, setDiscounts] = React.useState([]);

  async function getMain(type, productTypeId = "") {
    let PID = ``;
    if (
      productTypeId !== "" &&
      productTypeId !== undefined &&
      productTypeId !== null
    ) {
      PID = `&id=${productTypeId}`;
    } else {
      PID = ``;
    }
  }

  const getSections = async (id) => {
    axiosPrivate
      .get(
        `/ClientSide/Get?objectName=5&subLevelId=${id}&start=0&end=1000000&sort=sort&order=asc`
      )
      .then((res) => {
        setSections(res.data);
        setCategories([]);
        setSubCats([]);
      })
      .finally(() => {
        setIsloading(false);
      });
  };
  const getCats = async (id) => {
    axiosPrivate
      .get(
        `/ClientSide/Get?objectName=5&subLevelId=${id}&start=0&end=1000000&sort=sort&order=asc`
      )
      .then((res) => {
        setCategories(res.data);
        setSubCats([]);
      })
      .finally(() => {
        setIsloading(false);
      });
  };
  const getSubCat = async (id) => {
    axiosPrivate
      .get(
        `/ClientSide/Get?objectName=5&subLevelId=${id}&start=0&end=1000000&sort=sort&order=asc`
      )
      .then((res) => {
        setSubCats(res.data);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  useEffect(() => {
    axiosPrivate
      .get(
        "/ClientSide/GetGroup?GroupProductType=FrontPageSlider&start=0&end=1000000&sort=sort&order=asc"
      )
      .then((res) => {
        setSliders(res.data);
      });

    axiosPrivate
      .get(
        "/ClientSide/GetCurrentGroupProducts?GroupProductType=NewArrivals&start=0&end=1000000"
      )
      .then((res) => {
        setNewArrivals(res.data);
      });

    axiosPrivate
      .get(
        "/ClientSide/GetCurrentGroupProducts?GroupProductType=Discount&start=0&end=1000000"
      )
      .then((res) => {
        setDiscounts(res.data);
      });
  }, [0]);
  if (isLoading)
    return (
      <div className="w-100 h-screen d-flex justify-content-center align-items-center">
        <span className="loader-admin">Loading</span>
      </div>
    );
  return (
    <div>
      <section className="bg-secondary py-4">
        <div className="container">
          <div className="row">
            {/* <div className="col-xl-2 hideOnMobile">
              <div
                className="table-respo
              
              
                  nsive"
                data-simplebar
              >
                <div className="d-flex d-xl-block">
                  <h5 className={`text-center`}>{window.t("discount")}</h5>
                  <CustomCarousel
                    itemsPerPage={3}
                    itemsToShow={3}
                    isVertical={true}
                    pagination={true}
                    itemPadding={[0, 50]}
                  >
                    {discounts &&
                      discounts.map((discount, index) => (
                        <Link
                          className="d-flex align-items-center bg-faded-info rounded-3 ps-2 mb-4 me-4 me-xl-0"
                          key={index}
                          to={`/product-details/${discount.id}`}
                          style={{ minWidth: "16rem" }}
                        >
                          <img
                            src={helpers.getImageUrl(discount.attachment)}
                            width="125"
                            alt="Banner"
                          />
                          <div className="px-2">
                            <span>{discount.discount}%</span>
                            <h5 className="mb-2">
                              <span
                                className="fw-light"
                                style={{ fontSize: 11 }}
                              >
                                {discount.name}
                              </span>
                            </h5>
                            <div className="text-info fs-sm">
                              <i className="ci-arrow-right fs-xs ms-1"></i>
                              {window.t("shopNow")}
                            </div>
                          </div>
                        </Link>
                      ))}
                  </CustomCarousel>
                </div>
              </div>
            </div> */}
            <div className="col-xl-8  mx-auto ">
              <SwiperSlider>
                {sliders?.map((slider, index) => (
                  <SwiperSlide key={index}>
                    <div className="row">
                      <div className="col-md-12">
                        <img
                          className="d-block mx-auto"
                          src={helpers.getImageUrl(slider.attachment)}
                          alt={slider.name}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </SwiperSlider>
            </div>
            {/* <div className="col-xl-2 hideOnMobile">
              <div className="table-responsive" data-simplebar>
                <div className="d-flex d-xl-block">
                  <h5 className={`text-center`}>{window.t("newSrrivals")}</h5>
                  <CustomCarousel
                    itemsPerPage={3}
                    itemsToShow={3}
                    isVertical={true}
                    pagination={true}
                    itemPadding={[0, 50]}
                  >
                    {newArrivals &&
                      newArrivals.map((newArrival, index) => (
                        <Link
                          className="d-flex align-items-center bg-faded-info rounded-3 ps-2 mb-4 me-4 me-xl-0"
                          key={index}
                          to={`/product-details/${newArrival.id}`}
                          style={{ minWidth: "16rem" }}
                        >
                          <img
                            src={helpers.getImageUrl(newArrival.attachment)}
                            width="125"
                            alt="Banner"
                          />
                          <div className="px-2 pLs pR">
                            <h5 className="mb-2">
                              <span
                                className="fw-light"
                                style={{ fontSize: 11 }}
                              >
                                {helpers.getTextByLength(newArrival.name, 40)}
                              </span>
                            </h5>
                            <div className="text-info fs-sm">
                              <i className="ci-arrow-right fs-xs ms-1"></i>
                              {window.t("shopNow")}
                            </div>
                          </div>
                        </Link>
                      ))}
                  </CustomCarousel>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <Departments getSections={getSections} />
      <Sections sections={sections} getCats={getCats} />
      <Categories categories={categories} getSubCat={getSubCat} />
      <SubCats subCats={subCats} />
      {/* <TrendingProducts /> */}
      {/* <HomeWidgets /> */}
      <FollowUs />
    </div>
  );
}

export default Home;
