import React from "react";

function FollowUs() {
  return (
    <section className="container-fluid px-0 mt-3">
      <div className="row g-0">
        <div className="col-md-6">
          <a
            className="card border-0 rounded-0 text-decoration-none py-md-4 bg-faded-accent"
            href="https://www.facebook.com/chinainiraq1"
            _target="_blank"
          >
            <div className="card-body text-center">
              <i className="ci-facebook h3 mt-2 mb-4 text-accent"></i>
              <h3 className="h5 mb-1">{window.t("followSocialMedia2")}</h3>
              <p className="text-muted fs-sm">China in Iraq</p>
            </div>
          </a>
        </div>
        <div className="col-md-6">
          <a
            className="card border-0 rounded-0 text-decoration-none py-md-4 bg-faded-primary"
            href="https://instagram.com/china.in.iraq?igshid=YmMyMTA2M2Y="
            _target="_blank"
          >
            <div className="card-body text-center">
              <i className="ci-instagram h3 mt-2 mb-4 text-primary"></i>
              <h3 className="h5 mb-1">{window.t("followSocialMedia")}</h3>
              <p className="text-muted fs-sm">china.in.iraq</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default FollowUs;
