import React from "react";
import { MdEditNote } from "react-icons/md";
import { IoEyeSharp } from "react-icons/io5";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { useRevalidator } from "react-router-dom";
import { HTTP } from "../axios";
import { TbStatusChange } from "react-icons/tb";
import ToastMessage from "./ToastMessage";

export default function ActionButton(props) {
  const revalidator = useRevalidator();
  const {
    modal,
    setActionData,
    p,
    isShow,
    isDelete,
    isStatus,
    deleteFunc,
    deletePaymentType,
  } = props;
  return (
    <button
      className={`btn btn-${
        isDelete ? "primary" : isStatus ? "create" : "action"
      }`}
      data-bs-toggle={`${isDelete ? "" : "modal"}`}
      data-bs-target={`${isDelete ? "" : `#${modal}`} `}
      onClick={() => {
        if (isDelete) {
          deleteFunc(p.data.id, deletePaymentType || "")
            .then(() => {
              revalidator.revalidate();
              <ToastMessage title="Successfully Created" icon="success" />;
            })
            .catch((error) => {
              <ToastMessage
                title="Something went wrong, Try Again"
                icon="error"
                text={error.response.data.message}
              />;
            });
        } else {
          setActionData(p.data);
        }
      }}
    >
      {isShow ? (
        <IoEyeSharp style={{ fontSize: 25 }} />
      ) : isDelete ? (
        <MdOutlineDeleteSweep style={{ fontSize: 25 }} />
      ) : isStatus ? (
        <TbStatusChange style={{ fontSize: 25 }} />
      ) : (
        <MdEditNote style={{ fontSize: 25 }} />
      )}
    </button>
  );
}
export const CloseButton = () => {
  return (
    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
      Close
    </button>
  );
};
export const SubmitButton = (props) => {
  const revalidator = useRevalidator();
  const { endPoint, data, setData } = props;
  return (
    <button
      onClick={() => {
        HTTP.post(endPoint, data)
          .then(() => {
            setData({});
            revalidator.revalidate();
          })
          .then(() => {
            <ToastMessage title="Successfully Created" icon="success" />;
          })
          .catch((error) => {
            <ToastMessage
              title="Something went wrong, Try Again"
              icon="error"
              text={error.response.data.message}
            />;
          });
      }}
      type="button"
      className="btn btn-outline-create"
      data-bs-dismiss="modal"
    >
      Submit
    </button>
  );
};
export const UpdateButton = (props) => {
  const revalidator = useRevalidator();
  const { endPoint, data, setData } = props;
  return (
    <button
      onClick={() => {
        HTTP.put(endPoint, data)
          .then(() => {
            setData({});
            revalidator.revalidate();
          })
          .then(() => {
            <ToastMessage title="Successfully Updated" icon="success" />;
          })
          .catch((error) => {
            <ToastMessage
              title="Something went wrong, Try Again"
              icon="error"
              text={error.response.data.message}
            />;
          });
      }}
      type="button"
      className="btn btn-action"
      data-bs-dismiss="modal"
    >
      Update
    </button>
  );
};
