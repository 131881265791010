import React from "react";
import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Shop from "./Pages/Shop";
import ProductDetails from "./Pages/ProductDetails";
import Profile from "./Pages/Profile";
import Cart from "./Pages/Cart";
import NotFound from "./Pages/NotFound";
import Orders from "./Pages/Orders";
import FavList from "./Pages/FavList";
import OrderingLayout from "./Ordering/Pages/OrderingLayout";
import OnlineLayout from "./Pages/OnlineLayout";
import MainLayout from "./Pages/MainLayout";

import Customers, {
  loader as customersLoader,
} from "./Ordering/Pages/Customer/Customers";
import CustomerPayments, {
  loader as CustomerPaymentsLoader,
} from "./Ordering/Pages/Customer/CustomerPayments";
import CustomerInvoices, {
  loader as CustomerInvoicesLoader,
} from "./Ordering/Pages/Customer/CustomerInvoices";
import CustomerAccounts, {
  loader as CustomerAccountsLoader,
} from "./Ordering/Pages/Customer/CustomerAccounts";

import Suppliers, {
  loader as suppliersLoader,
} from "./Ordering/Pages/Supplier/Suppliers";
import SupplierPayments, {
  loader as supplierPaymentsLoader,
} from "./Ordering/Pages/Supplier/SupplierPayments";

import Login from "./Ordering/Pages/Login";
import User, { loader as userLoader } from "./Ordering/Pages/User";
import OrderList, {
  loader as orderLoader,
} from "./Ordering/Pages/Orders/OrderList";
import CustomerOrders, {
  loader as customerOrderLoader,
} from "./Ordering/Pages/Orders/CustomerOrders";
import SupplierOrders, {
  loader as supplierOrderLoader,
} from "./Ordering/Pages/Orders/SupplierOrders";
import CompareOrders, {
  loader as compareOrdersLoader,
} from "./Ordering/Pages/Orders/CompareOrders";
import ErrorEle from "./Ordering/Component/ErrorEle";
import MainOrderingLayout from "./Pages/MainOrderingLayout";
import OrderPerSupplierInvoice from "./Pages/OrderPerSupplierInvoice";

import ProductsList, {
  loader as productsLoader,
} from "./Ordering/Pages/Products/ProductsList";

import ProductsCreate from "./Ordering/Pages/Products/ProductsCreate";
import ProductsSize, {
  loader as productSizeLoader,
} from "./Ordering/Pages/Products/ProductsSize";

import ProductsRamSize, {
  loader as productSizeRamLoader,
} from "./Ordering/Pages/Products/ProductsRamSize";

import ProductsScreenSize, {
  loader as productSizeScreenLoader,
} from "./Ordering/Pages/Products/ProductsScreenSize";

import ProductsColor, {
  loader as productColorLoader,
} from "./Ordering/Pages/Products/ProductsColor";

import Brands, {
  loader as brandsLoader,
} from "./Ordering/Pages/Products/Brands";

import MadeIn, {
  loader as madeInLoader,
} from "./Ordering/Pages/Products/MadeIn";

import Tags, { loader as tagsLoader } from "./Ordering/Pages/Products/Tags";

import ProductTypes, {
  loader as productTypesLoader,
} from "./Ordering/Pages/Products/ProductTypes";
import FrontPageSlider, {
  loader as FrontPageSliderLoader,
} from "./Ordering/Pages/Utilites/FrontPageSlider";

import NewArrivals, {
  loader as newArrivalsLoader,
} from "./Ordering/Pages/Utilites/NewArrivals";
import Discount, {
  loader as discountLoader,
} from "./Ordering/Pages/Utilites/Discount";
import Currencies, {
  loader as currenciesLoader,
} from "./Ordering/Pages/Utilites/Currencies";

import InstallmentMethods, {
  loader as installmentMethodsLoader,
} from "./Ordering/Pages/Utilites/InstallmentMethods";

import Contract, {
  loader as ContractLoader,
} from "./Ordering/Pages/Contract/Contract";
import CreateContract from "./Ordering/Pages/Contract/CreateContract";
import TelegramGroups from "./Components/TelegramGroups";

const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<MainLayout />}>
      <Route path="" element={<OnlineLayout />}>
        <Route index element={<Home />} />
        <Route path="shop" element={<Shop />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="product-details/:id" element={<ProductDetails />} />
        <Route path="cart" element={<Cart />} errorElement={<ErrorEle />} />
        <Route path="FavList" element={<FavList />} />
        <Route path="telegramGroups" element={<TelegramGroups />} />
        <Route path="profile" element={isLoggedIn && <Profile />} />
        <Route path="orders" element={isLoggedIn && <Orders />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="Ordering" element={<OrderingLayout />}>
        <Route
          path=""
          element={<MainOrderingLayout />}
          errorElement={<ErrorEle />}
        >
          <Route index element={<p>fasdf</p>} />
          <Route path="login" element={<Login />} />
          <Route path="Products" element={<Outlet />}>
            <Route index element={<ProductsList />} loader={productsLoader} />
            <Route path="create" element={<ProductsCreate />} />
            <Route
              path="size"
              element={<ProductsSize />}
              loader={productSizeLoader}
            />
            <Route
              path="RamSize"
              element={<ProductsRamSize />}
              loader={productSizeRamLoader}
            />
            <Route
              path="ScreenSize"
              element={<ProductsScreenSize />}
              loader={productSizeScreenLoader}
            />
            <Route
              path="Color"
              element={<ProductsColor />}
              loader={productColorLoader}
            />
            <Route path="Brands" element={<Brands />} loader={brandsLoader} />
            <Route path="MadeIn" element={<MadeIn />} loader={madeInLoader} />
            <Route path="Tags" element={<Tags />} loader={tagsLoader} />
            <Route
              path="Types"
              element={<ProductTypes />}
              loader={productTypesLoader}
            />
          </Route>
          <Route path="utilities">
            <Route
              path="slider"
              element={<FrontPageSlider />}
              loader={FrontPageSliderLoader}
            />
            <Route
              path="newArrivals"
              element={<NewArrivals />}
              loader={newArrivalsLoader}
            />
            <Route
              path="discount"
              element={<Discount />}
              loader={discountLoader}
            />
            <Route
              path="currencies"
              element={<Currencies />}
              loader={currenciesLoader}
            />
            <Route
              path="installmentMethods"
              element={<InstallmentMethods />}
              loader={installmentMethodsLoader}
            />
          </Route>

          <Route path="customers">
            <Route index element={<Customers />} loader={customersLoader} />
            <Route
              path="payments"
              element={<CustomerPayments />}
              loader={CustomerPaymentsLoader}
            />
            <Route
              path="invoices"
              element={<CustomerInvoices />}
              loader={CustomerInvoicesLoader}
            />
            <Route
              path="accounts"
              element={<CustomerAccounts />}
              loader={CustomerAccountsLoader}
            />
          </Route>
          <Route path="suppliers">
            <Route index element={<Suppliers />} loader={suppliersLoader} />
            <Route
              path="payments"
              element={<SupplierPayments />}
              loader={supplierPaymentsLoader}
            />
          </Route>
          <Route path="contracts">
            <Route index element={<Contract />} loader={ContractLoader} />
            <Route path="create" element={<CreateContract />} />
          </Route>
          <Route path="orders">
            <Route index element={<OrderList />} loader={orderLoader} />
            <Route
              path="customerOrders"
              element={<CustomerOrders />}
              loader={customerOrderLoader}
            />
            <Route
              path="supplierOrders"
              element={<SupplierOrders />}
              loader={supplierOrderLoader}
            />
            <Route
              path="compareOrders"
              element={<CompareOrders />}
              loader={compareOrdersLoader}
            />
          </Route>
          <Route path="users" element={<User />} loader={userLoader} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route
        path="/Orders/Name/:code"
        element={<OrderPerSupplierInvoice />}
        errorElement={<ErrorEle />}
      />
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={routes} />;
};

export default App;
