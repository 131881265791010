import React from "react";
import { getSuppliers } from "../../api";
import { Await, defer, useLoaderData, useRevalidator } from "react-router-dom";
import DataGrid, { BooleanCellData } from "../../Component/DataGrid";
import { deleteSupplier } from "../../api";
import SupplierModal from "../../Component/SupplierModal";
import AcitionButton from "../../Component/ActionButton";
import ToastMessage from "../../Component/ToastMessage";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");

  const PromisedData = getSuppliers(sort, order, search);
  return defer({ data: PromisedData });
};

export default function Suppliers() {
  const data = useLoaderData();

  const [actionData, setActionData] = React.useState({});
  const [isShow, setIsShow] = React.useState(false);
  const Actions = (p) => {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center gap-2">
        <span
          onClick={() => {
            setIsShow(false);
          }}
        >
          <AcitionButton
            modal="supplierModal"
            setActionData={setActionData}
            p={p}
          />
        </span>
        <span
          onClick={() => {
            setIsShow(true);
          }}
        >
          <AcitionButton
            modal="supplierModal"
            setActionData={setActionData}
            p={p}
            isShow={true}
          />
        </span>

        <AcitionButton
          modal="supplierModal"
          setActionData={setActionData}
          p={p}
          isDelete={true}
          deleteFunc={deleteSupplier}
        />
        <button
          className="btn btn-action"
          onClick={() => {
            navigator.clipboard.writeText(p.data.code);
            <ToastMessage title="Successfully Copied!" icon="success" />;
          }}
        >
          Copy
        </button>
      </div>
    );
  };

  const [colDefs, setColDefs] = React.useState([
    {
      field: "name",
      headerName: "Full Name",
    },
    {
      field: "phone",
    },
    {
      field: "address",
    },
    {
      field: "currentLoan",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },

    {
      field: "hasAccount",
      cellRenderer: BooleanCellData,
    },
    {
      field: "actions",
      cellRenderer: Actions,
      flex: 2,
    },
  ]);

  return (
    <section className="p-3">
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return (
              <DataGrid
                hasCreate={true}
                modalType={"supplierModal"}
                data={data}
                col={colDefs}
              />
            );
          }}
        </Await>
      </React.Suspense>

      <SupplierModal isShow={isShow} actionData={actionData} />
    </section>
  );
}
