import React from "react";
import helpers from "../Constants/helpers";
import QuickViewModal from "../Components/QuickViewModal";
import { Link, useNavigate } from "react-router-dom";
import axiosPrivate from "../Axios";

export default function FavList() {
  const [favItems, setFavItems] = React.useState(
    JSON.parse(localStorage.getItem("favItems")) || []
  );
  localStorage.setItem("favItems", JSON.stringify(favItems));
  const [productId, setProcuctId] = React.useState(0);
  const [isAdded, setIsAdded] = React.useState(0);
  const navigate = useNavigate();
  const fullName = localStorage.getItem("fullName") || "";
  const image = `\\${localStorage.getItem("image")}` || "";
  const isLoggedIn =
    localStorage.getItem("isLoggedIn") === "true" ? true : false;

  React.useEffect(() => {}, [0]);
  const deleteItem = (id) => {
    const tempFavItems = favItems?.filter((item) => item.id !== id);
    setFavItems(tempFavItems);
  };

  const addToCart = (id) => {
    setProcuctId(id);
    helpers.openModal();
  };

  React.useEffect(() => {
    if (isAdded) {
      deleteItem(productId);
    }
  }, [isAdded]);

  return (
    <section>
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link to={"/"} className="text-nowrap" href="index.html">
                    <i className="ci-home"></i>Home
                  </Link>
                </li>

                <li
                  className="breadcrumb-item text-nowrap active"
                  aria-current="page"
                >
                  Wishlist
                </li>
              </ol>
            </nav>
          </div>
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">My wishlist</h1>
          </div>
        </div>
      </div>
      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <aside className="col-lg-3 pt-4 pt-lg-0 pe-xl-5">
            {isLoggedIn ? (
              <div className="bg-white rounded-3 shadow-lg pt-1 mb-5 mb-lg-0">
                <div className="d-md-flex justify-content-between align-items-center text-center text-md-start p-4">
                  <div className="d-flex flex-column flex-md-row gap-3 align-items-center">
                    <div
                      className="img-thumbnail shadow mx-auto "
                      style={{ width: " 4rem" }}
                    >
                      <img
                        className="rounded"
                        src={helpers.getImageUrl(image)}
                        alt="Susan Gardner"
                      />
                    </div>
                    <div className="">
                      <h3 className="fs-base mb-0">{fullName}</h3>
                    </div>
                  </div>
                  <a
                    className="btn btn-primary d-lg-none mb-2 mt-3 mt-md-0 text-decoration-none"
                    href="#account-menu"
                    data-bs-toggle="collapse"
                    aria-expanded="false"
                  >
                    <i className="ci-menu me-2"></i>
                    Account menu
                  </a>
                </div>
                <div className="d-lg-block collapse" id="account-menu">
                  <div className="bg-secondary px-4 py-3">
                    <h3 className="fs-sm mb-0 text-light">Info</h3>
                  </div>
                  <ul className="list-unstyled mb-0">
                    <li className="border-bottom mb-0">
                      <Link
                        to={"/cart"}
                        className="nav-link-style d-flex align-items-center px-4 py-3"
                      >
                        <i className="ci-bag opacity-60 me-2"></i>Orders
                        <span className="fs-sm text-muted ms-auto">
                          {helpers.getCartItemNumbers()}
                        </span>
                      </Link>
                    </li>
                  </ul>

                  <ul className="list-unstyled mb-0">
                    <li className="border-bottom mb-0">
                      <Link
                        to={"/profile"}
                        className="nav-link-style d-flex align-items-center px-4 py-3"
                      >
                        <i className="ci-user opacity-60 me-2"></i>Profile info
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-3 shadow-lg pt-1 mb-5 mb-lg-0">
                <div
                  className="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2 cursor-pointer"
                  href="/#"
                  data-bs-toggle="modal"
                  data-bs-target="#signin-modal"
                >
                  <div className="navbar-tool-icon-box">
                    <i className="navbar-tool-icon ci-user"></i>
                  </div>
                  <p className="">Login / Sign Up</p>{" "}
                </div>
              </div>
            )}
          </aside>
          <section className="col-lg-9">
            <div className="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
              <h6 className="fs-base text-light mb-0">
                List of items you added to wishlist:
              </h6>
              {isLoggedIn && (
                <span
                  className="btn btn-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("firstName");
                    localStorage.removeItem("middleName");
                    localStorage.removeItem("lastName");
                    localStorage.removeItem("fullName");
                    localStorage.removeItem("image");
                    localStorage.removeItem("isLoggedIn");
                    delete axiosPrivate.defaults.headers.common[
                      "Authorization"
                    ];
                    helpers.closeAllModals();
                    navigate("/");
                  }}
                >
                  <i className="ci-sign-out me-2 fs-base align-middle"></i>
                  {window.t("logout")}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-2">
              {favItems?.map((favItem, i) => {
                return (
                  <div className="d-flex justify-content-between gap-4 border-bottom border-secondary pb-3">
                    <div className="list-image shadow overflow-hidden rounded">
                      <img
                        className="w-100 h-100 object-cover scale-110"
                        src={helpers.getImageUrl(favItem.attachment)}
                        alt="Product"
                      />
                    </div>
                    <div
                      key={i}
                      className="d-flex flex-column flex-1 w-100 gap-2 justify-content-between text-center text-start"
                    >
                      <div className="">
                        <h3 className="product-title text-start fs-sm mb-2 ">
                          {favItem.name}
                        </h3>
                        <div className="fs-sm d-flex justify-content-between flex-wrap">
                          <span className="text-muted me-2">Color: Khaki</span>
                          <span className="text-muted me-2">
                            Code: {favItem.code}
                          </span>
                        </div>
                        {/* <div className="fs-lg text-accent pt-2"></div> */}
                      </div>

                      <div className="d-flex gap-2 justify-content-between text-center">
                        <button
                          className="btn btn-sm btn-primary-background d-flex justify-content-center flex-1"
                          type="button"
                          onClick={() => {
                            deleteItem(favItem.id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                          </svg>
                          <span className="fs-md d-none d-md-block">
                            Remove
                          </span>
                        </button>
                        <button
                          className="btn btn-sm btn-outline-create  flex-1 d-flex justify-content-center"
                          type="button"
                          onClick={() => {
                            addToCart(favItem.id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-cart-plus"
                            viewBox="0 0 16 16"
                          >
                            <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9z" />
                            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                          </svg>
                          <span className="fs-md d-none d-md-block">
                            Add to Cart
                          </span>
                        </button>{" "}
                      </div>
                      <button
                        className="btn flex-1 p-0 btn-sm btn-outline-action cursor-auto "
                        type="button"
                      >
                        <span className=""> ${favItem.price}</span>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
      <QuickViewModal setIsAdded={setIsAdded} productId={productId} />
    </section>
  );
}
