import React from "react";
import { CloseButton, SubmitButton } from "../../Component/ActionButton";
import Input from "../../Component/Input";
import { GiPayMoney } from "react-icons/gi";
import { AiOutlinePercentage } from "react-icons/ai";

import { GiReceiveMoney } from "react-icons/gi";

import AsyncSelect from "react-select/async";
import {
  brandLoadOptions,
  brunchLoadOptions,
  colorLoadOptions,
  countryLoadOptions,
  diskSizeLoadOptions,
  firstProductTypeLoadOptions,
  ProductTypeLoadOptions,
  secondProductTypeLoadOptions,
  supplierLoadOptions,
  tagLoadOptions,
  thirdProductTypeLoadOptions,
} from "../../api";
import SelectAttachment from "../../Component/SelectAttachment";
import { useSearchParams } from "react-router-dom";
export default function ProductsCreate(props) {
  const { actionData, isShow } = props;
  const [data, setData] = React.useState();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div className="p-5 bg-light">
      <h1 className=" fs-5">
        {data?.id
          ? isShow
            ? "Show Product"
            : "Edit Product"
          : "Create Product"}
      </h1>

      <div className=" d-flex flex-column gap-4">
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="code"
            name="code"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
          <Input
            label="barcode"
            name="barcode"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />{" "}
          <div className="flex-1">
            <p>Select Supplier: </p>
            <AsyncSelect
              placeholder="Select Supplier"
              name="price"
              required={true}
              onChange={(val) => {
                setData({
                  ...data,
                  supplierId: val?.value,
                  supplierName: val?.label,
                });
              }}
              loadOptions={supplierLoadOptions}
            />
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="name"
            name="name"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
          <Input
            label="kurdishName"
            name="kuName"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
          <Input
            label="arabicName"
            name="arName"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>

        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="cost"
            name="cost"
            type="number"
            isGroup={true}
            icon={<GiPayMoney className="h2 m-0 text-light" />}
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />{" "}
          <Input
            label="discountPercentage"
            isGroup={true}
            name="discount"
            icon={<AiOutlinePercentage className="h2 m-0 text-light" />}
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
          <Input
            label="price"
            isGroup={true}
            name="price"
            type="number"
            icon={<GiReceiveMoney className="h2 m-0 text-light" />}
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>

        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="containes"
            name="containes"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />{" "}
          <Input
            label="shippingPrice"
            name="shippingPrice"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
          <Input
            label="shippingTime"
            name="shippingTime"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>

        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="length"
            name="length"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />{" "}
          <Input
            label="width"
            name="width"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
          <Input
            label="height"
            name="height"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>

        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="cbm"
            name="cbm"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />{" "}
          <Input
            label="minOrder"
            name="minOrder"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />{" "}
          <Input
            label="weight"
            name="weight"
            type="number"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />{" "}
        </div>

        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <div className="flex-1">
            <p>Select seasons: </p>
            <select className="form-select" aria-label="Default select example">
              <option value="winter">winter</option>
              <option value="spring">spring</option>
              <option value="summer">summer</option>
              <option value="autumn">autumn</option>
            </select>
          </div>
          <div className="flex-1">
            <p>Made In: </p>
            <AsyncSelect
              name="cost"
              type="number"
              data={data}
              required={true}
              onChange={(val) => {
                setData({
                  ...data,
                  brandId: val?.value,
                  brandName: val?.label,
                });
              }}
              loadOptions={countryLoadOptions}
            />
          </div>
          <div className="flex-1">
            <p>Select Status: </p>
            <select className="form-select" aria-label="Default select example">
              <option value="available">Available</option>
              <option value="unavailable">Unavailable</option>
              <option value="temporaryUnAvailable">
                Temporary UnAvailable
              </option>
              <option value="iraqStock">Iraq Stock</option>
              <option value="chinaStock">China Stock</option>
              <option value="iranStock">Iran Stock</option>
              <option value="turkeyStock">Turkey Stock</option>
              <option value="uaeStock">UAE Stock</option>
              <option value="europeStock">Europe Stock</option>
            </select>
          </div>
        </div>

        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="description"
            name="description"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="kurdishDescription"
            name="kuDescription"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="arabicDescription"
            name="arDescription"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <div className="flex-1">
            <p>Select Product Type: </p>
            <AsyncSelect
              required={true}
              onChange={(val) => {
                setData({
                  ...data,
                  firstLevelType: val?.value,
                  secondLevelType: null,
                  thirdLevelType: null,
                  productTypeId: null,
                });
                setSearchParams({ firstProdutTypeId: val?.value });
              }}
              loadOptions={firstProductTypeLoadOptions}
            />
          </div>
          <div className="flex-1">
            <p>Select Product Type: </p>
            <AsyncSelect
              required={true}
              isDisabled={!data?.firstLevelType}
              onChange={(val) => {
                setData({
                  ...data,
                  secondLevelType: val?.value,
                  thirdLevelType: null,
                  productTypeId: null,
                });
                setSearchParams({
                  secondProdutTypeId: val?.value,
                });
              }}
              loadOptions={secondProductTypeLoadOptions}
            />
          </div>
          <div className="flex-1">
            <p>Select Product Type: </p>
            <AsyncSelect
              required={true}
              isDisabled={!data?.secondLevelType}
              onChange={(val) => {
                setData({
                  ...data,
                  thirdLevelType: val?.value,
                  productTypeId: null,
                });
                setSearchParams({ thirdProdutTypeId: val?.value });
              }}
              loadOptions={thirdProductTypeLoadOptions}
            />
          </div>
          <div className="flex-1">
            <p>Select Product Type: </p>
            <AsyncSelect
              required={true}
              isDisabled={!data?.thirdLevelType}
              onChange={(val) => {
                setData({
                  ...data,
                  productTypeId: val?.value,
                });
                setSearchParams({ productTypeId: val?.value });
              }}
              loadOptions={ProductTypeLoadOptions}
            />
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <div className="flex-1">
            <p>Select Brand: </p>
            <AsyncSelect
              name="cost"
              type="number"
              data={data}
              required={true}
              onChange={(val) => {
                setData({
                  ...data,
                  brandId: val?.value,
                  brandName: val?.label,
                });
              }}
              loadOptions={brandLoadOptions}
            />
          </div>

          <div className="flex-1">
            <p>Select Tags: </p>
            <AsyncSelect
              label="discountPercentage"
              name="products"
              required={true}
              isMulti
              onChange={(val) => {
                let tags = [];
                val?.map((item) => {
                  tags.push({ id: item?.value });
                });
                setData({
                  ...data,
                  tags,
                });
              }}
              loadOptions={tagLoadOptions}
            />
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <div className="flex-1">
            <p>Select Disk Size: </p>
            <AsyncSelect
              name="cost"
              type="number"
              data={data}
              isMulti
              required={true}
              onChange={(val) => {
                let availableDiskSizes = [];
                val?.map((item) => {
                  availableDiskSizes.push({ diskSizeId: item?.value });
                });
                setData({
                  ...data,
                  availableDiskSizes,
                });
              }}
              loadOptions={diskSizeLoadOptions}
            />
          </div>
          <div className="flex-1">
            <p>Select Ram Size: </p>
            {/* {RAMSizeLoadOptions.map((option, index) => {
              return (
                <div key={index} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                  />
                  <label className="form-check-label" for="inlineCheckbox1">
                    {option?.label}
                  </label>
                </div>
              );
            })} */}

            {/* <AsyncSelect
              required={true}
              isMulti
              onChange={(val) => {
                let availableRamSizes = [];
                val?.map((item) => {
                  availableRamSizes.push({ ramSizeId: item?.value });
                });
                setData({
                  ...data,
                  availableRamSizes,
                });
              }}
              loadOptions={RAMSizeLoadOptions}
            /> */}
          </div>
          <div className="flex-1">
            <p>Select Color: </p>
            <AsyncSelect
              required={true}
              isMulti
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  color: state.data.hexCode,
                  textShadow: `0 0 1.5px #000`,
                }),
              }}
              onChange={(val) => {
                let availableColors = [];
                val?.map((item) => {
                  availableColors.push({ colorId: item?.value });
                });
                setData({
                  ...data,
                  availableColors,
                });
              }}
              loadOptions={colorLoadOptions}
            />
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <div className="flex-1">
            <p>Select Brunch: </p>
            <AsyncSelect
              data={data}
              required={true}
              onChange={(val) => {
                setData({
                  ...data,
                  brandId: val?.value,
                });
              }}
              loadOptions={brunchLoadOptions}
            />
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-center align-items-center w-100">
          <Input
            label="note"
            name="note"
            type="text"
            data={data}
            required={true}
            formDataHandler={formDataHandler}
          />
        </div>

        {!data?.id && (
          <div className="d-flex gap-4 justify-content-center align-items-center w-100">
            <SelectAttachment
              data={data}
              setData={setData}
              label="thumbnail"
              className="flex-1 d-block"
            />
          </div>
        )}
        {!data?.id && !isShow && (
          <div className="d-flex gap-4 justify-content-center align-items-center w-100">
            <SelectAttachment
              data={data}
              setData={setData}
              label="attachment"
              className="flex-1 d-block"
            />
          </div>
        )}
      </div>
      <CloseButton />

      {!data?.id && (
        <SubmitButton endPoint={`/products`} data={data} setData={setData} />
      )}
    </div>
  );
}
