import React, { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  getCodes,
  getOrderByInvoice,
  getOrderPerInovoice,
} from "../Ordering/api";
import DataGrid from "../Ordering/Component/DataGrid";
import AsyncSelect from "react-select/async";
import helpers from "../Constants/helpers";
import ActionButton from "./../Ordering/Component/ActionButton";
import ChangePriceQty from "./../Ordering/Component/ChangePriceQty";

const image = (p) => {
  return (
    <span className="d-flex align-items-center gap-2 w-100 h-100 border-1">
      {p.data.name && (
        <img
          className=""
          alt={`${p.data.name}`}
          src={helpers.getImageUrl(p.data?.attachment)}
          style={{
            display: "block",
            width: "75px",
            height: "75px",
            objectFit: "cover",
            border: "1px solid rgb(217,217,219",
          }}
        />
      )}
      <p
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {p?.data?.name}
      </p>
    </span>
  );
};

export default function OrderPerSupplierInvoice() {
  const params = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(false);

  const [data, setData] = React.useState([]);
  const [actionData, setActionData] = React.useState({});

  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [invCode, setInvCode] = React.useState(0);
  const Actions = (p) => {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center gap-2">
        <ActionButton
          setActionData={setActionData}
          modal="changePriceQty"
          p={p}
        />
      </div>
    );
  };
  const [colDefs, setColDefs] = React.useState([
    {
      field: "pid",
      headerName: "PID",
    },
    {
      headerName: "Product",
      cellRenderer: image,
      flex: 4,
    },
    {
      field: "code",
    },
    {
      field: "price",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "qty",
      headerName: "Quantity",
      valueFormatter: (p) => {
        return `${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "actions",
      cellRenderer: Actions,
      flex: 2,
    },
  ]);

  const CodesLoadOptions = async (search, callback) => {
    const codes = await getCodes(search, isConfirmed, params?.code);
    let options = [];
    codes.map((code) => {
      options.push({
        label: code,
      });
    });
    callback(options);
  };

  const getData = async () => {
    const data = await getOrderByInvoice(
      searchParams.get("sort") || "id",
      searchParams.get("order") || "Desc",
      searchParams.get("search"),
      params?.code,
      isConfirmed,
      invCode
    );
    setData(data);
    setIsLoading(false);
  };

  return (
    <section className="">
      <nav className="navbar navbar-expand-lg bg-primary text-light">
        <div className="container-fluid">
          <Link to={"../"} className="navbar-brand text-light">
            China Start
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown link
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>{" "}
      <div className="p-3 d-flex justify-content-center align-items-center gap-3 flex-column">
        <div className="d-flex w-100 gap-3 justify-content-center align-items-center">
          <AsyncSelect
            placeholder="Select Supplier"
            className="flex-1"
            onChange={(e) => {
              setInvCode(e.label);
            }}
            loadOptions={CodesLoadOptions}
            defaultOptions
          />
          <div className="form-check form-switch">
            <input
              onClick={(e) => {
                setIsConfirmed(!isConfirmed);
              }}
              value={isConfirmed}
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              Checked Orders
            </label>
          </div>
          <button
            disabled={invCode === 0}
            onClick={() => {
              setIsLoading(true);
              getData();
            }}
            className="btn btn-primary flex-1"
          >
            Search
          </button>
        </div>
        {isLoading ? (
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        ) : (
          <DataGrid data={data} col={colDefs} rowHeight={80} />
        )}
        <ChangePriceQty params={params} actionData={actionData} />
      </div>
    </section>
  );
}
