import "react-toastify/dist/ReactToastify.css";

import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MainLayout() {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  window.t = t;
  window.lang = i18n;

  return (
    <main>
      <Outlet />
    </main>
  );
}
