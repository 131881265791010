import React, { useState, useEffect } from "react";
import PaginationComponent from "../Components/Pagination";
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

function Orders() {
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(1000);
  const [perPage, setPerPage] = useState(80);
  const [currentPage, setCurrentPage] = useState(1);
  const pagesCount = Math.ceil(totalCount / perPage);
  const [invoices, setInvoices] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState(0);

  useEffect(() => {
    axiosPrivate
      .get(`/ClientSide/GetInvoices`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setInvoices(response.data);
        setTotalCount(parseInt(response.headers["x-total-count"]));
      })
      .catch((error) => {
        if (error.response.status) {
          helpers.logout();
          navigate("/");
        }
      });
  }, []);

  const getInvoiceOrders = (invoiceId) => {
    axiosPrivate
      .get(`/ClientSide/GetInvoices/${invoiceId}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setOrders(response.data);
        setOrdersTotal(
          response.data.orders
            .reduce((total, item) => total + item.price * item.qty, 0)
            .toFixed(2)
        );
      })
      .catch();
  };

  return (
    <div className={`m-3`}>
      <section className={`container`}>
        <div className="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
          <div className="d-flex align-items-center">
            <label
              className="d-none d-lg-block fs-sm text-nowrap opacity-75 me-2"
              htmlFor="order-sort"
            >
              Sort orders:
            </label>
            <select className="form-select" id="order-sort">
              <option>All</option>
              <option>Delivered</option>
              <option>In Progress</option>
              <option>Delayed</option>
              <option>Canceled</option>
            </select>
          </div>
        </div>
        <div className="table-responsive fs-md mb-4">
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th>Order #</th>
                <th>Date Purchased</th>
                <th>Status</th>
                <th>Qty</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {invoices &&
                invoices.map((invoice, index) => (
                  <tr key={index}>
                    <td className="py-3">
                      <a
                        className="nav-link-style fw-medium fs-sm"
                        href="#order-details"
                        data-bs-toggle="modal"
                        onClick={() => {
                          getInvoiceOrders(invoice.id);
                        }}
                      >
                        {invoice.invoiceNumber}
                      </a>
                    </td>
                    <td className="py-3">
                      {moment(invoice.date).format("llll")}
                    </td>
                    <td className="py-3">
                      <span className="badge bg-info m-0">In Progress</span>
                    </td>
                    <td className="py-3">{invoice.totalQty}</td>
                    <td className="py-3">
                      {invoice.finalPrice.toLocaleString()}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <nav
          className="d-flex justify-content-between pt-2"
          aria-label="Page navigation"
        >
          <PaginationComponent
            itemsCount={totalCount}
            itemsPerPage={perPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            alwaysShown={false}
          />
        </nav>
        <div className="modal fade" id="order-details">
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Order No - 34VB5540K83</h5>
                <button
                  className="btn-close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body pb-0">
                {orders &&
                  orders.orders &&
                  orders.orders.map((order, index) => (
                    <div
                      className="d-sm-flex justify-content-between mb-4 pb-3 pb-sm-2 border-bottom"
                      key={index}
                    >
                      <div className="d-sm-flex text-center text-sm-start">
                        <Link
                          className="d-inline-block flex-shrink-0 mx-auto"
                          to={`/product-details/${order.product.id}`}
                          style={{ width: "10rem" }}
                        >
                          <img
                            src={helpers.getImageUrl(order.product.attachment)}
                            alt="Product"
                            style={{ maxHeight: "150px" }}
                          />
                        </Link>
                        <div className="ps-sm-4 pt-2">
                          <h3 className="product-title fs-base mb-2">
                            <Link to={`/product-details/${order.product.id}`}>
                              {order.product.name}
                            </Link>
                          </h3>
                          <div className="fs-sm">
                            <span className="text-muted me-2">Size:</span>
                            {order.diskSize.name}
                          </div>
                          <div className="fs-sm">
                            <span className="text-muted me-2">Color:</span>White
                            &amp; Blue
                          </div>
                          <div className="fs-lg text-accent pt-2">
                            {parseFloat(order.price)}
                          </div>
                        </div>
                      </div>
                      <div className="pt-2 ps-sm-3 mx-auto mx-sm-0 text-center">
                        <div className="text-muted mb-2">Quantity:</div>
                        {parseFloat(order.qty)}
                      </div>
                      <div className="pt-2 ps-sm-3 mx-auto mx-sm-0 text-center">
                        <div className="text-muted mb-2">Subtotal: </div>$
                        {helpers.roundToTwo(order.finalPrice)}
                      </div>
                    </div>
                  ))}
              </div>
              <div className="modal-footer flex-wrap justify-content-between bg-secondary fs-md">
                <div className="px-2 py-1">
                  <span className="text-muted">Total:&nbsp;</span>
                  <span className="fs-lg">{ordersTotal}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Orders;
