import React from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import SearchInput from "../Component/SearchInput";
import helpers from "../../Constants/helpers";
import OrderStatus from "./OrderStatus";
import {
  Link,
  useLocation,
  useRevalidator,
  useSearchParams,
} from "react-router-dom";
import {
  customerLoadOptions,
  firstProductTypeLoadOptions,
  getCustomers,
  getSuppliers,
  secondProductTypeLoadOptions,
  thirdProductTypeLoadOptions,
} from "../api";
import AsyncSelect from "react-select/async";

export const BooleanCellData = ({ value }) => (
  <span
    style={{
      display: "flex",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
    }}
  >
    {
      <img
        alt={`${value}`}
        src={`https://www.ag-grid.com/example-assets/icons/${
          value ? "tick-in-circle" : "cross-in-circle"
        }.png`}
        style={{ width: "auto", height: "auto" }}
      />
    }
  </span>
);

export const image = (p) => {
  return (
    <span className="d-flex align-items-center gap-2 w-100 h-100 border-1">
      <img
        className=""
        alt={`${p?.data?.product?.name || p?.data?.name}`}
        src={helpers.getImageUrl(
          p?.data?.product?.attachment || p?.data?.attachment
        )}
        style={{
          display: "block",
          width: "75px",
          height: "75px",
          objectFit: "cover",
          border: "1px solid rgb(217,217,219",
        }}
      />
      <p
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {p?.data?.product?.name || p?.data?.name}
      </p>
    </span>
  );
};

export default function DataGrid(props) {
  const rowData = props?.data;

  const setActionData = props?.setActionData;
  const modalType = props?.modalType;
  const rowClassRules = props?.rowClassRules;

  const revalidator = useRevalidator();
  const [searchParams, setSearchParams] = useSearchParams();

  const status = React.useRef(searchParams.get("status") || "underReview");
  const search = React.useRef(searchParams.get("search") || "");
  const currentSort = React.useRef(searchParams.get("sort") || "");
  const order = React.useRef(searchParams.get("order") || "");
  const customerId = React.useRef(searchParams.get("customerId") || "");
  const supplierId = React.useRef(searchParams.get("supplierId") || "");
  const productTypeFilter = React.useRef({ first: 0, second: 0, third: 0 });

  const objURl = React.useRef({});

  const filterURL = () => {
    const lastParameter = Object.fromEntries(
      Object.entries(objURl.current).filter(([key, value]) => value !== "")
    );
    setSearchParams(lastParameter);
    revalidator.revalidate();
  };

  const searchHandler = React.useCallback(
    (searchText) => {
      search.current = searchText;
      objURl.current = { ...objURl.current, search: searchText };
      filterURL();
    },
    [search.current]
  );
  const statusHandler = React.useCallback(
    (statusText) => {
      status.current = statusText;
      objURl.current = { ...objURl.current, status: statusText };
      filterURL();
    },
    [status.current]
  );
  const sortHandler = React.useCallback(
    (sort) => {
      if (sort === currentSort.current) {
        if (order.current === "" || order.current === null) {
          order.current = "ASC";
        } else if (order.current === "ASC") {
          order.current = "DESC";
        } else {
          order.current = "";
        }
      } else {
        order.current = "ASC";
      }

      objURl.current = { ...objURl.current, sort, order: order.current };

      filterURL();

      currentSort.current = sort;
    },
    [order.current, currentSort, search.current]
  );
  const selectCustomerIdHandler = React.useCallback(
    (selectedCustomer) => {
      customerId.current = selectedCustomer?.value;
      objURl.current = { ...objURl.current, customerId: customerId.current };
      filterURL();
    },
    [customerId.current]
  );
  const selectSupplierIdHandler = React.useCallback(
    (selectedSupplier) => {
      supplierId.current = selectedSupplier?.value;
      objURl.current = { ...objURl.current, supplierId: supplierId.current };
      filterURL();
    },
    [supplierId.current]
  );
  const selectFirstProductTypeHandler = React.useCallback(
    (item) => {
      console.log(item);
      productTypeFilter.current.first = item?.value;
      objURl.current = {
        ...objURl.current,
        productTypeId: productTypeFilter.current.first,
        firstProdutTypeId: productTypeFilter.current.first,
      };
      filterURL();
    },
    [productTypeFilter.current.first]
  );
  const selectSecondProductTypeHandler = React.useCallback(
    (item) => {
      productTypeFilter.current.second = item?.value;
      objURl.current = {
        ...objURl.current,
        productTypeId: productTypeFilter.current.second,
        secondProdutTypeId: productTypeFilter.current.second,
      };
      filterURL();
    },
    [productTypeFilter.current.second]
  );
  const selectThirdProductTypeHandler = React.useCallback(
    (item) => {
      productTypeFilter.current.third = item?.value;
      objURl.current = {
        ...objURl.current,
        productTypeId: productTypeFilter.current.third,
        thirdProdutTypeId: productTypeFilter.current.third,
      };
      filterURL();
    },
    [productTypeFilter.current.third]
  );

  const CustomHeader = (p) => {
    return (
      <div
        onClick={() => {
          sortHandler(p.column.colId);
        }}
        className="custom-header"
      >
        <span>{p?.displayName}</span>
      </div>
    );
  };

  const defaultColDef = React.useMemo(() => ({
    flex: 1,
    headerComponent: CustomHeader,
  }));
  const columnDefs = [
    {
      field: "id",
      headerName: "ID",
    },
    ...props?.col,
  ];

  const SupplierLoadOptions = async (search, callback) => {
    const suppliers = await getSuppliers("", "", search);

    let options = [];

    options.push({ label: "None", value: "" });
    suppliers.map((supplier) => {
      options.push({
        label: supplier.name,
        value: supplier.id,
        supplier,
      });
    });
    callback(options);
  };

  return (
    <div
      className={"ag-theme-quartz d-flex flex-column gap-3"}
      style={{ width: "100%", height: "80vh" }}
    >
      <div className="d-flex justify-content-start align-items-center gap-4 ">
        <SearchInput searchHandler={searchHandler} />
        {props?.orders === true && (
          <OrderStatus
            invoiceOptionsStatus={props.invoiceOptionsStatus}
            constStatus={props.constStatus}
            value={status.current}
            statusHandler={statusHandler}
          />
        )}
        {props?.hasCustomerSearch === true && (
          <AsyncSelect
            className="w-100"
            placeholder="Select Customer  "
            onChange={(e) => {
              selectCustomerIdHandler(e);
            }}
            loadOptions={customerLoadOptions}
            defaultOptions
          />
        )}{" "}
        {props?.hasSupplierSearch === true && (
          <AsyncSelect
            placeholder="Select Supplier"
            className="w-100"
            onChange={(e) => {
              selectSupplierIdHandler(e);
            }}
            loadOptions={SupplierLoadOptions}
            defaultOptions
          />
        )}
        {props?.hasProductTypeFilter === true && (
          <AsyncSelect
            className="w-100"
            required={true}
            onChange={(e) => {
              selectFirstProductTypeHandler(e);
            }}
            loadOptions={firstProductTypeLoadOptions}
          />
        )}
        {props?.hasProductTypeFilter === true && (
          <AsyncSelect
            required={true}
            className="w-100"
            isDisabled={productTypeFilter.current.first === 0}
            onChange={(e) => {
              selectSecondProductTypeHandler(e);
            }}
            loadOptions={secondProductTypeLoadOptions}
          />
        )}
        {props?.hasProductTypeFilter === true && (
          <AsyncSelect
            required={true}
            className="w-100"
            isDisabled={productTypeFilter.current.second === 0}
            onChange={(e) => {
              selectThirdProductTypeHandler(e);
            }}
            loadOptions={thirdProductTypeLoadOptions}
          />
        )}
        {props?.hasCreate &&
          (props?.Link ? (
            <Link
              to={"create"}
              className="btn btn-outline-create w-100 text-decoration-none"
            >
              Add +{" "}
            </Link>
          ) : (
            <button
              className="btn btn-outline-create w-100 fw-bold"
              data-bs-toggle="modal"
              data-bs-target={`#${modalType}`}
              onClick={() => {
                setActionData(null);
              }}
            >
              Add +{" "}
            </button>
          ))}
      </div>
      <AgGridReact
        rowClassRules={rowClassRules}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowHeight={props?.rowHeight || 60}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 25, 50, 100, 200, 400, 800]}
      />
    </div>
  );
}
