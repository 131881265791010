import React from "react";
import { getUsers } from "../api";
import { Await, defer, useLoaderData } from "react-router-dom";
import DataGrid, { BooleanCellData } from "../Component/DataGrid";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");

  const PromisedData = getUsers(sort, order, search);
  return defer({ data: PromisedData });
};
export default function User() {
  const data = useLoaderData();

  const [colDefs, setColDefs] = React.useState([
    {
      headerName: "Full Name",
      valueFormatter: (p) =>
        `${p.data.firstName} ${p.data.middleName} ${p.data.lastName}`,
    },
    {
      field: "phone",
    },
    {
      field: "userType",
    },
  ]);

  return (
    <section className="p-3">
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return <DataGrid data={data} col={colDefs} />;
          }}
        </Await>
      </React.Suspense>
    </section>
  );
}
