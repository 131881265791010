import React from "react";
import helpers from "../Constants/helpers";
import { Link } from "react-router-dom";
import CornerRibbon from "react-corner-ribbon";

function SingleProduct({ product, setProductId, favItems, setFavItems }) {
  let isFav = false;

  for (let i = 0; i < favItems?.length; i++) {
    if (product.id === favItems[i].id) {
      isFav = true;
      break;
    }
  }
  return (
    product && (
      <div
        className="card product-card overflow-hidden h-100"
        style={{
          padding: 0,
          border: "1px solid transparent ",
          borderRadius: 5,
        }}
      >
        {product?.shippingPrice === 0 && (
          <CornerRibbon
            position="top-right"
            fontColor="#f0f0f0"
            backgroundColor="#2c7"
          >
            {window.t("freeShipping")}
          </CornerRibbon>
        )}
        <div className="overflow-hidden w-100 position-relative">
          <img
            src={helpers.getImageUrl(product?.attachment)}
            alt={product?.name}
            className="img-scale w-100 d-none d-md-block"
            style={{
              height: "300px",
              objectFit: "cover",
            }}
          />
          <img
            src={helpers.getImageUrl(product?.attachment)}
            alt={product?.name}
            className="img-scale w-100 d-md-none"
            style={{
              height: "150px",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="card-body p-2 p-md-3">
          <h3
            style={{ height: "42px" }}
            className="product-title fs-md text-center text-container mb-3"
          >
            {product?.name}
          </h3>
          {/* price, code */}
          <div className="d-flex justify-content-center justify-content-md-between gap-2">
            <div className="product-price flex-1">
              <p className="btn btn-sm px-2 fs-2xs btn-primary w-100 cursor-auto ">
                {window.prefix !== "null" && window.prefix}
                {parseFloat(
                  parseFloat(
                    product?.price * parseFloat(window.rate ?? 1)
                  ).toFixed(2)
                ).toLocaleString()}{" "}
                {window.suffix !== "null" && window.suffix}
              </p>
            </div>
            <div className="product-price flex-1">
              <p className="btn btn-sm px-2 fs-2xs btn-outline-primary w-100  cursor-auto">
                {product?.code}
                {window.suffix !== "null" && window.suffix}
              </p>
            </div>
          </div>
        </div>
        {/* End price, code */}
        {/* Order, View */}
        <div className="bg-dark overflow-hidden row justify-content-between px-3 align-items-center ">
          {" "}
          <button
            onClick={() => {
              if (isFav) {
                const tempFavItems = favItems.filter(
                  (item) => item.id !== product.id
                );
                setFavItems(tempFavItems);
                helpers.getFavItemNumbers(tempFavItems.length);
              } else {
                setFavItems((prev) => [...prev, product]);
                helpers.getFavItemNumbers(favItems.length + 1);
              }
            }}
            className={`col-6 btn btn-sm btn-secondary rounded-0 rounded fs-ms text-light py-1 py-md-2 d-flex justify-content-center gap-2 border-end`}
            href="account-wishlist.html"
          >
            {isFav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-heart-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-heart"
                viewBox="0 0 16 16"
              >
                <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
              </svg>
            )}{" "}
            <span className="d-none d-md-inline ">Wishlist</span>
          </button>
          <Link
            to={`/product-details/${product?.id}`}
            className="col-6 btn btn-sm btn-secondary rounded-0 rounded fs-ms text-light py-1 py-md-2"
            style={{ cursor: "pointer" }}
          >
            <i className="ci-eye align-middle me-1"></i>
            <span className="d-none d-md-inline">View Product</span>
          </Link>
        </div>

        <button
          type="button"
          onClick={() => {
            setProductId(product?.id);
            helpers.openModal();
          }}
          className="col-12 border-top btn btn-sm btn-secondary rounded-0 rounded fs-ms text-light py-1 py-md-2"
          style={{ cursor: "pointer" }}
        >
          <i className="ci-cart fs-lg me-1"></i>
          <span className="d-none d-md-inline">Quick Order</span>
        </button>
      </div>
    )
  );
}

export default SingleProduct;
