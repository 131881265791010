import React from "react";
import { CloseButton, SubmitButton, UpdateButton } from "./ActionButton";

export default function CustomerInvoiceModal(props) {
  const { actionData, isShow } = props;

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const [data, setData] = React.useState();

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="customerModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {data?.id
                ? isShow
                  ? "Show Customer"
                  : "Edit Customer"
                : "Add Customer"}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="">
                <label htmlFor="first-name" className="col-form-label">
                  First Name:
                </label>
                <input
                  required
                  type="text"
                  name="firstName"
                  disabled={isShow}
                  value={data?.firstName}
                  onChange={formDataHandler}
                  className="form-control"
                  id="first-name"
                />
              </div>
              <div className="">
                <label htmlFor="middle-name" className="col-form-label">
                  Middle Name:
                </label>
                <input
                  required
                  type="text"
                  disabled={isShow}
                  name="middleName"
                  value={data?.middleName}
                  onChange={formDataHandler}
                  className="form-control"
                  id="middle-name"
                />
              </div>
              <div className="">
                <label htmlFor="last-name" className="col-form-label">
                  Last Name:
                </label>
                <input
                  required
                  disabled={isShow}
                  name="lastName"
                  type="text"
                  value={data?.lastName}
                  onChange={formDataHandler}
                  className="form-control"
                  id="last-name"
                />
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="">
                <label htmlFor="middle-name" className="col-form-label">
                  Phone:
                </label>
                <input
                  required
                  type="number"
                  disabled={isShow}
                  name="phone"
                  value={data?.phone}
                  onChange={formDataHandler}
                  className="form-control"
                  id="middle-name"
                />
              </div>
              <div className="">
                <label htmlFor="last-name" className="col-form-label">
                  Phone 2:
                </label>
                <input
                  name="phone2"
                  disabled={isShow}
                  type="number"
                  value={data?.phone2}
                  onChange={formDataHandler}
                  className="form-control"
                  id="last-name"
                />
              </div>
              <div className="">
                <label htmlFor="first-name" className="col-form-label">
                  Address:
                </label>
                <input
                  name="address"
                  disabled={isShow}
                  type="text"
                  value={data?.address}
                  onChange={formDataHandler}
                  className="form-control"
                  id="first-name"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <CloseButton />
            {!isShow &&
              (data?.id ? (
                <UpdateButton
                  endPoint={`/customers/${data?.id}`}
                  data={data}
                  setData={setData}
                />
              ) : (
                <SubmitButton
                  endPoint={`/custosmers`}
                  data={data}
                  setData={setData}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
