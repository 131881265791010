import React from "react";

export default function OrderStatus(props) {
  const {
    constStatus,
    value,
    statusHandler,
    invoiceOptionsStatus,
    formDataHandler,
    data,
  } = props;

  return (
    <select
      value={constStatus || value}
      disabled={constStatus}
      name="orderStatus"
      onChange={(e) => {
        data?.id ? formDataHandler(e) : statusHandler(e.target.value);
      }}
      className="form-select w-100"
      aria-label="Default select example"
    >
      <option selected value={""}>
        Select The Status
      </option>
      {invoiceOptionsStatus ? (
        <>
          <option value="UnChecked">Un Checked</option>
          <option value="Checked">Checked</option>
          <option value="Finance">Finance</option>
          <option value="Accepted">Accepted</option>
          <option value="DestinationReceived">Destination Received</option>
          <option value="OnTheWay">On The Way</option>
          <option value="TargetReceived">Target Received</option>
          <option value="Delivered">Delivered</option>
          <option value="Rejected">Rejected</option>
          <option value="Shipped">Shipped</option>
        </>
      ) : (
        <>
          <option value="UnderReview" selected="">
            Under Review
          </option>
          <option value="Reviewed">Reviewed</option>
          <option value="SentToSupplier">Sent To Supplier</option>
          <option value="SupplierAccepted">Supplier Accepted</option>
          <option value="CustomerAccepted">Customer Accepted</option>
          <option value="SendToFinance">Send To Finance</option>
          <option value="Accepted">Accepted</option>
          <option value="DestinationReceived">Destination Received</option>
          <option value="OnTheWay">On The Way</option>
          <option value="TargetReceived">Target Received</option>
          <option value="Delivered">Delivered</option>
          <option value="Rejected">Rejected</option>
          <option value="Shipped">Shipped</option>
        </>
      )}
    </select>
  );
}
