import React from "react";
import { HTTP } from "../axios";
import { useRevalidator } from "react-router-dom";
import { CloseButton, SubmitButton, UpdateButton } from "./ActionButton";

export default function CustomerInvoiceModal(props) {
  const { actionData, isShow } = props;

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const revalidator = useRevalidator();

  const [data, setData] = React.useState();

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="supplierModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {data?.id
                ? isShow
                  ? "Show Supplier"
                  : `Edit Supplier`
                : "Add Supplier"}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body d-flex flex-column gap-3">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="full-name" className="col-form-label">
                  Full Name:
                </label>
                <input
                  required
                  type="text"
                  name="name"
                  disabled={isShow}
                  value={data?.name}
                  onChange={formDataHandler}
                  className="form-control"
                  id="full-name"
                />
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="ku-name" className="col-form-label">
                  Kurdish Name:
                </label>
                <input
                  disabled={isShow}
                  type="text"
                  name="kuName"
                  value={data?.kuName}
                  onChange={formDataHandler}
                  className="form-control"
                  id="ku-name"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="ar-name" className="col-form-label">
                  Arabic Name:
                </label>
                <input
                  type="text"
                  disabled={isShow}
                  name="arName"
                  value={data?.arName}
                  onChange={formDataHandler}
                  className="form-control"
                  id="ar-name"
                />
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="phone" className="col-form-label">
                  Phone:
                </label>
                <input
                  required
                  type="number"
                  disabled={isShow}
                  value={data?.phone}
                  name="phone"
                  onChange={formDataHandler}
                  className="form-control"
                  id="phone"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="phone-2" className="col-form-label">
                  Phone 2:
                </label>
                <input
                  name="phone2"
                  disabled={isShow}
                  value={data?.phone2}
                  type="number"
                  onChange={formDataHandler}
                  className="form-control"
                  id="phone-2"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="address" className="col-form-label">
                  Address:
                </label>
                <input
                  name="address"
                  disabled={isShow}
                  value={data?.address}
                  type="text"
                  onChange={formDataHandler}
                  className="form-control"
                  id="address"
                />
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="email" className="col-form-label">
                  Email:
                </label>
                <input
                  type="email"
                  disabled={isShow}
                  value={data?.email}
                  name="email"
                  onChange={formDataHandler}
                  className="form-control"
                  id="email"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="country" className="col-form-label">
                  Country:
                </label>
                <input
                  name="country"
                  disabled={isShow}
                  type="text"
                  value={data?.country}
                  onChange={formDataHandler}
                  className="form-control"
                  id="country"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="city" className="col-form-label">
                  City:
                </label>
                <input
                  name="city"
                  disabled={isShow}
                  value={data?.city}
                  type="text"
                  onChange={formDataHandler}
                  className="form-control"
                  id="city"
                />
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="roof-loan" className="col-form-label">
                  Roof Loan:
                </label>
                <input
                  type="number"
                  disabled={isShow}
                  value={data?.roofLoan}
                  name="roofLoan"
                  onChange={formDataHandler}
                  className="form-control"
                  id="roof-loan"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="profit" className="col-form-label">
                  Profit:
                </label>
                <input
                  name="profit"
                  disabled={isShow}
                  value={data?.profit}
                  type="number"
                  onChange={formDataHandler}
                  className="form-control"
                  id="profit"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="discount" className="col-form-label">
                  Discount:
                </label>
                <input
                  disabled={isShow}
                  name="discount"
                  value={data?.discount}
                  type="number"
                  onChange={formDataHandler}
                  className="form-control"
                  id="discount"
                />
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="inner-shipping-time" className="col-form-label">
                  Inner Shipping Time:
                </label>
                <input
                  type="number"
                  value={data?.innerShippingTime}
                  disabled={isShow}
                  name="innerShippingTime"
                  onChange={formDataHandler}
                  className="form-control"
                  id="inner-shipping-time"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="shippingTime" className="col-form-label">
                  Shipping Time:
                </label>
                <input
                  value={data?.shippingTime}
                  disabled={isShow}
                  name="shippingTime"
                  type="number"
                  onChange={formDataHandler}
                  className="form-control"
                  id="shippingTime"
                />
              </div>
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="note" className="col-form-label">
                  Note:
                </label>
                <input
                  name="note"
                  disabled={isShow}
                  value={data?.note}
                  type="text"
                  onChange={formDataHandler}
                  className="form-control"
                  id="note"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <CloseButton />
            {!isShow &&
              (data?.id ? (
                <UpdateButton
                  endPoint={`/suppliers/${data?.id}`}
                  data={data}
                  setData={setData}
                />
              ) : (
                <SubmitButton
                  endPoint={`/suppliers`}
                  data={data}
                  setData={setData}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
