import React from "react";

export default function Input(props) {
  return (
    <div className={`flex-${props?.flex || 1} `}>
      {props?.label && (
        <label htmlFor="code" className="col-form-label p-0">
          {props?.label}:
        </label>
      )}
      <div class={`${props?.isGroup && "input-group"}`}>
        {props?.isGroup && (
          <span className="input-group-text bg-secondary" id="basic-addon1">
            {props?.icon}
          </span>
        )}
        <input
          className="form-control"
          type={props?.type}
          required={props?.required}
          name={props?.name}
          disabled={props?.data?.id || props?.disabled}
          value={props?.value || props?.data?.[`${props?.name}`]}
          onChange={props?.formDataHandler || props?.onChange}
          // aria-label="Username"
          // aria-describedby="basic-addon1"
        />{" "}
      </div>
    </div>
  );
}
