import React from "react";
import helpers from "../../Constants/helpers";

export default function SelectAttachment(props) {
  const { data, setData, label, className } = props;
  return (
    <div className={className}>
      <label htmlFor="note" className="col-form-label">
        {label}:
      </label>
      <input
        type="file"
        // disabled={data?.id}
        className="form-control"
        name="attachment"
        onChange={async (e) => {
          const file = e.target.files[0];
          const base64 = await helpers.getBase64(file);
          setData({ ...data, [`${label}`]: base64 });
        }}
        id="img"
        aria-describedby="inputGroupFileAddon04"
        aria-label="Upload"
      />
      {data?.[`${label}`] && (
        <div className="position-relative d-flex gap-4 justify-content-center align-items-center w-100 my-3">
          <a
            href={data?.id && `${helpers.getImageUrl(data?.[`${label}`])}`}
            target="_blank"
          >
            <img
              src={
                data?.id
                  ? helpers.getImageUrl(data?.[`${label}`])
                  : data?.[`${label}`]
              }
              alt=""
            />
          </a>
          {!data?.id && (
            <button
              onClick={() => setData({ ...data, [`${label}`]: "" })}
              className="m-1 shadow-lg btn btn-primary position-absolute top-0 start-0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </button>
          )}
        </div>
      )}
    </div>
  );
}
