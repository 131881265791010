import React from "react";

import {
  CloseButton,
  SubmitButton,
  UpdateButton,
} from "../../../Component/ActionButton";

export default function SizeModal(props) {
  const { actionData, type } = props;

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const [data, setData] = React.useState();
  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="sizeModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Product {type}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="flex-1">
                <label htmlFor="name" className="col-form-label">
                  name:
                </label>
                <input
                  required
                  name="name"
                  value={data?.name}
                  onChange={formDataHandler}
                  className="form-control"
                  id="name"
                />
              </div>

              <div className="flex-1">
                <label htmlFor="sizeInGB" className="col-form-label">
                  {type === "Size" || type === "Ram"
                    ? "Size In GB"
                    : type === "Screen"
                    ? "Size"
                    : type === "Color" && "Color"}
                  :
                </label>

                <input
                  style={{
                    height: type === "Color" && "45px",
                    padding: type === "Color" && "0",
                  }}
                  name={
                    type === "Size" || type === "Ram"
                      ? "sizeInGB"
                      : type === "Screen"
                      ? "size"
                      : type === "Color" && "hexCode"
                  }
                  value={
                    type === "Size" || type === "Ram"
                      ? data?.sizeInGB
                      : type === "Screen"
                      ? data?.size
                      : type === "Color" && data?.hexCode
                  }
                  type={type !== "Color" ? "number" : "color"}
                  onChange={formDataHandler}
                  className="form-control"
                  id={
                    type === "Size" || type === "Ram"
                      ? "sizeInGB"
                      : type === "Screen"
                      ? "size"
                      : type === "Color" && "hexCode"
                  }
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {!data?.id && (
              <SubmitButton
                endPoint={`/Main?objectName=${
                  type === "Size"
                    ? "AvailableDiskSizes"
                    : type === "Ram"
                    ? "AvailableRamSizes"
                    : type === "Screen"
                    ? "12"
                    : type === "Color" && "AvailableColors"
                }`}
                data={data}
                setData={setData}
              />
            )}
            <CloseButton />
            {data?.id && (
              <UpdateButton
                endPoint={`/Main/${data?.id}?objectName=${
                  type === "Size"
                    ? "AvailableDiskSizes"
                    : type === "Ram"
                    ? "AvailableRamSizes"
                    : type === "Screen"
                    ? "12"
                    : type === "Color" && "AvailableColors"
                }`}
                data={data}
                setData={setData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
