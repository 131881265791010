import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import CategoryIcon from "@mui/icons-material/Category";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ReceiptIcon from "@mui/icons-material/Receipt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { RxSize } from "react-icons/rx";
import { MdOutlineStorage } from "react-icons/md";
import { CgScreenShot } from "react-icons/cg";
import { IoIosColorPalette } from "react-icons/io";
import { TbBrandUnity } from "react-icons/tb";
import { MdOutlineCallMade } from "react-icons/md";
import { BsFillTagsFill } from "react-icons/bs";
import { VscUngroupByRefType } from "react-icons/vsc";
import { GrServices } from "react-icons/gr";
import { BsSliders2Vertical } from "react-icons/bs";
import { LiaPlaneArrivalSolid } from "react-icons/lia";
import { RiDiscountPercentFill } from "react-icons/ri";
import { BsCurrencyExchange } from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi";

import { Link, useNavigate } from "react-router-dom";
export default function SideBar() {
  const navigate = useNavigate();
  const [items, setMenuItems] = React.useState([
    {
      title: "dashboard",
      active: false,
      route: "",
      icon: <DashboardIcon />,
    },

    {
      title: "products",
      icon: <CategoryIcon />,
      active: false,
      subMenu: [
        { title: "products", route: "products", icon: <CategoryIcon /> },
        {
          title: "size",
          route: "products/size",
          icon: <RxSize />,
        },
        {
          title: "RamSize",
          route: "products/RamSize",
          icon: <MdOutlineStorage />,
        },
        {
          title: "ScreenSize",
          route: "products/ScreenSize",
          icon: <CgScreenShot />,
        },
        {
          title: "color",
          route: "products/color",
          icon: <IoIosColorPalette />,
        },
        {
          title: "brands",
          route: "products/brands",
          icon: <TbBrandUnity />,
        },
        {
          title: "madeIn",
          route: "products/madeIn",
          icon: <MdOutlineCallMade />,
        },
        {
          title: "tags",
          route: "products/tags",
          icon: <BsFillTagsFill />,
        },
        {
          title: "types",
          route: "products/types",
          icon: <VscUngroupByRefType />,
        },
      ],
    },
    {
      title: "utilities",
      icon: <GrServices />,
      active: false,
      subMenu: [
        {
          title: "slider",
          route: "utilities/slider",
          icon: <BsSliders2Vertical />,
        },
        {
          title: "newArrivals",
          route: "utilities/newArrivals",
          icon: <LiaPlaneArrivalSolid />,
        },
        {
          title: "discount",
          route: "utilities/discount",
          icon: <RiDiscountPercentFill />,
        },
        {
          title: "currencies",
          route: "utilities/currencies",
          icon: <BsCurrencyExchange />,
        },
        {
          title: "installmentMethods",
          route: "utilities/installmentMethods",
          icon: <GiPayMoney />,
        },
      ],
    },
    {
      title: "customers",
      icon: <GroupsIcon />,
      active: false,
      subMenu: [
        { title: "customers", route: "customers", icon: <GroupsIcon /> },
        {
          title: "payments",
          route: "customers/payments",
          icon: <PriceCheckIcon />,
        },
        {
          title: "invoices",
          route: "customers/invoices",
          icon: <ReceiptIcon />,
        },
        {
          title: "accounts",
          route: "customers/accounts",
          icon: <AccountBoxIcon />,
        },
      ],
    },
    {
      title: "suppliers",
      icon: <Diversity3Icon />,
      active: false,
      subMenu: [
        {
          title: "suppliers",
          route: "suppliers",
          icon: <Diversity3Icon />,
        },
        {
          title: "payments",
          route: "suppliers/payments",
          icon: <PriceCheckIcon />,
        },
      ],
    },
    {
      title: "contracts",
      active: false,
      icon: <Diversity3Icon />,
      route: "contracts",
    },
    {
      title: "orders",
      icon: <ShoppingCartIcon />,
      active: false,
      subMenu: [
        { title: "orders", route: "orders", icon: <ShoppingCartIcon /> },
        {
          title: "customerOrders",
          route: "orders/customerOrders",
          icon: <GroupsIcon />,
        },
        {
          title: "supplierOrders",
          route: "orders/supplierOrders",
          icon: <Diversity3Icon />,
        },
        {
          title: "compareOrders",
          route: "orders/compareOrders",
          icon: <CompareArrowsIcon />,
        },
      ],
    },
    {
      title: "users",
      active: false,
      route: "users",
      icon: <SupervisedUserCircleIcon />,
    },
  ]);

  const activeHandler = (i) => {
    const updatedMenuItems = items.map((item, index) => {
      if (index === i) {
        if (item.active === true) {
          return { ...item, active: false };
        } else {
          return { ...item, active: true };
        }
      } else {
        return { ...item, active: false };
      }
    });
    setMenuItems(updatedMenuItems);
  };
  const [minimize, setMinimize] = React.useState(false);

  return (
    <section>
      <div className="bg-secondary text-center fs-5 p-3 d-flex justify-content-between align-items-center gap-5 border-bottom ">
        {!minimize && <p>Company Name </p>}
        <MenuIcon
          onClick={() => {
            setMinimize(!minimize);
          }}
        />
      </div>
      <div className="bg-secondary h-screen d-flex flex-column justify-content-start ">
        {items.map((item, index) => {
          return (
            <div key={index} className="text-light">
              <div
                onClick={() => {
                  item?.subMenu?.length > 0
                    ? activeHandler(index)
                    : navigate(`/ordering/${item?.route}`);
                }}
                className=" btn btn-secondary rounded-0 d-flex py-3 px-2 justify-content-between align-items-center gap-2 border-bottom"
              >
                <span className="d-flex align-items-center gap-2">
                  {item.icon}
                  {!minimize && <p>{item.title}</p>}
                </span>
                {item?.subMenu?.length > 0 &&
                  (item.active ? (
                    <KeyboardArrowUpIcon
                      className={`${minimize ? "ms-1" : "ms-4"}`}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      className={`${minimize ? "ms-1" : "ms-4"}`}
                    />
                  ))}
              </div>
              {item?.subMenu?.length > 0 && item?.active && (
                <div>
                  {item?.subMenu?.map((subItem, index) => {
                    return (
                      <div
                        onClick={() => navigate(`/ordering/${subItem.route}`)}
                        className={`ps-4 btn btn-secondary-varint rounded-0 d-flex p-2 justify-content-start align-items-center gap-2 border-bottom`}
                      >
                        {subItem.icon}
                        {!minimize && <p>{subItem.title}</p>}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}
