import React, { useEffect, useState } from "react";
import axiosPrivate from "../Axios";
import helpers from "../Constants/helpers";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Swal from "sweetalert2";

function QuickViewModal({ productId, setIsAdded }) {
  const [product, setProduct] = useState({});
  const [qty, setQty] = useState(1);
  const [selectedImage, setSelectedImage] = useState({});
  const [selectedDiskSize, setSelectedDiskSize] = useState(0);
  const [selectedRamSize, setSelectedRamSize] = useState(0);
  const [selectedScreenSize, setSelectedScreenSize] = useState(0);
  const [selectedColor, setSelectedColor] = useState(0);

  useEffect(() => {
    if (productId !== 0) {
      axiosPrivate
        .get(`/ClientSide/GetProductById?id=${productId}`)
        .then((response) => {
          response.data.attachments.unshift({
            attachment: response.data.attachment,
          });
          setProduct(response.data);
          setSelectedImage({ attachment: response.data.attachment });
        })
        .catch();
    }
  }, [productId]);

  const addToBasket = (item) => {
    if (qty <= 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: window.t("pleaseWriteQuantity"),
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }
    if (qty < product.minOrder) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: window.t("cantOrderLessThanMinQuantity"),
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }
    if (qty % product.minOrder !== 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title:
          window.t("factorOrderingInfo") +
          product.minOrder +
          ", " +
          product.minOrder * 2 +
          ", " +
          product.minOrder * 3 +
          ", " +
          product.minOrder * 4,
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }
    if (selectedDiskSize === 0) {
      setSelectedDiskSize(1);
      // Swal.fire({
      //   position: "top-end",
      //   icon: "error",
      //   title: "Please select size",
      //   showConfirmButton: false,
      //   timer: 1100,
      // });
      // return;
    }
    localStorage.setItem(
      `item_${item.id}`,
      JSON.stringify({
        barcode: item.barcode,
        code: item.code,
        id: item.id,
        name: item.name,
        price: item.price,
        qty: qty,
        brandId: item.brandId,
        brandName: item.brandName,
        productTypeId: item.productTypeId,
        productTypeName: item.productTypeName,
        attachment: item.attachment,
        thumbnail: item.thumbnail,
        diskSize: selectedDiskSize,
        ramSize: selectedRamSize,
        screenSize: selectedScreenSize,
        color: selectedColor,
      })
    );
    helpers.getCartItemNumbers();
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: window.t("addedToCart"),
      showConfirmButton: false,
      timer: 1100,
    });
    setIsAdded(true);
    helpers.closeAllModals();
  };

  return (
    <div className="modal-quick-view modal fade" id="quick-view" tabIndex="-1">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title product-title">
              <a
                href="/#"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Go to product page"
              >
                {product && product.name}
                <i className="ci-arrow-right fs-lg ms-2"></i>
              </a>
            </h4>
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-7 pe-lg-0">
                <div className="product-gallery">
                  <div className="product-gallery-preview order-sm-2">
                    <div
                      className="product-gallery-preview-item active"
                      id="first"
                    >
                      <Zoom transitionDuration={500}>
                        <img
                          className="image-zoom"
                          src={helpers.getImageUrl(
                            selectedImage.attachment && selectedImage.attachment
                          )}
                          alt={product.name && product.name}
                        />
                      </Zoom>
                    </div>
                  </div>
                  <div className="product-gallery-thumblist order-sm-1">
                    {product.attachments &&
                      product.attachments.map((attachment, index) => (
                        <span
                          className="product-gallery-thumblist-item active"
                          key={index}
                          onClick={() => {
                            setSelectedImage(attachment);
                          }}
                        >
                          <img
                            src={helpers.getImageUrl(attachment.attachment)}
                            alt={index}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              padding: "1px",
                            }}
                          />
                        </span>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 pt-4 pt-lg-0 image-zoom-pane">
                <div className="product-details ms-auto pb-3">
                  <div className="mb-3">
                    <span className="h3 fw-normal text-accent me-1">
                      {window.prefix !== "null" && window.prefix}
                      {parseFloat(
                        parseFloat(
                          product.price * parseFloat(window.rate ?? 1)
                        ).toFixed(2)
                      ).toLocaleString()}{" "}
                      {window.suffix !== "null" && window.suffix}
                    </span>
                    {product && product.discount > 0 && (
                      <del className="text-muted fs-lg me-3">
                        $
                        {parseFloat(product.discount) +
                          parseFloat(product.price)}
                      </del>
                    )}
                    <span className="badge bg-danger badge-shadow align-middle mt-n2">
                      Sale
                    </span>
                  </div>
                  <div className="fs-sm mb-1">
                    <span className="text-heading fw-medium me-1">
                      {window.t("code")}:
                    </span>
                    <span className="text-muted" id="colorOptionText">
                      {product && product.code}
                    </span>
                  </div>
                  <div className="fs-sm mb-1">
                    <span className="text-heading fw-medium me-1">
                      {window.t("barcode")}:
                    </span>
                    <span className="text-muted" id="colorOptionText">
                      {product && product.barcode}
                    </span>
                  </div>
                  <div className="fs-sm mb-1">
                    <span className="text-heading fw-medium me-1">
                      {window.t("minOrder")}:
                    </span>
                    <span className="text-muted" id="colorOptionText">
                      {product && product.minOrder}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="qty" className="form-label">
                        {window.t("qty")}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="qty"
                        defaultValue={1}
                        onChange={(e) => setQty(e.target.value)}
                      />
                    </div>
                  </div>
                  {product.availableDiskSizes &&
                    product.availableDiskSizes.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <p className="form-label">
                            {window.t("availableSizes")}
                          </p>
                          <div className="form-check form-option form-check-inline mb-2">
                            <input
                              type="radio"
                              checked
                              className="form-check-input"
                              id={`diskSize_0`}
                              name="diskSize"
                              onChange={(e) => {
                                setSelectedDiskSize(new { id: 1 }());
                              }}
                            />
                            <label
                              htmlFor={`diskSize_1`}
                              className="form-option-label"
                            >
                              {window.t("all")}
                            </label>
                          </div>
                          {product.availableDiskSizes &&
                            product.availableDiskSizes.length > 0 &&
                            product.availableDiskSizes
                              .sort(
                                (a, b) =>
                                  parseFloat(a.name) - parseFloat(b.name)
                              )
                              .map((diskSize, index) => (
                                <div
                                  className="form-check form-option form-check-inline mb-2"
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    disabled
                                    className="form-check-input"
                                    id={`diskSize_${diskSize.id}`}
                                    name="diskSize"
                                    onChange={(e) => {
                                      setSelectedDiskSize(diskSize);
                                    }}
                                  />
                                  <label
                                    htmlFor={`diskSize_${diskSize.id}`}
                                    className="form-option-label"
                                  >
                                    {diskSize.name}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </div>
                    )}

                  {product.availableRamSizes &&
                    product.availableRamSizes.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <p className="form-label">
                            {window.t("bottomSizes")}
                          </p>
                          {product.availableRamSizes &&
                            product.availableRamSizes.length > 0 &&
                            product.availableRamSizes
                              .sort(
                                (a, b) =>
                                  parseFloat(a.name) - parseFloat(b.name)
                              )
                              .map((ramSize, index) => (
                                <div
                                  className="form-check form-option form-check-inline mb-2"
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`ramSize_${ramSize.id}`}
                                    name="ramSize"
                                    onChange={(e) => {
                                      setSelectedRamSize(ramSize);
                                    }}
                                  />
                                  <label
                                    htmlFor={`ramSize_${ramSize.id}`}
                                    className="form-option-label"
                                  >
                                    {ramSize.name}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </div>
                    )}

                  {product.availableScreenSizes &&
                    product.availableScreenSizes.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <p className="form-label">
                            {window.t("bottomSize2")}
                          </p>
                          {product.availableScreenSizes &&
                            product.availableScreenSizes.length > 0 &&
                            product.availableScreenSizes
                              .sort(
                                (a, b) =>
                                  parseFloat(a.name) - parseFloat(b.name)
                              )
                              .map((screenSize, index) => (
                                <div
                                  className="form-check form-option form-check-inline mb-2"
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`screenSize_${screenSize.id}`}
                                    name="screenSize"
                                    onChange={(e) => {
                                      setSelectedScreenSize(screenSize);
                                    }}
                                  />
                                  <label
                                    htmlFor={`screenSize_${screenSize.id}`}
                                    className="form-option-label"
                                  >
                                    {screenSize.name}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </div>
                    )}

                  {product.availableColors &&
                    product.availableColors.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <p className="form-label">
                            {window.t("availableColors")}
                          </p>
                          {product.availableColors &&
                            product.availableColors.length > 0 &&
                            product.availableColors.map((color, index) => (
                              <div
                                className="form-check form-option form-check-inline mb-2"
                                key={index}
                              >
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id={`color_${color.id}`}
                                  name="color"
                                  onChange={(e) => {
                                    setSelectedColor(color);
                                  }}
                                />
                                <label
                                  htmlFor={`color_${color.id}`}
                                  className="form-option-label"
                                >
                                  {color.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  <button
                    className="btn btn-primary btn-shadow d-block w-100 mb-3"
                    type="button"
                    onClick={() => {
                      addToBasket(product);
                    }}
                  >
                    <i className="ci-cart fs-lg me-2"></i>
                    {window.t("addToCart")}
                  </button>
                  {product.description && (
                    <div
                      style={{ lineBreak: "" }}
                      className={`product-details`}
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default QuickViewModal;
