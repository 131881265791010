import React from "react";
import { CloseButton, UpdateButton } from "./ActionButton";

export default function CustomerModal(props) {
  const { actionData } = props;

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const [data, setData] = React.useState();
  console.log(data);
  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setData(() => ({
      ...data,
      [name]: value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="CustomerInvoiceModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              Edit Customer Invoice
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <div className="w-100">
                <label htmlFor="shipping" className="col-form-label">
                  Shipping:
                </label>
                <input
                  type="number"
                  name="shipping"
                  value={data?.shipping}
                  onChange={formDataHandler}
                  className="form-control"
                  id="shipping"
                />
              </div>
              <div className="w-100">
                <label htmlFor="tax" className="col-form-label">
                  Tax:
                </label>
                <input
                  type="number"
                  name="tax"
                  value={data?.tax}
                  onChange={formDataHandler}
                  className="form-control"
                  id="tax"
                />
              </div>
              <div className="w-100">
                <label htmlFor="other" className="col-form-label">
                  Other:
                </label>
                <input
                  required
                  name="other"
                  type="number"
                  value={data?.other}
                  onChange={formDataHandler}
                  className="form-control"
                  id="other"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <CloseButton />
            <UpdateButton
              endPoint={`/Invoices/${
                data?.id
              }?invoiceType=SellInvoice&changeTaxPrice=True&newTaxPrice=${parseFloat(
                data?.tax
              )}&changeShippingPrice=True&newShippingPrice=${parseFloat(
                data?.shipping
              )}&changeOtherPrice=True&newOtherPrice=${parseFloat(
                data?.other
              )}`}
              data={data}
              setData={setData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
