export const en = {
  home: "Home",
  darkLightMode: "Dark / Light Mode",
  //Start Header
  available: "Available",
  at: "at",
  //End Header

  //Start Navbar
  searchForProduct: "Search for products",
  hello: "Hello",
  myAccount: "My Account",
  departments: "Departments",

  /* Start SignIn */
  signIn: "Sign in",
  username: "Username",
  password: "Password",
  rememberMe: "Remember me",
  forgetPassword: "Forgot password?",
  /* End SignIn */

  /* Start SignUp */
  signUp: "Sign up",
  fullName: "Full name",
  emailAddress: "Email address",
  confirmPassword: "Confirm password",
  userType: "User Type",
  generalAdmin: "General Admin",
  superAdmin: "Super Admin",
  admin: "Admin",
  default: "Default",
  /* End SignUp */

  /* Start My Cart */
  myCart: "My Cart",
  subtotal: "Subtotal",
  expandCart: "Expand Cart",
  checkout: "Checkout",
  /* End My Cart */

  //End Navbar

  //Start Navs
  home: "Home",
  smartphones: "Smartphones",
  tablets: "Tablets",
  gaming: "Gaming",
  laptopAndComputer: "Laptop & Computer",
  wearables: "Wearables",
  headPhones: "Head Phones",
  accessories: "Accessories",
  tools: "Tools",
  //End Navs

  //Start Shop Cart Page
  yourCart: "Your cart",
  products: "Products",
  contShopping: "Continue shopping",
  quantity: "Quantity",
  additionalComments: "Additional comments",
  applyPromoCode: "Apply promo code",
  promoCode: "Promo code",
  proceedToCheckout: "Proceed to Checkout",
  remove: "remove",
  //End Shop Cart Page

  //Start Main Page
  slidHead1: "World of music with",
  slidBody1: "Headphones",
  slidFooter1: "Choose between top brands",
  slidHead2: "Explore the best",
  slidBody2: "VR Collection",
  slidFooter2: "on the market ",
  slidHead3: "Check our huge",
  slidBody3: "Smartphones",
  slidFooter3: "& Accessories collection ",
  //End Main Page

  //Start Trending Products (Product Component)
  trendingProducts: "Trending products",
  moreProducts: "More Products",
  addToCart: "Add to Cart",
  quickView: "Quick view",
  //End Trending Products (Product Component)

  //Start Product Modal
  reviews: "Reviews",
  color: "Color: ",
  size: "Size: ",
  addToWishlist: "Add to Wishlist",
  compare: "Compare",
  productInfo: "Product info",
  general: "General",
  generalInfo: "General Info",
  model: "Model: ",
  gender: "Gender: ",
  osCompatibilty: "OS campitibility: ",
  physicalSpece: "Physical specs",
  shape: "Shape: ",
  bodyMterial: "Body material: ",
  bandMterial: "Band material: ",
  ram: "RAM : ",
  rom: "ROM : ",
  screen: "Screen : ",
  installments: "Installments : ",
  overview: "Overview ",
  description: "Description",
  noRAM: "No RAM Size Available",
  noROM: "No ROM Size Available",
  noIM: "No Installment Method Available",
  noSc: "No Different Screen Size Available",
  noCo: "No Color Available",
  availableSizes: "Available Sizes",
  availableColors: "Available Colors",
  bottomSizes: "Bottom Sizes",
  bottomSizes2: "Bottom Sizes2",
  //End Product Modal

  //Start Prormo Banner
  limitedOffer: "Limited Offer",
  offerHead: "All new",
  offerBody: "Last Gen iPad Pro",
  offerfooter: "at discounted price. Hurry up!",
  viewOffers: "View offers",
  //End Promo Banner

  //Start Product Widget
  bestsellers: "Bestsellers",
  newSrrivals: "New arrivals",
  pufu: "Pick Up for you",
  //End Product Widget

  //Start SocialMedia Feeds
  smfl: "View latest gadgets reviews available for purchase in our store.",
  smfr: "Latest videos from Techeye channel",
  moreVideos: "More videos",
  //End SocialMedia Feeds

  //Start SocialMedia Info
  readBlog: "Read the blog",
  readBlogDescr: "Latest store, fashion news and trends",
  followSocialMedia: "Follow on Instagram",
  followSocialMedia2: "Like on Facebook",
  //End SocialMedia Info

  //Start Product Carousel
  ymal: "You may also like",
  //End Product Carousel

  //Start Cheaper Together
  cheaperTogether: "Cheaper together",
  purchaseTogether: "Purchase together",
  //End Cheaper Together

  //Start TFHHD
  menu: "Menu",
  wishlist: "Wishlist",
  filters: "Filters",
  //End TFHHD

  //Start Toolbar
  sortBy: "Sort by:",
  popularity: "Popularity",
  LtHp: "Low - Hight Price",
  HtLp: "High - Low Price",
  AtZ: "A - Z Name",
  ZtA: "Z - A Name",
  AtZCode: "A - Z Code",
  ZtACode: "Z - A Code",
  shippingPrice: "Shipping Price",
  //End Toolbar

  //Start Footer
  shopDept: "Shop departments",
  accAndShippingInfo: "Account & shipping info",
  stayInformed: "Stay informed",
  aboutUs: "About us",
  downloadApp: "Download our app",
  footerNav1Head: "Fast and free delivery",
  footerNav1Descr: "Free delivery for all orders over $200",
  footerNav2Head: "Money back guarantee",
  footerNav2Descr: "We return money within 30 days",
  footerNav3Head: "24/7 customer support",
  footerNav3Descr: "Friendly 24/7 customer support",
  footerNav4Head: "Secure online payment",
  footerNav4Descr: "We possess SSL / Secure сertificate",
  cr: "© All rights reserved. Made by TechEye",
  aboutCompany: "About Company",
  ourTeam: "Our Team",
  careers: "Careers",
  news: "News",
  downloadOn: "Download on the",
  //End Footer

  // Start Shop Filter
  price: "Price",
  min: "min",
  max: "max",
  categories: "Categories",
  brands: "Brands",
  colors: "Colors",
  raf: "Reset All Filters",
  rcf: "Reset Color Filters",
  //stock
  stock: "Stock",
  available: "Available",
  iraqStock: "Iraq Stock",
  chinaStock: "China Stock",
  iranStock: "Iran Stock",
  turkeyStock: "Turkey Stock",
  uaeStock: "UAE Stock",
  europeStock: "Europe Stock",
  //seasons
  seasons: "Seasons",
  spring: "Spring",
  summer: "Summer",
  autumn: "Autumn",
  winter: "Winter",
  all: "All",
  // End Shop Filter

  // Start Generals
  techeye: "Techeye",
  techeyestore: "Techeye Store",
  shopNow: "Shop Now ",
  shop: "Shop Here",
  new: "New",
  note: "Note",
  sale: "Sale",
  viewMore: "View more",

  kurdish: "کوردی",
  arabic: "عربی",
  english: "English",
  language: "Language",
  usd: "USD",
  // End Generals

  contactUs: "Contact Us",
  discount: "Discount",
  aboutUsTitle1: "1- Launched in the early 2003’s",
  aboutUsTitle2: "2- Research and Development Staff",
  aboutUsTitle3: "3- Our Mission",
  aboutUsTitle4: "4- Trademarks",
  aboutUsTitle5: "5- Deal, Purchase and Shipment",
  aboutUsTitle6: "6- Customs' Procedure",
  aboutUsTitle7: "7- Widespread Network of Relations",
  aboutUsTitle8: "8- International Market",
  aboutUsTitle9: "9- Our Vision",
  aboutUsTitle10: "On a final note,",
  //desc
  aboutUsDesc1:
    "It is a large commercial enterprise focusing on its business on Research and Development. China Stars Co is the first in Iraq to operate supply chain service through a large application and advanced information technology covering thousands of products. It is one of the fast-growing foundations in the country since its establishment.",
  aboutUsDesc2:
    "We have 19 years of Research and Development experience in the Iraq market and 10 years of Research and Development experience in the Chinese market. We have a group of high-qualified managers, from businessmen to engineers, mechanics, doctors, and marketing experts. It has established extensive research partnerships in its home country and abroad. It is made up of 14 managers with specialized abilities in the global market, especially the Chinese market.",
  aboutUsDesc3:
    "Our mission is to make it easy to do business in Iraq through the technology infrastructure and marketing strategies. We help merchants by giving them the tools necessary to reach Chinese suppliers and their products quickly and efficiently. We build the bridge between our clients and Chinese suppliers that are providing good quality products in China and are suitable for their trading.We also help Chinese companies to do marketing for their products and trade of brands in our country market, to leverage the power of new technology to engage with their users and customers through our domain and operate more efficiently. By this connection we also support the growth of their businesses in our country.China stars.com brings you millions of products in over 4000 different categories, including consumer electronics, machinery, and apparel. We developed a web based platform, that help businessmen do their purchases and track them in an efficient way, and discover new opportunities, anywhere, anytime. Turn to China Stars-iq.com for all your business needs...",
  aboutUsDesc4: `China Stars is a large enterprise with high brand visibility, creativity, and great influence. Currently, it is a known trademark in Iraq.
  China stars use professional design for their brands and their clients which is supported by efficient value-added management technology that is based on the electronic commerce platform. The platform provides integration of internal and external resources, and integration of the trade, logistics, information, and capital altogether. The innovation is continuously tied to our supply chain operation services in our business model.`,
  aboutUsDesc5:
    "We are experts at Deals and Purchases merchandise in China. We also perform the logistics process from door to door. We collect the goods all over China and ship them by a cost effective, time-saving and safe logistic procedures.    ",
  aboutUsDesc6:
    "We choose the best ports for receiving (Umm-Qasr port in Iraq, Port of Abbas in Iran or port of Mersin In Turkey). We choose ports and routes of logistic according to the type of goods and logistic standards of the delivery of each type of product.  Our logistics procedure is done according to international standards and the requirements of the Iraqi government.",
  aboutUsDesc7:
    "We successfully establish strategic partnership relationships with many famous Chinese brands for all trading categories. Just contact us and you will find out how professional we are in the business. Our professional team services will guarantee you success in your business. Our aim is to establish a perfect service network by building up long-term and friendly relationships with customers from all over Iraq.",
  aboutUsDesc8:
    "Instantly it is based on the domestic market, and in parallel of that we actively develop the international market relation.",
  aboutUsDesc9: `We always adhere to the motto of "Honesty and customers trust comes first, High quality and high efficiency in business performance, Innovation and Enterprising in our products and services and Win-Win Cooperation with our clients". We look forward to growing up with you together to our mutual benefit. Our company is steadily marching forward in the market competition with our advanced services and safe and reliable products, and a continual improvement in our software system for the best and efficient services`,
  aboutUsDesc10: `China Stars is sticking the company policy of "try our best to meet customers' requirements". We promise to ensure the good quality of each products. We sincerely hope that we can develop businesses based on mutual benefit. China Stars is the No.1 in providing end to end supply chain Iraq. We integrate the value of creativity into all of our procedures and service. To guarantee a bright future to your business you can meet us. We extend a warm welcome to our beloved customers.`,
  freeShipping: "Free Shipping",
  pleaseWriteQuantity: "Please write quantity",
  cantOrderLessThanMinQuantity: "Can't order less than min quantity",
  factorOrderingInfo:
    "You can ask for each set according to factors of min order for example you can order ",
  addedToCart: "Added To Cart",
  shippingOptions: "Shipping Options",
  courier: "Courier",
  share: "Share",
  relatedProducts: "Related Products",
  code: "Code",
  barcode: "Barcode",
  minOrder: "Min Order",
  qty: "Quantity",
  resetFilters: "Reset Filters",
  applyFilters: "Apply Filters",
  sections: "Sections",
  families: "Families",
  minPrice: "Min Price",
  maxPrice: "Max Price",
  search: "Search",
  searchCode: "Search Code",
  of: "of",
  logout: "Logout",
  myProfile: "My Profile",
  languageChanged: "Language Changed",
  telegramGroups: "Telegram Groups",
};
