import React, { useState, useEffect, useCallback } from "react";
import axiosPrivate from "../Axios";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

function LeftSidebar({
  setProducts,
  setIsLoading,
  setTotalCount,
  currentPage,
  sortBy,
  searchCode,
  searchSeason,
  searchStock,
  passFamiliesData,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {}, [searchParams]);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [departments, setDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [sections, setSections] = useState([]);
  const [filteredSections, setFilteredSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);

  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);

  const [families, setFamilies] = useState([]);
  const [filteredFamilies, setFilteredFamilies] = useState([]);
  const [selectedFamilies, setSelectedFamilies] = useState([]);
  const [catID, setCatID] = useState(null);

  useEffect(() => {
    // console.log("zzzzzz = ", searchParams.get('category'));
    setCatID(searchParams.get("category"));
  }, [searchParams]);

  const getProducts = useCallback(async () => {
    let start = (currentPage - 1) * 20;
    let end = currentPage * 20;

    let selectedCat = "";

    if (selectedSection === 0) {
      selectedCat = `&productTypeId=${selectedDepartment}`;
    } else if (selectedSection !== 0 && selectedCategory === 0) {
      selectedCat = `&productTypeId=${selectedSection}`;
    } else if (
      selectedSection !== 0 &&
      selectedCategory !== 0 &&
      selectedFamilies.length === 0
    ) {
      selectedCat = `&productTypeId=${selectedCategory}`;
    } else {
      selectedFamilies.forEach((family) => {
        selectedCat += `&productTypeId=${family}`;
      });
      selectedCat += "&isLastLevelType=true";
    }

    if (
      searchParams.get("category") !== null &&
      searchParams.get("category") !== undefined &&
      searchParams.get("category") !== ""
    ) {
      selectedCat = `&productTypeId=${searchParams.get("category")}`;
      if (
        searchParams.get("level") !== null &&
        searchParams.get("level") !== undefined &&
        searchParams.get("level") !== ""
      ) {
        selectedCat += `&isLastLevelType=${searchParams.get("level")}`;
      }
    }

    let minP = "";
    if (minPrice !== 0 && minPrice !== null) {
      minP = `&minPrice=${minPrice}`;
    }

    let maxP = "";
    if (maxPrice !== 0 && maxPrice !== null) {
      maxP = `&maxPrice=${maxPrice}`;
    }

    let searchProduct = "";
    if (
      searchParams.get("q") !== null &&
      searchParams.get("q") !== undefined &&
      searchParams.get("q") !== ""
    ) {
      searchProduct = `&search=${searchParams.get("q")}`;
    }

    let sorting = ``;
    if (sortBy === "highToLow") {
      sorting = "&sort=price&order=asc";
    } else if (sortBy === "lowToHigh") {
      sorting = "&sort=price&order=desc";
    } else if (sortBy === "aToZ") {
      sorting = "&sort=name&order=asc";
    } else if (sortBy === "zToA") {
      sorting = "&sort=name&order=desc";
    } else if (sortBy === "aToZcode") {
      sorting = "&sort=code&order=asc";
    } else if (sortBy === "zToAcode") {
      sorting = "&sort=code&order=desc";
    } else if (sortBy === "freeShipping") {
      sorting = "&sort=shippingPrice&order=desc";
    } else {
      sorting = "&sort=id&order=desc";
    }

    let searchProducts = "";

    if (searchSeason !== 0 && searchSeason !== null) {
      searchProducts = `&searchField=season&search=${searchSeason}`;
    }
    if (searchStock !== 0 && searchStock !== null && searchStock !== "Stock") {
      searchProducts = `&searchField=stock&search=${searchStock}`;
    }

    if (searchCode !== 0 && searchCode !== null) {
      searchProducts = `&searchField=code&search=${searchCode}`;
    }
    if (selectedCat.includes("productTypeId=null")) {
      return;
    }
    const response = await axiosPrivate.get(
      `/ClientSide/GetProducts?start=${start}&end=${end}${sorting}${minP}${maxP}${selectedCat}${searchProduct}${searchProducts}`
    );
    setProducts(response.data);
    setTotalCount(parseInt(response.headers["x-total-count"]));
    setIsLoading(false);
  }, [
    selectedDepartment,
    selectedSection,
    selectedCategory,
    selectedFamilies,
    currentPage,
    minPrice,
    maxPrice,
    searchParams,
    sortBy,
    setProducts,
    setTotalCount,
    searchCode,
    searchSeason,
    searchStock,
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getProducts().catch();
  }, [getProducts]);

  const applyFilters = async () => {
    setIsLoading(true);
    await getProducts().catch();
  };

  const getDepartments = useCallback(async () => {
    const response = await axiosPrivate.get(
      "/ClientSide/Get?objectName=5&start=0&end=1000000&sort=sort&order=asc"
    );
    setDepartments(response.data);
    setFilteredDepartments(response.data);
    setSelectedDepartment(response.data.length > 0 ? response.data[0].id : 0);
    resetFilterInputs();
  }, []);
  const filterDepartment = (value) => {
    let search = value ?? "";
    let filtered = departments;
    if (search.length == 0) {
      setFilteredDepartments(departments);
    } else {
      filtered = departments.filter((entry) =>
        Object.values(entry).some(
          (val) =>
            typeof val === "string" &&
            val.toLocaleLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredDepartments(filtered);
    }
  };
  useEffect(() => {
    getDepartments().catch();
  }, [getDepartments]);

  const getSections = useCallback(async () => {
    if (
      selectedDepartment !== null &&
      selectedDepartment !== undefined &&
      selectedDepartment !== "" &&
      selectedDepartment !== 0
    ) {
      const response = await axiosPrivate.get(
        `/ClientSide/Get?objectName=5&start=0&end=1000000&sort=sort&order=asc&subLevelId=${selectedDepartment}`
      );
      response.data.unshift({
        id: 0,
        name: window.t("all"),
      });
      setSections(response.data);
      setFilteredSections(response.data);
      setSelectedSection(response.data.length > 0 ? response.data[0].id : 0);
      resetFilterInputs();
    }
  }, [selectedDepartment]);

  const filterSection = (e) => {
    let search = e.target.value;
    let filtered = sections;
    if (search.length > 0) {
      filtered = sections.filter((entry) =>
        Object.values(entry).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
    setFilteredSections(filtered);
  };
  useEffect(() => {
    getSections().catch();
  }, [getSections]);

  const getCategories = useCallback(async () => {
    if (
      selectedSection !== null &&
      selectedSection !== undefined &&
      selectedSection !== "" &&
      selectedSection !== 0
    ) {
      const response = await axiosPrivate.get(
        `/ClientSide/Get?objectName=5&start=0&end=1000000&sort=sort&order=asc&subLevelId=${selectedSection}`
      );
      response.data.unshift({
        id: 0,
        name: window.t("all"),
      });
      setCategories(response.data);
      setFilteredCategories(response.data);
      setSelectedCategory(response.data.length > 0 ? response.data[0].id : 0);
      resetFilterInputs();
    }
  }, [selectedSection]);
  const filterCategory = (e) => {
    let search = e.target.value;
    let filtered = categories;
    if (search.length > 0) {
      filtered = categories.filter((entry) =>
        Object.values(entry).some(
          (val) => typeof val === "string" && val.includes(e.target.value)
        )
      );
    }
    setFilteredCategories(filtered);
  };
  useEffect(() => {
    getCategories().catch();
  }, [getCategories]);

  const getFamilies = useCallback(async () => {
    if (
      selectedCategory !== null &&
      selectedCategory !== undefined &&
      selectedCategory !== "" &&
      selectedCategory !== 0
    ) {
      const response = await axiosPrivate.get(
        `/ClientSide/Get?objectName=5&start=0&end=1000000&sort=sort&order=asc&subLevelId=${selectedCategory}`
      );
      setFamilies(response.data);
      setFilteredFamilies(response.data);
      passFamiliesData(response.data);
      // setSelectedFamilies(response.data.length > 0 ? [response.data[0].id] : [0]);
      resetFilterInputs();
      resetSelectedFamiliesOnChangeCategory();
    }
  }, [selectedCategory]);

  const filterFamily = (e) => {
    let search = e.target.value;
    let filtered = families;
    if (search.length > 0) {
      filtered = families.filter((entry) =>
        Object.values(entry).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
    setFilteredFamilies(filtered);
  };
  useEffect(() => {
    getFamilies().catch();
  }, [getFamilies]);

  const resetFilterInputs = () => {
    let inputElements = document.getElementsByClassName("widget-filter-search");
    for (let i = 0; i < inputElements.length; i++) {
      inputElements[i].value = "";
    }
  };
  const resetSelectedFamiliesOnChangeCategory = () => {
    let inputElements = document.getElementsByClassName("familiy-input");
    for (let i = 0; i < inputElements.length; i++) {
      if (inputElements[i].checked == true) {
        inputElements[i].click();
      }
    }
  };

  const resetAllCheckBoxsAndRadioButtons = () => {
    setSelectedDepartment(filteredDepartments ? 1 : 0);
    setSelectedSection(0);
    setCategories([]);
    setSelectedCategory(0);
    setFilteredCategories([]);
    setFamilies([]);
    setFilteredFamilies([]);
  };

  const resetFilters = () => {
    passFamiliesData("");
    setSearchParams({});
    resetAllCheckBoxsAndRadioButtons();
    resetFilterInputs();
    setMinPrice(null);
    setMaxPrice(null);
    filterDepartment(document.getElementById("departments-search").value);
    let minAndMaxInputValueReset = document.querySelectorAll(".min-max");
    for (let i = 0; i < minAndMaxInputValueReset.length; i++) {
      minAndMaxInputValueReset[i].value = null;
    }
  };

  return (
    <div
      className="shop-page-sidebar offcanvas offcanvas-collapse bg-white w-100 rounded-3 shadow-lg py-1"
      id="shop-sidebar"
      style={{ maxWidth: "22rem" }}
    >
      <div className="offcanvas-header align-items-center shadow-sm">
        <h2 className="h5 mb-0">Filters</h2>
        <button
          className="btn-close ms-auto"
          type="button"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body py-grid-gutter px-lg-grid-gutter">
        <div className="widget mb-4 pb-4 border-bottom">
          <button
            className={`btn btn-outline-primary btn-shadow d-block w-100 mt-4 mb-3`}
            onClick={applyFilters}
          >
            {window.t("applyFilters")}
          </button>
          <button
            className={`btn btn-primary d-block w-100 mt-4 mb-3`}
            onClick={() => {
              resetFilters();
            }}
          >
            {window.t("resetFilters")}
          </button>
        </div>

        {/* Departments Filter */}
        <div className="widget widget-filter mb-4 pb-4 border-bottom">
          <h3 className="widget-title">{window.t("departments")}</h3>
          <div className="input-group input-group-sm mb-2">
            <input
              className="widget-filter-search searchInputRtl form-control rounded-end pe-5"
              type="text"
              placeholder={window.t("search")}
              id="departments-search"
              onChange={(e) => filterDepartment(e.target.value)}
            />
            <i className="ci-search my-ci-search position-absolute top-50 end-0 translate-middle-y fs-sm me-3"></i>
          </div>
          <ul
            className="widget-list widget-filter-list list-unstyled pt-1 scrollable"
            style={{ maxHeight: "11rem" }}
            data-simplebar
            data-simplebar-auto-hide="true"
          >
            {filteredDepartments &&
              filteredDepartments.map((department, index) => (
                <li
                  className="widget-filter-item d-flex justify-content-between align-items-center mb-1"
                  key={index}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input chechboxRTL"
                      checked={department.id === selectedDepartment}
                      type="radio"
                      name={`departments`}
                      id={"department_" + department.id}
                      onChange={() => {
                        setSelectedDepartment(department.id);
                        navigate(`?productTypeId=${department.id}`);
                      }}
                    />
                    <label
                      className="form-check-label widget-filter-item-text chechboxRtlLable"
                      htmlFor={"department_" + department.id}
                    >
                      {department.name}
                    </label>
                  </div>
                </li>
              ))}
          </ul>
        </div>

        {/* Sections Filter */}
        <div className="widget widget-filter mb-4 pb-4 border-bottom">
          <h3 className="widget-title">{window.t("sections")}</h3>
          <div className="input-group input-group-sm mb-2">
            <input
              className="widget-filter-search searchInputRtl form-control rounded-end pe-5"
              type="text"
              placeholder={window.t("search")}
              onChange={filterSection}
            />
            <i className="ci-search my-ci-search position-absolute top-50 end-0 translate-middle-y fs-sm me-3"></i>
          </div>
          <ul
            className="widget-list widget-filter-list list-unstyled pt-1 scrollable"
            style={{ maxHeight: "11rem" }}
            data-simplebar
            data-simplebar-auto-hide="true"
          >
            {filteredSections &&
              filteredSections.map((section, index) => (
                <li
                  className="widget-filter-item d-flex justify-content-between align-items-center mb-1"
                  key={index}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input chechboxRTL "
                      checked={section.id === selectedSection}
                      type="radio"
                      name={`sections`}
                      id={"section_" + section.id}
                      onChange={() => {
                        setSelectedSection(section.id);
                      }}
                    />
                    <label
                      className="form-check-label widget-filter-item-text chechboxRtlLable"
                      htmlFor={"section_" + section.id}
                    >
                      {section.name}
                    </label>
                  </div>
                </li>
              ))}
          </ul>
        </div>

        {/* Categories Filter */}
        <div className="widget widget-filter mb-4 pb-4 border-bottom">
          <h3 className="widget-title">{window.t("categories")}</h3>
          <div className="input-group input-group-sm mb-2">
            <input
              className="widget-filter-search searchInputRtl form-control rounded-end pe-5"
              type="text"
              placeholder={window.t("search")}
              onChange={filterCategory}
            />
            <i className="ci-search my-ci-search position-absolute top-50 end-0 translate-middle-y fs-sm me-3"></i>
          </div>
          <ul
            className="widget-list widget-filter-list list-unstyled pt-1 scrollable"
            style={{ maxHeight: "11rem" }}
            data-simplebar
            data-simplebar-auto-hide="true"
          >
            {filteredCategories &&
              filteredCategories.map((category, index) => (
                <li
                  className="widget-filter-item d-flex justify-content-between align-items-center mb-1"
                  key={index}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input chechboxRTL "
                      checked={category.id === selectedCategory}
                      type="radio"
                      name={`categories`}
                      id={`category_` + category.id}
                      onChange={() => {
                        setSelectedCategory(category.id);
                      }}
                    />
                    <label
                      className="form-check-label widget-filter-item-text chechboxRtlLable"
                      htmlFor={`category_` + category.id}
                    >
                      {category.name}
                    </label>
                  </div>
                </li>
              ))}
          </ul>
        </div>

        {/* Families Filter */}
        <div className="widget widget-filter mb-4 pb-4 border-bottom">
          <h3 className="widget-title">{window.t("families")}</h3>
          <div className="input-group input-group-sm mb-2">
            <input
              className="widget-filter-search searchInputRtl form-control rounded-end pe-5"
              type="text"
              placeholder={window.t("search")}
              onChange={filterFamily}
            />
            <i className="ci-search my-ci-search position-absolute top-50 end-0 translate-middle-y fs-sm me-3"></i>
          </div>
          <ul
            className="widget-list widget-filter-list list-unstyled pt-1 scrollable"
            style={{ maxHeight: "11rem" }}
            data-simplebar
            data-simplebar-auto-hide="true"
          >
            {filteredFamilies &&
              filteredFamilies.map((family, index) => (
                <li
                  className="widget-filter-item d-flex justify-content-between align-items-center mb-1"
                  key={index}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input familiy-input"
                      type="checkbox"
                      name={`families`}
                      id={`family_` + family.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedFamilies([...selectedFamilies, family.id]);
                        } else {
                          setSelectedFamilies(
                            selectedFamilies.filter((x) => x !== family.id)
                          );
                        }
                      }}
                    />
                    <label
                      className="form-check-label widget-filter-item-text"
                      htmlFor={`family_` + family.id}
                    >
                      {family.name}
                    </label>
                  </div>
                </li>
              ))}
          </ul>
        </div>

        {/* Price Filter */}
        <div className="widget mb-4 pb-4 border-bottom">
          <h3 className="widget-title">{window.t("price")}</h3>
          <div
            className="range-slider"
            data-start-min="250"
            data-start-max="680"
            data-min="0"
            data-max="1000"
            data-step="1"
          >
            <div className="d-flex pb-1">
              <div className="w-50 pe-2 me-2">
                <div
                  className="input-group input-group-sm"
                  style={{ direction: "ltr" }}
                >
                  <span className="input-group-text">$</span>
                  <input
                    className="form-control min-max range-slider-value-min carouselSet"
                    type="number"
                    placeholder={window.t("minPrice")}
                    onChange={(e) => {
                      setMinPrice(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="w-50 pe-2 me-2">
                <div
                  className="input-group input-group-sm"
                  style={{ direction: "ltr" }}
                >
                  <span className="input-group-text">$</span>
                  <input
                    className="form-control min-max range-slider-value-max carouselSet"
                    type="number"
                    placeholder={window.t("maxPrice")}
                    onChange={(e) => {
                      setMaxPrice(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="widget mb-4 pb-4 border-bottom">
          <button
            className={`btn btn-outline-primary btn-shadow d-block w-100 mt-4 mb-3`}
            onClick={applyFilters}
          >
            {window.t("applyFilters")}
          </button>
          <button
            className={`btn btn-primary d-block w-100 mt-4 mb-3`}
            onClick={() => {
              resetFilters();
            }}
          >
            {window.t("resetFilters")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LeftSidebar;
