import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HTTP } from "../axios";
import ToastMessage from "../Component/ToastMessage";

export default function Login() {
  const navigate = useNavigate();
  const [serachParams, setSearchPrams] = useSearchParams();
  const [data, setData] = React.useState({
    userName: "",
    password: "",
  });

  const login = async () => {
    const res = await HTTP.post(`/Accounts/login`, JSON.stringify(data), {
      headers: { "content-type": "application/json; charset=utf-8" },
    });
    const responseData = await res.data;

    localStorage.setItem("token", responseData.token);
    localStorage.setItem("expiration", responseData.expiration);
    localStorage.setItem("firstName", responseData.firstName);
    localStorage.setItem("lastName", responseData.lastName);
    localStorage.setItem("middleName", responseData.middleName);
    localStorage.setItem(
      "fullName",
      `${responseData.firstName} ${responseData.middleName} ${responseData.lastName}`
    );
    localStorage.setItem("branchId", responseData.branchId);
    localStorage.setItem("image", responseData.image);
    localStorage.setItem("userId", responseData.userId);
    localStorage.setItem("userType", responseData.userType);

    HTTP.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${responseData.token}`;

    localStorage.setItem("isLoggedIn", "true");

    if (res.statusText === "OK") {
      navigate(serachParams.get("prevRouter") || "/ordering");
    }
  };

  return (
    <section className="d-flex flex-column gap-4 justify-content-center align-items-center h-100 ">
      <div className=" w-50 max-w-500 shadow">
        <div className="card">
          <div className="card-body d-flex flex-column gap-4">
            <div>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="userName"
                onChange={(e) => setData({ ...data, userName: e.target.value })}
              />
            </div>
            <div>
              <input
                type="password"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Password"
                onChange={(e) => setData({ ...data, password: e.target.value })}
              />
            </div>{" "}
            <button
              onClick={login}
              className="card-title btn btn-secondary btn-block w-100"
            >
              Login
            </button>
          </div>
        </div>
      </div>
      <p className="fs-5">
        © 2024 .{" "}
        <a href="https://tech-eye.com/" target="_blank">
          TecteyeCo.
        </a>{" "}
        All rights reserved.
      </p>
    </section>
  );
}
