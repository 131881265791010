import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination, Scrollbar, A11y } from "swiper";
import helpers from "../Constants/helpers";
import { Link } from "react-router-dom";

function Categories(props) {
  const { categories, getSubCat } = props;

  return (
    categories.length > 0 && (
      <div className={`container mt-3`}>
        <h4>{window.t("categories")}</h4>
        <Swiper
          slidesPerView={2}
          modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
          pagination={false}
          spaceBetween={20}
          navigation={true}
          autoplay={{
            delay: 2600,
            disableOnInteraction: false,
          }}
          loop={true}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
        >
          {categories &&
            categories.map((cat) => (
              <SwiperSlide key={cat.id}>
                <div className="card product-card-alt">
                  <div
                    className="product-thumb"
                    onClick={() => {
                      getSubCat(cat.id);
                    }}
                  >
                    <div className="product-card-actions">
                      <a className="btn btn-action btn-icon btn-shadow fs-base mx-2">
                        <i className="ci-eye"></i>
                      </a>
                    </div>
                    <a className="product-thumb-overlay"> </a>
                    <img
                      src={helpers.getImageUrl(cat.attachment)}
                      alt={cat.name}
                      style={{ height: 180 }}
                    />
                  </div>
                  <div className="card-body">
                    <h3 className="product-title fs-sm mb-2 text-center">
                      <Link to={`/shop?category=${cat.id}&level=false`}>
                        {cat.name}
                      </Link>
                    </h3>
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    )
  );
}

export default Categories;
