import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="container py-5 mb-lg-3">
      <div className="row justify-content-center pt-lg-4 text-center">
        <div className="col-lg-5 col-md-7 col-sm-9">
          <img
            className="d-block mx-auto mb-5"
            src="assets/img/pages/404.png"
            width="340"
            alt="404 Error"
          />
          <h1 className="h3">404 error</h1>
          <h3 className="h5 fw-normal mb-4">
            We can't seem to find the page you are looking for.
          </h3>
          <p className="fs-md mb-4">
            <u>Here are some helpful links instead:</u>
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-10">
          <Link className="card h-100 border-0 shadow-sm" to={`../`}>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-center">
                <i className="ci-home text-primary h4 mb-0"></i>
                <div className="ps-3">
                  <h5 className="fs-sm mb-0">Homepage</h5>
                  <span className="text-muted fs-ms">Return to homepage</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
