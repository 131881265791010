import React from "react";
import { getOrders } from "../../api";
import { Await, defer, useLoaderData } from "react-router-dom";
import DataGrid, { image } from "../../Component/DataGrid";
import ChangeStatusModal from "../../Component/ChangeStatusModal";
import ChangeCostQty from "../../Component/ChangeCostQty";
import ActionButton from "../../Component/ActionButton";

export const loader = ({ request }) => {
  const url = new URL(request.url);
  const sort = url.searchParams.get("sort");
  const order = url.searchParams.get("order");
  const search = url.searchParams.get("search");
  const status = url.searchParams.get("status");

  const PromisedData = getOrders(sort, order, search, status);
  return defer({ data: PromisedData });
};

export default function OrderList() {
  const data = useLoaderData();
  const [actionData, setActionData] = React.useState({});

  const Actions = (p) => {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center gap-2">
        <ActionButton
          modal="changeCostQty"
          setActionData={setActionData}
          p={p}
        />
        <ActionButton modal="changeStatus" p={p} isStatus={true} />
      </div>
    );
  };

  const [colDefs, setColDefs] = React.useState([
    {
      headerName: "Product",
      cellRenderer: image,
      flex: 4,
    },

    {
      field: "cost",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "qty",
      headerName: "Quantity",
      valueFormatter: (p) => {
        return `${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "price",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },
    {
      field: "totalPrice",
      valueFormatter: (p) => {
        return `$ ${p.value?.toLocaleString()}`;
      },
    },

    {
      field: "orderStatus",
    },
    {
      field: "actions",
      cellRenderer: Actions,
      flex: 2,
    },
  ]);

  return (
    <section className="p-3">
      <React.Suspense
        fallback={
          <div className="w-100 h-screen d-flex justify-content-center align-items-center">
            <span className="loader-admin">Loading</span>
          </div>
        }
      >
        <Await resolve={data.data}>
          {(data) => {
            return (
              <DataGrid
                orders={true}
                rowHeight={80}
                data={data}
                col={colDefs}
              />
            );
          }}
        </Await>
      </React.Suspense>
      <ChangeStatusModal actionData={actionData} />
      <ChangeCostQty actionData={actionData} hasQty={true} hasCost={true} />
    </section>
  );
}
