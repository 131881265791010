import Navbar from "../Components/Navbar";
import SignInModal from "../Components/SignInModal";
import Footer from "../Components/Footer";
import GoToTop from "../Components/GoToTop";
import BottomNavbar from "../Components/BottomNavbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../Constants/ScrollToTop";
import { useTranslation } from "react-i18next";

export default function MainLayout() {
  return (
    <main>
      <ScrollToTop />
      <div className="app overflow-hidden">
        <SignInModal />{" "}
        <main className="page-wrapper">
          <Navbar />
          <Outlet />
          <Footer />
          <BottomNavbar />
          <GoToTop />{" "}
        </main>
        <ToastContainer />{" "}
      </div>
    </main>
  );
}
