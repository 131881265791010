import React, { useCallback, useEffect, useState } from "react";
import axiosPrivate from "../Axios";
import { Link, useParams } from "react-router-dom";
import SingleProduct from "../Components/SingleProduct";
import helpers from "../Constants/helpers";
import QuickViewModal from "../Components/QuickViewModal";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Swal from "sweetalert2";

function ProductDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [qty, setQty] = useState(1);
  const [selectedDiskSize, setSelectedDiskSize] = useState(0);
  const [selectedRamSize, setSelectedRamSize] = useState(0);
  const [selectedScreenSize, setSelectedScreenSize] = useState(0);
  const [selectedColor, setSelectedColor] = useState(0);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [productId, setProductId] = useState({});
  const [breadcrumb, setBreadcrumb] = useState([]);
  // const [productTypeId, setProductTypeId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const params = useParams();
  const perPage = 12;

  let _start = 0;
  let _end = perPage;
  const setStartAndEnd = useCallback(
    async (number, productTypeId, relatedProducts) => {
      setIsLoading(true);
      _start += number;
      _end += number;
      const relatedProductsData = await axiosPrivate.get(
        `/ClientSide/GetProducts?productTypeId=${productTypeId}&start=${_start}&end=${_end}&isLastLevelType=true`
      );
      setRelatedProducts([...relatedProducts, ...relatedProductsData.data]);
      setIsLoading(false);
    },
    []
  );

  const getProduct = useCallback(async () => {
    const response = await axiosPrivate.get(
      `/ClientSide/GetProductById?id=${params.id}`
    );
    response.data.attachments.unshift({
      attachment: response.data.attachment,
    });
    setProduct(response.data);
    setSelectedImage({ attachment: response.data.attachment });
    const relatedProductsHandler = await axiosPrivate.get(
      `/ClientSide/GetProducts?productTypeId=${response.data.productTypeId}&start=${_start}&end=${_end}&isLastLevelType=true`
    );
    setRelatedProducts(relatedProductsHandler.data);
  }, [params.id]);

  useEffect(() => {
    helpers.closeAllModals();
  }, []);

  const getBreadcrumbs = useCallback(async () => {
    const response = await axiosPrivate.get(
      `/ClientSide/GetProductBreadcrumb/${params.id}`
    );
    setBreadcrumb(response.data.reverse());
  }, [params.id]);

  useEffect(() => {
    getBreadcrumbs().catch();
  }, [params.id]);

  useEffect(() => {
    getProduct();
  }, []);

  const addToBasket = (item) => {
    if (qty <= 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: window.t("pleaseWriteQuantity"),
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }
    if (qty < product.minOrder) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: window.t("cantOrderLessThanMinQuantity"),
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }
    if (qty % product.minOrder !== 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title:
          window.t("factorOrderingInfo") +
          product.minOrder +
          ", " +
          product.minOrder * 2 +
          ", " +
          product.minOrder * 3 +
          ", " +
          product.minOrder * 4,
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }
    if (selectedDiskSize === 0) {
      setSelectedDiskSize(1);
      // Swal.fire({
      //   position: "top-end",
      //   icon: "error",
      //   title: "Please select size",
      //   showConfirmButton: false,
      //   timer: 1100,
      // });
      // return;
    }
    localStorage.setItem(
      `item_${item.id}`,
      JSON.stringify({
        barcode: item.barcode,
        code: item.code,
        id: item.id,
        name: item.name,
        price: item.price,
        qty: qty,
        brandId: item.brandId,
        brandName: item.brandName,
        productTypeId: item.productTypeId,
        productTypeName: item.productTypeName,
        attachment: item.attachment,
        thumbnail: item.thumbnail,
        diskSize: selectedDiskSize,
        ramSize: selectedRamSize,
        screenSize: selectedScreenSize,
        color: selectedColor,
      })
    );
    helpers.getCartItemNumbers();
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: window.t("addedToCart"),
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <div className={`product-detail`}>
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">{product && product.name}</h1>
          </div>
          <div className="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                <li className="breadcrumb-item">
                  <Link className="text-nowrap" to={`/`}>
                    <i className="ci-home"></i>
                    {window.t("home")} &nbsp;&nbsp;
                  </Link>
                </li>
                <li className="breadcrumb-item text-nowrap">
                  <Link to={`/shop`}>&nbsp; {window.t("shop")} </Link>
                </li>
                {breadcrumb &&
                  breadcrumb.map((x) => {
                    return (
                      <li
                        className="breadcrumb-item text-nowrap active"
                        aria-current="page"
                      >
                        <Link to={`/shop?category=${x.id}`}>{x.name}</Link>
                      </li>
                    );
                  })}
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
          <div className="px-lg-3">
            <div className="row">
              <div className="col-lg-7 pe-lg-0 pt-lg-4">
                <div className="product-gallery">
                  <div className="product-gallery-preview order-sm-2">
                    <div
                      className="product-gallery-preview-item active"
                      id="first"
                    >
                      <Zoom transitionDuration={500}>
                        {selectedImage && (
                          <img
                            className="image-zoom"
                            src={helpers.getImageUrl(selectedImage.attachment)}
                            alt="Product image"
                          />
                        )}
                      </Zoom>

                      <div className="image-zoom-pane"></div>
                    </div>
                  </div>
                  <div className="product-gallery-thumblist order-sm-1">
                    {product &&
                      product.attachments &&
                      product.attachments.map((attachment, index) => (
                        <span
                          className="product-gallery-thumblist-item active"
                          key={index}
                          onClick={() => {
                            setSelectedImage(attachment);
                          }}
                        >
                          <img
                            src={helpers.getImageUrl(attachment.attachment)}
                            alt={product && product.name}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              padding: "1px",
                            }}
                          />
                        </span>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 pt-4 pt-lg-0">
                <div className="product-details ms-auto pb-3">
                  <div className="mb-3">
                    <span className="h3 fw-normal text-accent me-1">
                      {window.prefix !== "null" && window.prefix}
                      {parseFloat(
                        parseFloat(
                          product.price * parseFloat(window.rate ?? 1)
                        ).toFixed(2)
                      ).toLocaleString()}{" "}
                      {window.suffix !== "null" && window.suffix}
                    </span>
                    {product && parseFloat(product.discount) > 0 && (
                      <del className="text-muted fs-lg me-3">
                        $
                        {parseFloat(product.discount) +
                          parseFloat(product.price)}
                      </del>
                    )}
                    <span className="badge bg-danger badge-shadow align-middle mt-n2">
                      Sale
                    </span>
                  </div>
                  <div className="fs-sm mb-1">
                    <span className="text-heading fw-medium me-1">
                      {window.t("code")}:
                    </span>
                    <span className="text-muted" id="colorOption">
                      {product && product.code}
                    </span>
                  </div>
                  <div className="fs-sm mb-1">
                    <span className="text-heading fw-medium me-1">
                      {window.t("barcode")}:
                    </span>
                    <span className="text-muted" id="colorOption">
                      {product && product.barcode}
                    </span>
                  </div>
                  <div className="fs-sm mb-1">
                    <span className="text-heading fw-medium me-1">
                      {window.t("minOrder")}:
                    </span>
                    <span className="text-muted" id="colorOptionText">
                      {product && product.minOrder}
                    </span>
                  </div>
                  <div className="position-relative me-n4 mb-3">
                    {product.availableColors &&
                      product.availableColors.length > 0 &&
                      product.availableColors.map((color, index) => (
                        <div
                          className="form-check form-option form-check-inline mb-2"
                          key={index}
                        >
                          <img
                            src={helpers.getImageUrl(color.attachment)}
                            alt={`color_` + index}
                            width={64}
                            height={64}
                            style={{ padding: 2, border: "1px solid #ccc" }}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label htmlFor="qty" className="form-label">
                        {window.t("qty")}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="qty"
                        defaultValue={1}
                        onChange={(e) => setQty(e.target.value)}
                      />
                    </div>
                  </div>
                  {product.availableDiskSizes &&
                    product.availableDiskSizes.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <p className="form-label">
                            {window.t("availableSizes")}
                          </p>
                          <div className="form-check form-option form-check-inline mb-2">
                            <input
                              type="radio"
                              checked
                              className="form-check-input"
                              id={`diskSize_1`}
                              name="diskSize"
                              onChange={(e) => {
                                setSelectedDiskSize(new { id: 1 }());
                              }}
                            />
                            <label
                              htmlFor={`diskSize_0`}
                              className="form-option-label"
                            >
                              {window.t("all")}
                            </label>
                          </div>
                          {product.availableDiskSizes &&
                            product.availableDiskSizes.length > 0 &&
                            product.availableDiskSizes
                              .sort(
                                (a, b) =>
                                  parseFloat(a.name) - parseFloat(b.name)
                              )
                              .map((diskSize, index) => (
                                <div
                                  className="form-check form-option form-check-inline mb-2"
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    disabled
                                    className="form-check-input"
                                    id={`diskSize_${diskSize.id}`}
                                    name="diskSize"
                                    onChange={(e) => {
                                      setSelectedDiskSize(diskSize);
                                    }}
                                  />
                                  <label
                                    htmlFor={`diskSize_${diskSize.id}`}
                                    className="form-option-label"
                                  >
                                    {diskSize.name}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </div>
                    )}

                  {product.availableRamSizes &&
                    product.availableRamSizes.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <p className="form-label">
                            {window.t("bottomSizes")}
                          </p>
                          {product.availableRamSizes &&
                            product.availableRamSizes.length > 0 &&
                            product.availableRamSizes
                              .sort(
                                (a, b) =>
                                  parseFloat(a.name) - parseFloat(b.name)
                              )
                              .map((ramSize, index) => (
                                <div
                                  className="form-check form-option form-check-inline mb-2"
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`ramSize_${ramSize.id}`}
                                    name="ramSize"
                                    onChange={(e) => {
                                      setSelectedRamSize(ramSize);
                                    }}
                                  />
                                  <label
                                    htmlFor={`ramSize_${ramSize.id}`}
                                    className="form-option-label"
                                  >
                                    {ramSize.name}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </div>
                    )}

                  {product.availableScreenSizes &&
                    product.availableScreenSizes.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <p className="form-label">
                            {window.t("bottomSize2")}
                          </p>
                          {product.availableScreenSizes &&
                            product.availableScreenSizes.length > 0 &&
                            product.availableScreenSizes
                              .sort(
                                (a, b) =>
                                  parseFloat(a.name) - parseFloat(b.name)
                              )
                              .map((screenSize, index) => (
                                <div
                                  className="form-check form-option form-check-inline mb-2"
                                  key={index}
                                >
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id={`screenSize_${screenSize.id}`}
                                    name="screenSize"
                                    onChange={(e) => {
                                      setSelectedScreenSize(screenSize);
                                    }}
                                  />
                                  <label
                                    htmlFor={`screenSize_${screenSize.id}`}
                                    className="form-option-label"
                                  >
                                    {screenSize.name}
                                  </label>
                                </div>
                              ))}
                        </div>
                      </div>
                    )}

                  {product.availableColors &&
                    product.availableColors.length > 0 && (
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <p className="form-label">
                            {window.t("availableColors")}
                          </p>
                          {product.availableColors &&
                            product.availableColors.length > 0 &&
                            product.availableColors.map((color, index) => (
                              <div
                                className="form-check form-option form-check-inline mb-2"
                                key={index}
                              >
                                <input
                                  type="radio"
                                  className="form-check-input"
                                  id={`color_${color.id}`}
                                  name="color"
                                  onChange={(e) => {
                                    console.log(e);
                                    setSelectedColor(color.id);
                                  }}
                                />
                                <label
                                  htmlFor={`color_${color.id}`}
                                  className="form-option-label"
                                >
                                  {color.name}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}

                  <form className="mb-grid-gutter" method="post">
                    <div className="mb-3 d-flex align-items-center">
                      <button
                        className="btn btn-primary btn-shadow d-block w-100"
                        type="button"
                        onClick={() => {
                          addToBasket(product);
                        }}
                      >
                        <i className="ci-cart fs-lg me-2"></i>
                        {window.t("addToCart")}
                      </button>
                    </div>
                  </form>
                  <div className="accordion mb-4" id="productPanels">
                    <div className="accordion-item">
                      <h3 className="accordion-header">
                        <a
                          className="accordion-button"
                          href="#productInfo"
                          role="button"
                          data-bs-toggle="collapse"
                          aria-expanded="true"
                          aria-controls="productInfo"
                        >
                          <i className="ci-announcement text-muted fs-lg align-middle mt-n1 me-2"></i>
                          {window.t("productInfo")}
                        </a>
                      </h3>
                      <div
                        className="accordion-collapse collapse show"
                        id="productInfo"
                        data-bs-parent="#productPanels"
                      >
                        <div className="accordion-body">
                          {product.description && (
                            <div
                              className={`product-details`}
                              dangerouslySetInnerHTML={{
                                __html: product.description,
                              }}
                            ></div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h3 className="accordion-header">
                        <a
                          className="accordion-button collapsed"
                          href="#shippingOptions"
                          role="button"
                          data-bs-toggle="collapse"
                          aria-expanded="true"
                          aria-controls="shippingOptions"
                        >
                          <i className="ci-delivery text-muted lead align-middle mt-n1 me-2"></i>
                          {window.t("shippingOptions")}
                        </a>
                      </h3>
                      <div
                        className="accordion-collapse collapse"
                        id="shippingOptions"
                        data-bs-parent="#productPanels"
                      >
                        <div className="accordion-body fs-sm">
                          <div className="d-flex justify-content-between border-bottom pb-2">
                            <div>
                              <div className="fw-semibold text-dark">
                                {window.t("courier")}
                              </div>
                              <div className="fs-sm text-muted">
                                {product && product.shippingTime}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <label className="form-label d-inline-block align-middle my-2 me-3">
                    {window.t("share")}
                  </label>
                  <a className="btn-share btn-twitter me-2 my-2" href="#">
                    <i className="ci-twitter"></i>Twitter
                  </a>
                  <a className="btn-share btn-instagram me-2 my-2" href="#">
                    <i className="ci-instagram"></i>Instagram
                  </a>
                  <a className="btn-share btn-facebook my-2" href="#">
                    <i className="ci-facebook"></i>Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <h2 className="h3 text-center pb-4">{window.t("relatedProducts")}</h2>
        <div className={`row`}>
          {relatedProducts &&
            relatedProducts.map((relatedProduct, index) => {
              return (
                <div className={`col-lg-3 mb-2`} key={index}>
                  <SingleProduct
                    product={relatedProduct}
                    setProductId={setProductId}
                  />
                </div>
              );
            })}
          <QuickViewModal productId={productId} />
        </div>
        <br />
        <button
          className="btn btn-primary w-100 my-3"
          onClick={() =>
            setStartAndEnd(12, product.productTypeId, relatedProducts)
          }
          disabled={isLoading}
        >
          <i className="ci-arrow-down fs-xs mx-1"></i>
          {isLoading ? window.t("loading") : window.t("moreProducts")}
        </button>
      </div>
    </div>
  );
}

export default ProductDetails;
