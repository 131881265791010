import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import helpers from "../Constants/helpers";
import axiosPrivate from "../Axios";

function SignInModal() {
  const [serachParams, setSearchPrams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const login = async () => {
    const res = await axiosPrivate.post(
      `/Accounts/login`,
      JSON.stringify({ username, password }),
      {
        headers: { "content-type": "application/json; charset=utf-8" },
      }
    );
    const responseData = await res.data;

    localStorage.setItem("token", responseData.token);
    localStorage.setItem("expiration", responseData.expiration);
    localStorage.setItem("firstName", responseData.firstName);
    localStorage.setItem("lastName", responseData.lastName);
    localStorage.setItem("middleName", responseData.middleName);
    localStorage.setItem(
      "fullName",
      `${responseData.firstName} ${responseData.middleName} ${responseData.lastName}`
    );
    localStorage.setItem("branchId", responseData.branchId);
    localStorage.setItem("image", responseData.image);
    localStorage.setItem("userId", responseData.userId);
    localStorage.setItem("userType", responseData.userType);

    axiosPrivate.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${responseData.token}`;

    localStorage.setItem("isLoggedIn", "true");
    helpers.closeAllModals();

    if (res.statusText === "OK") {
      setIsLoading(false);
      navigate(serachParams.get("prevRouter") || "/");
    }
  };

  return (
    <div className="modal fade" id="signin-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        {isLoading ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center bg-light">
            <span className="loader"></span>
          </div>
        ) : (
          <div className="modal-content">
            <div className="modal-header bg-secondary d-flex justify-content-between align-items-center">
              <p
                className="text-light text-decoration-none px-3"
                href="/#signin-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-selected="true"
              >
                <i className="ci-unlocked me-2 mt-n1"></i>Sign in
              </p>
              <button
                className="btn text-light p-0 "
                type="button "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </svg>{" "}
              </button>
            </div>
            <div className="modal-body tab-content py-4">
              <div
                className="needs-validation tab-pane fade show active d-flex flex-column gap-3 justify-content-center"
                id="signin-tab"
              >
                <div className="">
                  <label className="form-label" htmlFor="si-email">
                    Username
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="si-email"
                    placeholder="Username"
                    required
                    defaultValue={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    required
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="exampleInputPassword1"
                    placeholder="Password"
                  />
                </div>
                <button
                  className="btn btn-primary btn-shadow d-block w-100"
                  type="button"
                  onClick={() => {
                    setIsLoading(true);
                    login();
                  }}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SignInModal;
