import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import helpers from "../Constants/helpers";
import Swal from "sweetalert2";
import axiosPrivate from "../Axios";
import moment from "moment";

function Cart() {
  const [cartArray, setCartArray] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [note, setNote] = useState("");

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = () => {
    let array = helpers.getCartItems();
    setCartArray(array);
    setTotalPrice(
      array.reduce((total, item) => total + item.price * item.qty, 0).toFixed(2)
    );
  };

  const updateQty = (item, qty) => {
    localStorage.setItem(
      `item_${item.id}`,
      JSON.stringify({
        barcode: item.barcode,
        code: item.code,
        id: item.id,
        name: item.name,
        price: item.price,
        qty: parseInt(qty),
        brandId: item.brandId,
        brandName: item.brandName,
        productTypeId: item.productTypeId,
        productTypeName: item.productTypeName,
        attachment: item.attachment,
        thumbnail: item.thumbnail,
        diskSize: item.diskSize,
        ramSize: item.ramSize,
        screenSize: item.screenSize,
        color: item.color,
        customerNote: item.customerNote,
      })
    );
    loadItems();
  };
  const updateCustomerNote = (item, customerNote) => {
    localStorage.setItem(
      `item_${item.id}`,
      JSON.stringify({
        barcode: item.barcode,
        code: item.code,
        id: item.id,
        name: item.name,
        price: item.price,
        qty: item.qty,
        brandId: item.brandId,
        brandName: item.brandName,
        productTypeId: item.productTypeId,
        productTypeName: item.productTypeName,
        attachment: item.attachment,
        thumbnail: item.thumbnail,
        diskSize: item.diskSize,
        ramSize: item.ramSize,
        screenSize: item.screenSize,
        color: item.color,
        customerNote: customerNote,
      })
    );
    loadItems();
  };
  const deleteItem = (id) => {
    console.log(id);
    localStorage.removeItem(`item_${id}`);
    loadItems();
  };

  const submitBasket = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to submit the order!!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, order it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let itemArray = helpers.getCartItems().map((item) => {
          return {
            productId: item.id,
            colorId: item.color !== 0 ? item.color.colorId : 1,
            diskSizeId: item.diskSize !== 0 ? item.diskSize.diskSizeId : 1,
            ramSizeId: item.ramSize !== 0 ? item.ramSize.ramSizeId : 1,
            screenSizeId:
              item.screenSize !== 0 ? item.screenSize.screenSizeId : 1,
            qty: item.qty,
            price: item.price,
          };
        });
        let form = {
          idCash: false,
          date: moment(new Date(), "YYYY-MM-DD HH:mm:ss"),
          customerNote: note,
          orders: itemArray,
        };
        axiosPrivate
          .post("/Invoices?invoiceType=SellInvoice", form, {})
          .then(() => {
            Object.keys(localStorage).forEach(function (key) {
              if (key.startsWith("item_")) {
                localStorage.removeItem(key);
              }
            });
            setNote("");
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Form Submit",
              showConfirmButton: false,
              timer: 1500,
            });
            loadItems();
          });
      }
    });
  };

  return (
    <div>
      <div className="page-title-overlap bg-dark pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0">Your cart</h1>
          </div>
        </div>
      </div>
      <div className="container pb-5 mb-2 mb-md-4">
        <div className="row">
          <section
            className={`${cartArray?.length > 0 ? "col-lg-8" : "col-lg-12"}`}
          >
            <div className="d-flex justify-content-between align-items-center pt-3 pb-4 pb-sm-5 mt-1">
              <h2 className="h6 text-light mb-0">Products</h2>
              <Link
                className="btn btn-primary btn-sm ps-2 text-decoration-none"
                to={`/shop`}
              >
                {window.t("contShopping")}
              </Link>
            </div>
            {cartArray.length === 0 && (
              <div className="d-flex gap-4 flex-column justify-content-center align-items-center my-3 text-center">
                <h4>Your cart is Empty, it's time to shopping</h4>
                <Link
                  to={`/shop`}
                  className="btn btn-sm btn-action btn-block w-75 fs-lg text-decoration-none"
                >
                  Shop Here
                </Link>
              </div>
            )}
            {cartArray &&
              cartArray.map((cart, index) => (
                <div className="d-flex flex-column gap-3 border-bottom border-secondary py-3">
                  <div className="d-flex justify-content-between gap-4  ">
                    <div className="list-image shadow overflow-hidden rounded">
                      <img
                        className="w-100 h-100 object-cover scale-110"
                        src={helpers.getImageUrl(cart.attachment)}
                        alt="Product"
                      />
                    </div>
                    <div
                      key={index}
                      className="d-flex flex-column flex-1 w-100 gap-2 justify-content-between text-center text-start"
                    >
                      <div className="">
                        <h3 className="product-title text-start fs-sm mb-2 ">
                          {cart.name}
                        </h3>
                        <div className="fs-sm d-flex justify-content-between flex-wrap">
                          <span className="text-muted me-2">Color: Khaki</span>
                          <span className="text-muted me-2">
                            Code: {cart.code}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex gap-2 justify-content-between text-center">
                        <button
                          className="btn flex-1 p-0 btn-sm btn-outline-action cursor-auto "
                          type="button"
                        >
                          <span className="">
                            Price ${helpers.roundToTwo(cart.price)}
                          </span>
                        </button>
                        <button
                          className="btn btn-sm btn-primary-background d-flex justify-content-center flex-1"
                          type="button"
                          onClick={() => {
                            console.log(cart?.id);
                            deleteItem(cart.id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                          </svg>
                          <span className="fs-md d-none d-md-block">
                            Remove
                          </span>
                        </button>
                      </div>
                      <button
                        className="btn flex-1 p-1 btn-sm btn-action cursor-auto "
                        type="button"
                      >
                        <span className="">
                          Total Price $
                          {helpers.roundToTwo(
                            parseFloat(cart.price * cart.qty)
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="mx-auto w-100 d-flex flex-column flex-md-row gap-2">
                    <div className="flex-1 w-100 d-flex flex-md-column gap-1 align-items-center align-items-md-start">
                      <label htmlFor="quantity">Quantity: </label>
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Quantity"
                        id="quantity"
                        min="1"
                        defaultValue={cart.qty}
                        onChange={(e) => {
                          updateQty(cart, e.target.value);
                        }}
                      />
                    </div>
                    <div className="flex-1 w-100 d-flex flex-md-column gap-1 align-items-center align-items-md-start">
                      <label htmlFor="note">Note: </label>
                      <input
                        className="form-control"
                        placeholder="Item Note"
                        type="text"
                        id="note"
                        defaultValue={cart.customerNote}
                        onChange={(e) => {
                          updateCustomerNote(cart, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </section>
          {cartArray?.length > 0 && (
            <aside className="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
              <div className="bg-white rounded-3 shadow-lg p-4">
                <div className="py-2 px-xl-2">
                  <div className="text-center mb-4 pb-3 border-bottom">
                    <h2 className="h6 mb-3 pb-1">Subtotal</h2>
                    <h3 className="fw-normal">${totalPrice}</h3>
                  </div>
                  <div className="mb-3 mb-4">
                    <label className="form-label mb-3" htmlFor="order-comments">
                      <span className="badge bg-info fs-xs me-2">
                        Invoice Note
                      </span>
                      <span className="fw-medium">Additional comments</span>
                    </label>
                    <textarea
                      className="form-control"
                      rows="6"
                      id="order-comments"
                      onChange={(e) => setNote(e.target.value)}
                      defaultValue={note}
                    ></textarea>
                  </div>
                  <button
                    disabled={helpers.getCartItemNumbers() <= 0}
                    className="btn btn-primary btn-shadow d-block w-100 mt-4"
                    type={`button`}
                    onClick={submitBasket}
                  >
                    <i className="ci-card fs-lg me-2"></i>Submit Order
                  </button>
                </div>
              </div>
            </aside>
          )}
        </div>
      </div>
    </div>
  );
}

export default Cart;
