import axios from "axios";
import helpers from "../Constants/helpers";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const token = localStorage.getItem("token");

const axiosPrivate = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
  language:
    localStorage.getItem("language") === "ar"
      ? "Arabic"
      : localStorage.getItem("language") === "ku"
      ? "Kurdish"
      : "English",
});
if (token) {
  axiosPrivate.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axiosPrivate.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      helpers.openLoginModal();
    }
    return Promise.reject(error);
  }
);
export default axiosPrivate;
