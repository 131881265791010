import React from "react";

import {
  CloseButton,
  SubmitButton,
  UpdateButton,
} from "../../../Component/ActionButton";
import Input from "../../../Component/Input";
import SelectAttachment from "../../../Component/SelectAttachment";

export default function (props) {
  const { actionData, type } = props;

  React.useEffect(() => {
    setData(actionData);
  }, [actionData]);

  const [data, setData] = React.useState();
  console.log(data);
  const formDataHandler = (e) => {
    console.log(e);
    const { name, value, type, checked } = e.target;
    setData(() => ({
      ...data,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <div
      className="modal fade"
      id="CurrencyModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              {type}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <Input
                label="name"
                name="name"
                type="text"
                data={data}
                required={true}
                formDataHandler={formDataHandler}
              />
              <Input
                label="kurdishName"
                name="kuName"
                type="text"
                data={data}
                required={true}
                formDataHandler={formDataHandler}
              />
              <Input
                label="arabicName"
                name="arName"
                type="text"
                data={data}
                required={true}
                formDataHandler={formDataHandler}
              />
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <Input
                label="suffix"
                name="suffix"
                type="text"
                data={data}
                required={true}
                formDataHandler={formDataHandler}
              />
              <Input
                label="prefix"
                name="prefix"
                type="text"
                data={data}
                required={true}
                formDataHandler={formDataHandler}
              />
            </div>
            <div className="d-flex gap-4 justify-content-center align-items-center w-100">
              <SelectAttachment
                data={data}
                setData={setData}
                label="attachment"
                className="flex-1 d-block"
              />
            </div>
          </div>
          <div className="modal-footer">
            {!data?.id && (
              <SubmitButton
                endPoint={`/Main?objectName=11`}
                data={data}
                setData={setData}
              />
            )}
            <CloseButton />
            {data?.id && (
              <UpdateButton
                endPoint={`/Main/${data?.id}?objectName=11`}
                data={data}
                setData={setData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
