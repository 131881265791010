import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const token = localStorage.getItem("token");
export const HTTP = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
});
if (token) {
  HTTP.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

HTTP.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
